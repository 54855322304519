import { format, parseISO } from "date-fns";
import { Button, LinkButton, Tooltip, Typography } from "@/components/atoms";
import { DataTableColumnDef } from "@/components/molecules";
import { getBookingDetailsUrl } from "@/helpers/bookingHelpers";
import { PayoutHistoryItem, PayoutRecordsItem } from "@/redux/slices/payment/types";
import { clsx, formatCurrency } from "@/utils";
import { PayoutHistoryTableData, PayoutTableData } from "./types";

export const generatePayoutHistoryColumns = () => {
  const columns: DataTableColumnDef<PayoutHistoryTableData>[] = [
    {
      accessorKey: "estArrivalDate",
      header: "Est. Arrival Date",
      id: "date",
      cell: (props) => props.row.original.estArrivalDate,
      size: 200,
    },
    {
      accessorKey: "amount",
      header: "Amount",
      id: "amount",
      cell: (props) => props.row.original.amount,
      size: 150,
    },
    {
      accessorKey: "currency",
      header: "Currency",
      id: "currency",
      cell: (props) => props.row.original.currency,
      size: 150,
    },
    {
      accessorKey: "description",
      header: "Description",
      id: "description",
      cell: (props) => props.row.original.description,
    },
    {
      accessorKey: "status",
      header: "Status",
      id: "status",
      cell: (props) => props.row.original.status,
      size: 250,
    },
    {
      accessorKey: "action",
      header: "",
      cell: (props) => props.row.original.action,
      enableSorting: false,
      size: 150,
    },
  ];

  return columns;
};

export const generatePayoutHistoryData = (
  items: PayoutHistoryItem[],
  dateFormat: string,
  handleViewPayoutDetails: (item: PayoutHistoryItem) => void
) => {
  const data = items.map(
    (i) =>
      ({
        estArrivalDate: format(parseISO(i.arrivalDate), dateFormat),
        amount: formatCurrency(i.amount, 2, i.currency),
        currency: i.currency,
        description: (
          <div className="flex">
            <Tooltip content={i.description} placement="bottom">
              <Typography variant="paragraph" className="truncate">
                {i.description}
              </Typography>
            </Tooltip>
          </div>
        ),
        status: renderPayoutHistoryStatus(i),
        action: (
          <Button
            variant="tertiary"
            startIcon="Eye"
            size="xs"
            onClick={() => handleViewPayoutDetails(i)}
            className="!text-info hover:bg-info-light"
          >
            View
          </Button>
        ),
      } as PayoutHistoryTableData)
  );

  return data;
};

export const renderPayoutHistoryStatus = (item: PayoutHistoryItem) => {
  const { status } = item;

  if (status) {
    return (
      <Typography variant="paragraph" className="flex flex-row items-center gap-x-2 truncate capitalize">
        {renderStatusIcon(status)} {status.replaceAll("_", " ").toLowerCase()}
      </Typography>
    );
  }
};

export const renderStatusIcon = (status: string) => {
  const iconColorClass = {
    "bg-success": status === "PAID" || status === "SUCCESSFUL",
    "bg-warning": status === "REFUNDED",
    "bg-warning-dark": status === "PARTIALLY_REFUNDED",
    "bg-danger": status === "FAILED" || status === "CANCELLED" || status === "ERROR",
    "bg-danger opacity-60": status === "CANCELLED",
    "bg-info": status === "PROCESSING" || status === "IN_TRANSIT",
  };

  return <span className={clsx("h-2 w-2 flex-shrink-0 rounded-full bg-neutral-dark-gray", iconColorClass)} />;
};

export const generatePayoutColumns = () => {
  const columns: DataTableColumnDef<PayoutTableData>[] = [
    {
      accessorKey: "description",
      header: "Description",
      cell: (props) => props.row.original.description,
      enableSorting: false,
    },
    {
      accessorKey: "jobDate",
      header: "Job Date (if applicable)",
      id: "date",
      cell: (props) => props.row.original.jobDate,
      size: 200,
    },
    {
      accessorKey: "time",
      header: "Time",
      cell: (props) => props.row.original.time,
      enableSorting: false,
      size: 150,
    },
    {
      accessorKey: "status",
      header: "Status",
      cell: (props) => props.row.original.status,
      enableSorting: false,
      size: 150,
    },
    {
      accessorKey: "amount",
      header: "Amount",
      cell: (props) => props.row.original.amount,
      enableSorting: false,
      size: 150,
    },
    {
      accessorKey: "currency",
      header: "Currency",
      cell: (props) => props.row.original.currency,
      enableSorting: false,
    },
    {
      accessorKey: "action",
      header: "",
      cell: (props) => props.row.original.action,
      enableSorting: false,
      size: 150,
    },
  ];

  return columns;
};

export const generatePayoutData = (items: PayoutRecordsItem[], dateFormat: string, timeFormat: string, operatorId: string) => {
  const data = items.map(
    (i) =>
      ({
        description: <Typography variant="paragraph">{i.description}</Typography>,
        jobDate: i.dateTime ? format(parseISO(i.dateTime), dateFormat) : "-",
        time: i.dateTime ? format(parseISO(i.dateTime), timeFormat) : "-",
        status: (
          <Typography variant="paragraph" className="flex flex-row items-center gap-x-2 capitalize">
            {i.status ? i.status.toLowerCase() : "-"}
          </Typography>
        ),
        amount: formatCurrency(i.amount, 2, i.currency),
        currency: i.currency,
        action: i.identifier && (
          <LinkButton
            to={getBookingDetailsUrl(operatorId, i.identifier, format(parseISO(i.dateTime), "yyyy-MM-dd"))}
            target="_blank"
            variant="tertiary"
            startIcon="Archive"
            size="xs"
            className="!text-info hover:bg-info-light"
          >
            View Job
          </LinkButton>
        ),
      } as PayoutTableData)
  );

  return data;
};
