import { isEmpty } from "ramda";
import { useMemo, useRef, useState } from "react";
import { Alert, Button, DropdownSelect, Icon, Loading, Modal, Spinner, TextInput, Typography } from "@/components/atoms";
import { useOperator } from "@/hooks";
import { useGetCountryStatesQuery } from "@/redux/apis/resource/resourceApi";
import { useGetVehicleFormOptionsQuery, useUpdateRegistrationMutation } from "@/redux/apis/vehicle/vehicleApi";
import { Vehicle } from "@/redux/slices/vehicle/types";
import { addToast, clsx, formatCurrency } from "@/utils";
import { getVerificationErrorType } from "../../AddVehicle/helpers";

interface ChangeNumberPlateStateModalProps extends React.HTMLAttributes<HTMLDivElement> {
  vehicle: Vehicle;
  open: boolean;
  onClose: () => void;
}

export const ChangeNumberPlateStateModal = ({ vehicle, open, onClose, ...props }: ChangeNumberPlateStateModalProps) => {
  const inputRef = useRef<HTMLInputElement>(null);
  const { country } = useOperator();
  const { isFetching, data: response } = useGetCountryStatesQuery(country);
  const [updateRegistration, { isLoading, error }] = useUpdateRegistrationMutation();
  const [numberPlate, setNumberPlate] = useState<string>(vehicle.registrationNumber);
  const [state, setState] = useState<string>(vehicle.state.uuid);
  const { data } = useGetVehicleFormOptionsQuery();

  const fee = useMemo(() => {
    if (!data) return 0;

    return data.fees.verificationFee;
  }, [data]);

  const options = useMemo(
    () => response?.data.states.map((state) => ({ name: state.name, value: state.id })) ?? [],
    [response?.data.states]
  );

  const errorType = useMemo(() => {
    if (!error) return undefined;

    return getVerificationErrorType(error);
  }, [error]);

  const handleUpdate = () => {
    if (!numberPlate || !state) return;

    updateRegistration({
      vehicle_uuid: vehicle.uuid,
      registration_number: numberPlate,
      state_uuid: state,
    })
      .unwrap()
      .then(() => {
        addToast("success", "Vehicle has been successfully updated");
        onClose();
      })
      .catch(() => {
        console.warn("Failed to update vehicle");
      });
  };

  return (
    <>
      <Modal open={open} onClose={onClose} className="!p- !max-w-[550px] overflow-y-visible">
        <div className={clsx("relative")} {...props}>
          {isLoading && <Loading />}

          <div className="mt-5 flex">
            <div className="flex flex-1 flex-col gap-2">
              <Typography variant="title">Change Number Plate or State</Typography>
              <Typography variant="action">
                Ensure the new plate matches the listed vehicle. If not, archive this vehicle and register a new one.
              </Typography>
            </div>

            <div className="flex justify-end">
              <Icon name="close" isCustom className="flex cursor-pointer" onClick={onClose} />
            </div>
          </div>
          <div className="flex max-w-lg flex-col">
            <form className="mt-6 flex flex-col gap-4">
              <label htmlFor="model-name" className="flex flex-col gap-1">
                <Typography variant="paragraph">New Vehicle Number Plate</Typography>

                <TextInput
                  placeholder="000-0000"
                  id="number-plate"
                  value={numberPlate}
                  onChange={(e) => setNumberPlate(e.target.value)}
                  hasError={errorType === "verification-failed"}
                  hasWarning={errorType === "vehicle-exists"}
                  required
                  autoFocus
                  ref={inputRef}
                />
              </label>
              <label htmlFor="state" className="flex flex-col gap-1">
                <Typography variant="paragraph">New State of the Vehicle</Typography>

                <DropdownSelect
                  className="w-full"
                  placeholder="Select State"
                  options={options}
                  value={state}
                  onChange={setState}
                  disabled={isEmpty(options) || isFetching}
                />
              </label>
              {vehicle.state.uuid !== state && vehicle.registration.status === "VERIFIED" && (
                <Alert type="warning" message="Note that changing the State will unverify the vehicle" />
              )}
            </form>
          </div>
          <div className="mt-6 space-y-3 ">
            <Button variant="primary" onClick={handleUpdate} size="lg" className="w-full" disabled={isLoading}>
              {isLoading ? <Spinner /> : `Update for  ${formatCurrency(fee, 2, "AUD")}`}
            </Button>
            <Typography variant="small" className="w-full text-center text-neutral-dark-gray">
              A {formatCurrency(fee, 2, "AUD")} fee will be debited during the daily account processing.
            </Typography>
          </div>
        </div>
      </Modal>
    </>
  );
};
