import { createApi } from "@reduxjs/toolkit/dist/query/react";
import { baseQueryWithoutProvider } from "@/redux/baseQuery";
import { transformDriverProfile, transformDriverDocument } from "./helpers";
import {
  GetDriverProfileResponse,
  GetDriverProfileParams,
  UpdateDriverProfileResponse,
  UpdateDriverProfileParams,
  AddDriverDocumentResponse,
  AddDriverDocumentParams,
  RemoveDriverDocumentParams,
} from "./types";

export const driverProfileApi = createApi({
  reducerPath: "driverProfileApi",
  baseQuery: baseQueryWithoutProvider("driver"),
  endpoints: (builder) => ({
    getDriverProfile: builder.query<GetDriverProfileResponse, GetDriverProfileParams>({
      query: ({ id, operatorId }) => `/${id}/${operatorId}/profile`,
      providesTags: ["DriverProfile"],
      transformResponse: transformDriverProfile,
    }),
    updateDriverProfile: builder.mutation<UpdateDriverProfileResponse, UpdateDriverProfileParams>({
      query: ({ id, ...body }) => ({
        url: `/${id}/profile`,
        method: "PATCH",
        body,
      }),
      invalidatesTags: ["DriverProfile"],
    }),
    addDriverDocument: builder.mutation<AddDriverDocumentResponse, AddDriverDocumentParams>({
      query: ({ id, body }) => ({
        url: `/${id}/document`,
        method: "POST",
        body,
      }),
      transformResponse: transformDriverDocument,
    }),
    removeDriverDocument: builder.mutation<void, RemoveDriverDocumentParams>({
      query: ({ id, documentId }) => ({
        url: `/${id}/document/${documentId}`,
        method: "DELETE",
      }),
    }),
  }),
  tagTypes: ["DriverProfile"],
});

export const { useGetDriverProfileQuery, useUpdateDriverProfileMutation, useAddDriverDocumentMutation, useRemoveDriverDocumentMutation } =
  driverProfileApi;
