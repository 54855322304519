import { zodResolver } from "@hookform/resolvers/zod";
import { format, parseISO } from "date-fns";
import { Controller, useForm } from "react-hook-form";
import { Button, DropdownSelect, ErrorMessage, Icon, IconButton, Loading, Toggle, Tooltip, Typography } from "@/components/atoms";
import { getErrorMessages } from "@/helpers/reduxHelpers";
import { useProfile } from "@/hooks";
import { driverProfileApi } from "@/redux/apis";
import { useToggleFleetLeaderMutation, useUpdateFleetStatusMutation } from "@/redux/apis/driver/driverApi";
import { useAppDispatch } from "@/redux/hooks";
import { addToast, clsx } from "@/utils";
import { DriverDetail } from "../../../common/DriverDetail";
import { useManageDriverContext } from "../../context";
import { EditDriverStatusFormData, editDriverStatusFormSchema } from "../../form";

export const DriverStatus = () => {
  const dispatch = useAppDispatch();
  const { dateFormat } = useProfile();
  const [toggle, { isLoading: isLoadingToggle }] = useToggleFleetLeaderMutation();
  const [updateDriverStatus, { isLoading: isLoadingUpdateStatus }] = useUpdateFleetStatusMutation();
  const { driver, setActiveModal, isFetching: isFetchingDriverProfile, setActiveSection, activeSection } = useManageDriverContext();
  const isLoading = isLoadingToggle || isFetchingDriverProfile;
  const { status, metadata } = driver;

  const {
    control,
    handleSubmit,
    formState: { isDirty, errors: formErrors },
  } = useForm<EditDriverStatusFormData>({
    resolver: zodResolver(editDriverStatusFormSchema),
    defaultValues: {
      tier: driver.tier,
    },
  });

  const toggleFleetLeader = (isFleetLeader: boolean) => {
    toggle({ id: driver.uuid, isFleetLeader })
      .unwrap()
      .then(() => {
        dispatch(driverProfileApi.util.invalidateTags(["DriverProfile"]));
        addToast("success", "Successfully toggled fleet leader");
      })
      .catch((e) => getErrorMessages(e).forEach((m) => addToast("danger", m)));
  };

  const handleEdit = () => {
    if (activeSection !== undefined) setActiveModal("save-changes");
    setActiveSection("driver-status");
  };

  const onSubmit = handleSubmit((data: EditDriverStatusFormData) => {
    if (editMode && isDirty) {
      const { tier } = data;

      updateDriverStatus({
        id: driver.uuid,
        tier: tier.toString(),
      })
        .unwrap()
        .then(() => {
          addToast("success", "Successfully updated account status");
          dispatch(driverProfileApi.util.invalidateTags(["DriverProfile"]));
          setActiveSection(undefined);
        })
        .catch((e) => getErrorMessages(e).forEach((message) => addToast("danger", message)));
    }
  });

  const renderApplicationStatus = () => {
    const statusClassName = "flex flex-row items-center gap-1.5 capitalize";
    let displayStatus = null;

    switch (status) {
      case "pending":
      case "Pending Qualification":
      case "Application in Progress":
      case "Pending Approval":
        displayStatus = (
          <div className={statusClassName}>
            <Icon name="MinusCircle" size="sm" variant="Bold" className="text-neutral-dark-gray" />
            <Typography variant="action">{status}</Typography>
          </div>
        );
        break;
      case "active":
      case "Approved":
        displayStatus = (
          <div className={statusClassName}>
            <Icon name="TickCircle" size="sm" variant="Bold" className="text-success" />
            <Typography variant="action">{status}</Typography>
          </div>
        );
        break;
      case "Declined":
      case "removed":
      case "left":
      case "inactive":
      case "Permanently Terminated":
      case "Retired":
      case "suspended":
      case "Temporarily Suspended":
        displayStatus = (
          <div className={statusClassName}>
            <Icon name="Trash" size="sm" variant="Bold" className="text-danger" />
            <Typography variant="action">{status}</Typography>
          </div>
        );
        break;
      default:
        break;
    }

    return <DriverDetail label="Application" detail={displayStatus} className="flex-1" />;
  };

  const editMode = activeSection === "driver-status";

  return (
    <form onSubmit={onSubmit} className="relative mt-6 flex flex-col rounded-lg border border-neutral-gray bg-neutral-surface-gray p-5">
      {isLoading && <Loading />}

      <div className="flex flex-row items-center">
        <Typography variant="title" className="flex flex-1 flex-col gap-1">
          Driver Status
          <Typography variant="small" className="self-start rounded-full bg-info-light px-3 py-0.5 text-info">
            Driver can now accept network jobs
          </Typography>
        </Typography>
        {editMode ? (
          <div className="flex flex-row items-center gap-3">
            <Button size="sm" variant="secondary" onClick={() => setActiveSection(undefined)}>
              Cancel
            </Button>
            <Button size="sm" variant="primary" type="submit" disabled={!isDirty || isLoadingUpdateStatus}>
              Save
            </Button>
          </div>
        ) : (
          <IconButton variant="tertiary" iconName="Edit2" size="sm" onClick={handleEdit} />
        )}
      </div>

      <div
        className={clsx(
          "mt-6 grid gap-6",
          editMode ? "grid-cols-1 md:grid-cols-2 2xl:grid-cols-3" : "grid-cols-1 md:grid-cols-3 2xl:grid-cols-5"
        )}
      >
        {!editMode ? renderApplicationStatus() : null}
        <DriverDetail
          className={editMode ? "hidden" : ""}
          label={
            <div className="flex flex-row items-center gap-1.5 text-neutral-dark-gray">
              <Typography variant="paragraph">Fleet Leader</Typography>
              <Tooltip
                placement="top"
                maxWidth={467}
                content={
                  <span className="whitespace-pre-line">
                    {`Fleet leaders are defined by operators and have an elevated set of permissions. By being a fleet leader they are able to:

                      1. Reassign jobs to other drivers (via the driver app)
                      2. Be assigned jobs through the private network
                      3. Are visible to operators when searching in the Driver Manager
                      
                      A fleet leader can be identified as having a green circle around their profile picture.`}
                  </span>
                }
              >
                <span>
                  <Icon name="InfoCircle" variant="Bold" size="sm" className="-rotate-180" />
                </span>
              </Tooltip>
            </div>
          }
          detail={<Toggle size="xs" checked={driver.isFleetLeader} onChange={(checked) => toggleFleetLeader(checked)} />}
        />
        <DriverDetail
          label="Driver Tier"
          detail={
            editMode ? (
              <div className="relative flex flex-col gap-1">
                <Controller
                  name="tier"
                  control={control}
                  render={({ field }) => (
                    <DropdownSelect
                      options={Array.from({ length: 3 }, (_, i) => ({ name: `Tier ${i + 1}`, value: (i + 1).toString() }))}
                      placeholder="Select Tier"
                      value={field.value?.toString()}
                      onChange={(value) => field.onChange(+(value as unknown as number))}
                      hasError={!!formErrors.tier}
                    />
                  )}
                />
                <ErrorMessage errors={formErrors} name="tier" />
              </div>
            ) : (
              `Tier ${driver.tier}`
            )
          }
        />
        <DriverDetail
          className={editMode ? "hidden" : ""}
          label="Driver Joined"
          detail={metadata.joinDate ? format(parseISO(metadata.joinDate), dateFormat) : "-"}
        />
        <DriverDetail
          className={editMode ? "hidden" : ""}
          label="Latest Activity"
          detail={metadata.lastActivityDate ? format(parseISO(metadata.lastActivityDate), dateFormat) : "-"}
        />
      </div>
    </form>
  );
};
