import { z } from "zod";

export const addTransferTypeFormSchema = z.object({
  name: z.string().min(1, "Field is required and can't be empty.").max(128, "Maximum of 128 characters."),
  description: z.string().min(1, "Field is required and can't be empty.").max(128, "Maximum of 128 characters."),
  costAdjustment: z
    .number({
      message: "Field is required and can't be empty.",
    })
    .min(-999)
    .max(999)
    .step(0.01, "Field must be a maximum of 2 decimal places."),
  tripType: z.string().min(1, "Please select a trip type."),
  associatedCategories: z.array(z.string()),
  //advanced settings
  isAdminOnly: z.boolean(),
  isDispatchEnabled: z.boolean(),
  isTaxExempt: z.boolean(),
  isSpecialEvent: z.boolean(),
});
export type AddTransferTypeFormData = z.infer<typeof addTransferTypeFormSchema>;
