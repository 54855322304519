import { useCallback } from "react";
import { Dropzone, Icon, Loading, Typography } from "@/components/atoms";
import { getErrorMessages } from "@/helpers/reduxHelpers";
import { useLazyRequestDriverDocumentQuery } from "@/redux/apis/document/documentApi";
import {
  useAddDriverDocumentMutation,
  useRemoveDriverDocumentMutation,
  useUpdateDriverProfileMutation,
} from "@/redux/apis/driver/driverProfileApi";
import { DriverDocument } from "@/redux/slices/driver/types";
import { addToast, clsx, downloadFile } from "@/utils";
import { useManageDriverContext } from "../../context";

interface DriverDocumentUploadFieldProps {
  editMode: boolean;
  document?: {
    uuid?: string;
    filename?: string;
    type?: string;
  } | null;
  documentType: string;
}

export const DriverDocumentUploadField = ({ editMode, document, documentType }: DriverDocumentUploadFieldProps) => {
  const { driver } = useManageDriverContext();
  const [addDocument, { isLoading: isLoadingAddDocument }] = useAddDriverDocumentMutation();
  const [removeDocument, { isLoading: isLoadingRemoveDocument }] = useRemoveDriverDocumentMutation();
  const [requestDocument, { isLoading: isLoadingRequestDocument }] = useLazyRequestDriverDocumentQuery();
  const [updateDriverProfile, { isLoading: isLoadingUpdate }] = useUpdateDriverProfileMutation();
  const currentDocuments = driver.documents.map((document) => ({ uuid: document.uuid }));
  const isLoading = isLoadingAddDocument || isLoadingRemoveDocument || isLoadingRequestDocument || isLoadingUpdate;

  const uploadFile = (file: File) => {
    const formData = new FormData();

    formData.append("file", file);
    formData.append("name", file.name);
    formData.append("document_type", documentType);

    addDocument({ id: driver.uuid, body: formData })
      .unwrap()
      .then((res) => {
        updateDriverProfile({
          id: driver.uuid,
          documents: [...currentDocuments, { uuid: res.data.uuid }],
        })
          .unwrap()
          .then(() => addToast("success", "Successfully uploaded document"))
          .catch((e) => getErrorMessages(e).forEach((message) => addToast("danger", message)));
      })
      .catch((e) => getErrorMessages(e).forEach((e) => addToast("danger", e)));
  };

  const removeFile = () => {
    const activeDocument = document ? (document as DriverDocument) : null;

    if (activeDocument) {
      removeDocument({ id: driver.uuid, documentId: activeDocument.uuid })
        .unwrap()
        .then(() => {
          const updatedDocuments = currentDocuments.filter((document) => document.uuid !== activeDocument.uuid);
          updateDriverProfile({
            id: driver.uuid,
            documents: updatedDocuments,
          })
            .unwrap()
            .then(() => addToast("success", "Successfully removed document"))
            .catch((e) => getErrorMessages(e).forEach((message) => addToast("danger", message)));
        })
        .catch((e) => getErrorMessages(e).forEach((e) => addToast("danger", e)));
    }
  };

  const downloadDocument = () => {
    const activeDocument = document ? (document as DriverDocument) : null;

    if (activeDocument) {
      requestDocument({
        id: activeDocument.uuid,
        driverId: driver.uuid,
      })
        .unwrap()
        .then((res) => downloadFile(res.filename, activeDocument.filename));
    }
  };

  const renderContent = useCallback(() => {
    return (
      <div className="relative flex h-[46px] w-full flex-row items-center gap-2.5 rounded-lg border border-neutral-mid-gray bg-white p-1.5">
        {isLoading && <Loading className="rounded-lg" />}
        <div className="flex items-center justify-center rounded-md bg-neutral-gray px-3 py-1">
          <Typography variant="action" className="text-neutral-black">
            Choose
          </Typography>
        </div>
        <Typography variant="paragraph" className="text-neutral-dark-gray">
          No file selected
        </Typography>
      </div>
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [document, editMode, isLoading]);

  return (
    <>
      {editMode ? (
        document ? (
          <div className="relative flex">
            {isLoading && <Loading className="rounded-lg" />}
            <div className={clsx("flex min-h-[46px] gap-1", { "cursor-wait": isLoading })}>
              <div className="group flex cursor-pointer flex-row items-center gap-2" onClick={downloadDocument}>
                <Typography variant="action" className="text-neutral-black group-hover:text-info">
                  {document.filename}
                </Typography>
                <Icon name="ImportCurve" size="sm" className="text-info" />
              </div>
              <div className="flex">
                <div className="flex cursor-pointer flex-row items-center gap-1.5 text-danger hover:text-danger-dark" onClick={removeFile}>
                  <Icon name="Trash" size="sm" />
                </div>
              </div>
            </div>
          </div>
        ) : (
          <Dropzone
            accept={{ "image/png": [".png"], "image/jpeg": [".jpeg", ".jpg"], "application/pdf": [".pdf"] }}
            maxSize={10000000}
            upload={uploadFile}
            className="border-none"
            detail={renderContent()}
            disabled={isLoading}
          />
        )
      ) : document ? (
        <div className="flex">
          <div
            className={clsx("group flex cursor-pointer flex-row items-center gap-2", { "cursor-wait": isLoading })}
            onClick={downloadDocument}
          >
            <Typography variant="action" className="text-neutral-black group-hover:text-info">
              {document.filename}
            </Typography>
            <Icon name="ImportCurve" size="sm" className="text-info" />
          </div>
        </div>
      ) : (
        "-"
      )}
    </>
  );
};
