import { useWindowSize } from "@react-hookz/web";
import { useState } from "react";
import { Button, SearchInput, Toggle, Typography } from "@/components/atoms";
import { usePagination } from "@/hooks";
import { clsx } from "@/utils";
import { DriversFilters } from "../../features/Filters/DriversFilters";
import { useGetOffloadDriversParams } from "../hooks/useGetOffloadDriversParams";

export const OffloadDriversActionMenu = () => {
  const { search, setSearch, showBlocked, setShowBlocked } = useGetOffloadDriversParams();
  const [isSearchActive, setIsSearchActive] = useState(false);
  const { clearPagination } = usePagination("offload-drivers");

  const handleSearch = (value?: string) => {
    if (!value) setIsSearchActive(false);

    clearPagination();
    setSearch(value);
  };

  if (!isSearchActive && !search) {
    return (
      <div className="flex flex-1 items-center justify-end gap-0 xl:gap-2 ">
        <Button
          className="max-md:[&>span]:hidden"
          variant="tertiary"
          size="sm"
          startIcon="SearchNormal1"
          onClick={() => setIsSearchActive(true)}
        >
          Search
        </Button>
        <FilterButton />
        <div className="flex flex-row items-center gap-1.5">
          <Toggle size="xs" checked={!!showBlocked} onChange={(checked) => setShowBlocked(checked ? true : false)} />
          <Typography className="paragraph hidden md:block">Show Blocked Drivers</Typography>
        </div>
      </div>
    );
  }

  return (
    <div className="flex flex-1 items-center justify-end gap-2">
      <SearchInput
        search={search}
        onSearch={handleSearch}
        autoFocus
        placeholder="Search Drivers"
        className={clsx("w-full max-w-sm p-0 sm:w-fit [&>input]:transition-size", isSearchActive && "[&>input]:w-[280px]")}
        size="sm"
        onClear={() => setIsSearchActive(false)}
      />
    </div>
  );
};

const FilterButton = () => {
  const { width } = useWindowSize();

  return width >= 768 ? <DriversFilters.Button /> : <DriversFilters.DrawerButton />;
};
