import { createContext, PropsWithChildren, useContext, useState } from "react";
import { useParams } from "react-router-dom";
import { Loading } from "@/components/atoms";
import { useOperator } from "@/hooks";
import { useGetDriverProfileQuery } from "@/redux/apis/driver/driverProfileApi";
import { DriverProfile } from "@/redux/slices/driver/types";

type DriverModal = "driver-left" | "remove-driver" | "change-password" | "approve-driver" | "save-changes" | "driver-status";
type DriverSection = "account-details" | "driver-details" | "emergency-contact" | "driver-status";

interface IManageDriverContext {
  driver: DriverProfile;
  isFetching: boolean;
  activeModal?: DriverModal;
  setActiveModal: (modal?: DriverModal) => void;
  activeSection?: DriverSection;
  setActiveSection: (section?: DriverSection) => void;
}

export const ManageDriverContext = createContext<IManageDriverContext | null>(null);

export const ManageDriverProvider = ({ children }: PropsWithChildren) => {
  const { driverId } = useParams<{ driverId: string }>();
  const { id: operatorId } = useOperator();
  const { data: driver, isFetching } = useGetDriverProfileQuery({ id: driverId ?? "", operatorId }, { skip: !driverId });
  const [activeModal, setActiveModal] = useState<IManageDriverContext["activeModal"]>(undefined);
  const [activeSection, setActiveSection] = useState<IManageDriverContext["activeSection"]>(undefined);

  if (isFetching || !driver) return <Loading className="!bg-transparent" />;

  return (
    <ManageDriverContext.Provider value={{ driver: driver.data, isFetching, activeModal, setActiveModal, activeSection, setActiveSection }}>
      {children}
    </ManageDriverContext.Provider>
  );
};

export const useManageDriverContext = () => {
  const context = useContext(ManageDriverContext);
  if (!context) {
    throw new Error("useManageDriverContext must be used within an ManageDriverProvider");
  }
  return context;
};
