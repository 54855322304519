import { matchPath, useLocation } from "react-router-dom";
import { useAppAbility } from "@/casl";
import { Container } from "@/components/atoms";
import { Tabs } from "@/components/molecules";
import { PageHeader } from "@/components/organisms";
import { Tab } from "@/components/organisms/Sidebar/types";
import { RequireAccess } from "@/routes/RequireAccess/RequireAccess";
import { Admin } from "./Admin/Admin";
import { BrandingAndAcquisition } from "./BrandingAndAcquisition/BrandingAndAcquisition";
import { Documents } from "./Documents/Documents";
import { Pricing } from "./Pricing/Pricing";
import { UserAccounts } from "./UserAccounts/UserAccounts";

const pageTabs: Tab[] = [
  { name: "Admin Settings", to: "admin", icon: "" },
  { name: "Pricing Settings", to: "pricing", icon: "" },
  { name: "Branding & Acquisition Tools", to: "branding-and-acquisition", icon: "" },
  { name: "Documents", to: "documents", icon: "" },
  {
    name: "User Accounts",
    to: "user-accounts",
    permission: ["manage", "providerAccountDirectory"],
    icon: "",
  },
];

export const Config = () => {
  const ability = useAppAbility();
  const { pathname } = useLocation();

  const isParentPage = Boolean(
    matchPath({ path: "/config/:slug" }, pathname) || matchPath({ path: "operator/:operatorId/config/:slug" }, pathname)
  );

  return (
    <Container className="w-full">
      <div className="flex flex-1 flex-col pb-12">
        <PageHeader title="Settings" skipCrumbs={1} />
        {isParentPage && (
          <Tabs className="no-scrollbar mb-5 border-b !border-neutral-dark-gray">
            {pageTabs.map((t, i) => {
              if (t.permission) {
                if (ability.can(t.permission[0], t.permission[1])) return <Tabs.Tab key={i} to={t.to} name={t.name} />;
              } else return <Tabs.Tab key={i} to={t.to} name={t.name} />;
            })}
          </Tabs>
        )}
        <RequireAccess action="view" subject="providerConfig" />
      </div>
    </Container>
  );
};

Config.Pricing = Pricing;
Config.Admin = Admin;
Config.BrandingAndAcquisition = BrandingAndAcquisition;
Config.Documents = Documents;
Config.UserAccounts = UserAccounts;
