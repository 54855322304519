import { Icon, IconButton, Toggle, Tooltip, Typography } from "@/components/atoms";
import { DataTableColumnDef } from "@/components/molecules";
import { CostExtrasItem } from "@/redux/slices/pricing/types";
import { capitalize, formatCurrency } from "@/utils";
import { FixedCostExtrasTableData } from "./types";

export const generateEditorColumns = (data: FixedCostExtrasTableData[]) => {
  const cols: DataTableColumnDef<FixedCostExtrasTableData>[] = [
    {
      accessorKey: "reorder",
      header: "",
      cell: (props) => props.row.original.reorder,
      enableSorting: false,
      size: 50,
    },
    { accessorKey: "name", enableSorting: false, header: `Name (${data.length})`, cell: (props) => props.row.original.name, size: 450 },
    { accessorKey: "description", enableSorting: false, header: "Description", size: 600 },
    { accessorKey: "price", enableSorting: false, header: "Cost" },
    {
      accessorKey: "type",
      enableSorting: false,
      header: () => (
        <div className="flex flex-row items-center gap-2">
          <Typography variant="small">Type</Typography>
          <Tooltip
            content={
              <Typography variant="action" className="mb-2">
                Extra: An option selected by the passenger from a <br />
                drop down menu in the Booking Sheet (e.g. Baby Seat) <br />
                Fee: An administrative extra added by your <br /> internal staff (e.g. Booking Fee)
              </Typography>
            }
          >
            <span className="ml-1">
              <Icon name="InfoCircle" variant="Bold" className="rotate-180 text-neutral-dark-gray" size="sm" />
            </span>
          </Tooltip>
        </div>
      ),
    },
    { accessorKey: "active", enableSorting: false, header: "Active", cell: (props) => props.row.original.actions },
  ];

  return cols;
};

export const generateEditorTableData = (
  items: CostExtrasItem[],
  currency: string,
  toggleItemStatus: (id: string, active: boolean) => void,
  onEditItem: (item: CostExtrasItem) => void,
  onDeleteItem: (item: CostExtrasItem) => void
) => {
  return items.map(
    (i) =>
      ({
        id: i.id,
        name: (
          <div className="flex items-center gap-3">
            <Icon name={`extras-${i.icon}`} isCustom className="h-7 w-7 flex-shrink-0 rounded-full bg-neutral-gray p-0.5" size="xl" />
            {getNameWithTooltip(i)}
          </div>
        ),
        description: i.description,
        price: formatCurrency(i.price, 2, currency),
        type: capitalize(i.type),
        actions: !i.universal ? (
          <div className="flex items-center gap-1.5">
            {!i.universal && (
              <Toggle size="xs" className="-my-1 mr-1" checked={i.active} onChange={(checked) => toggleItemStatus(i.id, checked)} />
            )}
            <IconButton
              iconName="Edit2"
              variant="tertiary"
              onClick={() => onEditItem(i)}
              className="border-none bg-transparent p-1 !text-info hover:bg-info-light"
            />
            <IconButton
              iconName="Trash"
              variant="tertiary"
              onClick={() => onDeleteItem(i)}
              className="border-none bg-transparent p-1 !text-danger hover:bg-danger-light"
            />
          </div>
        ) : undefined,
      } as FixedCostExtrasTableData)
  );
};

export const getNameWithTooltip = (i: CostExtrasItem) => {
  return (
    <div className="flex flex-row items-center gap-2">
      <Typography>{i.name}</Typography>
      {i.tooltip && (
        <Tooltip content={i.tooltip}>
          <Icon name="InfoCircle" variant="Bold" className="rotate-180 text-neutral-dark-gray" size="sm" />
        </Tooltip>
      )}
    </div>
  );
};
