import { useCallback, useMemo, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Button, Modal, Loading } from "@/components/atoms";
import { DataTable, Pagination } from "@/components/molecules";
import { useOperator, usePagination } from "@/hooks";
import {
  fixedCostExtrasApi,
  useDeleteCostExtraMutation,
  useReorderCostExtraMutation,
  useToggleCostExtraMutation,
} from "@/redux/apis/config/pricing/fixedCostExtras/fixedCostExtrasApi";
import { useAppDispatch } from "@/redux/hooks";
import { CostExtrasItem } from "@/redux/slices/pricing/types";
import { Page } from "@/redux/types";
import { addNotification, clsx } from "@/utils";
import { modals } from "./fixtures";
import { generateEditorColumns, generateEditorTableData } from "./helpers";
import { useGetFixedCostExtrasParams } from "./hooks/useGetFixedCostExtrasParams";
import { FixedCostExtrasTableData } from "./types";

interface FixedCostExtrasTableEditorProps extends React.HTMLAttributes<HTMLDivElement> {
  items: CostExtrasItem[];
}

export const FixedCostExtrasTableEditor = ({ items, className, ...props }: FixedCostExtrasTableEditorProps) => {
  const dispatch = useAppDispatch();
  const { currency } = useOperator();
  const navigate = useNavigate();
  const { params } = useGetFixedCostExtrasParams();
  const [pendingDeleteId, setPendingDeleteId] = useState<string | null>(null);
  const [toggle, { isLoading: toggleLoading }] = useToggleCostExtraMutation();
  const [remove, { isLoading: removeLoading }] = useDeleteCostExtraMutation();
  const [reorder, { isLoading: reorderLoading }] = useReorderCostExtraMutation();
  const [activeModal, setActiveModal] = useState<"error" | "exit" | "delete" | null>(null);

  const toggleItemStatus = useCallback(
    (id: string, active: boolean) => {
      const item = items.find((i) => i.id === id);
      if (!item) return;

      toggle({ uuid: id, is_active: active })
        .unwrap()
        .then(() => {
          dispatch(
            fixedCostExtrasApi.util.updateQueryData("getFixedCostExtras", params, (draft) => {
              draft.data.items.find((i) => i.id === id)!.active = active;
            })
          );
          addNotification("info", `Active status: ${active ? "On" : "Off"}`, `Changed active status for "${item.name}".`);
        })
        .catch(() => setActiveModal("error"));
    },
    [dispatch, items, params, toggle]
  );

  const onEditItem = useCallback(
    (item: CostExtrasItem) => {
      navigate(`./${item.id}`);
    },
    [navigate]
  );

  const onDeleteItem = useCallback((item: CostExtrasItem) => {
    setPendingDeleteId(item.id);
    setActiveModal("delete");
  }, []);

  const onReorder = useCallback(
    (sortedItems: FixedCostExtrasTableData[]) => {
      const mapping = sortedItems.reduce((acc, item, index) => {
        acc[item.id] = index;
        return acc;
      }, {} as Record<string, number>);

      dispatch(
        fixedCostExtrasApi.util.updateQueryData("getFixedCostExtras", params, (draft) => {
          const newItems = items.map((item) => ({ ...item, order: mapping[item.id] })).sort((a, b) => a.order - b.order);
          Object.assign(draft.data.items, newItems);
        })
      );

      reorder(mapping)
        .unwrap()
        .then(() => {
          addNotification("info", "Updated Order", "Successfully updated fixed cost extras order.");
          setActiveModal(null);
        })
        .catch(() => {
          setActiveModal("error");
        });
    },
    [dispatch, items, params, reorder]
  );

  const data = useMemo(
    () => generateEditorTableData(items, currency, toggleItemStatus, onEditItem, onDeleteItem),
    [currency, items, onDeleteItem, onEditItem, toggleItemStatus]
  );

  const columns = useMemo(() => generateEditorColumns(data), [data]);

  const handleDeleteRow = () => {
    setActiveModal(null);
    const item = items.find((i) => i.id === pendingDeleteId);
    if (!item) return;

    remove(item.id)
      .unwrap()
      .then(() => {
        addNotification("info", `Deleted 1 row`, `Deleted "${item.name}"`);
        setActiveModal(null);
      })
      .catch(() => {
        setActiveModal("error");
      });
  };

  const renderModalButtons = () => {
    switch (activeModal) {
      case "delete":
        return (
          <>
            <Button variant="secondary" onClick={() => setActiveModal(null)} className="px-8">
              Cancel
            </Button>
            <Button variant="primary" onClick={handleDeleteRow} className="bg-danger px-8 hover:bg-danger-dark">
              Delete
            </Button>
          </>
        );
      default:
        return (
          <Button variant="primary" onClick={() => setActiveModal(null)} className="px-8">
            Back
          </Button>
        );
    }
  };

  return (
    <div className="relative">
      {activeModal && (
        <Modal
          open={true}
          title={modals[activeModal].title}
          description={modals[activeModal].description}
          onClose={() => setActiveModal(null)}
          renderButtons={renderModalButtons}
        />
      )}
      {(toggleLoading || removeLoading || reorderLoading) && <Loading />}
      <DataTable
        columns={columns}
        data={data}
        className={clsx("w-full overflow-x-auto [&_table]:table-auto", className)}
        onReorder={onReorder}
        {...props}
      />
    </div>
  );
};

const ExtrasTableEditorPagination = ({ page }: { page: Page }) => {
  const { current, size, total, count } = page;
  const { handlePageClick, handleSizeChange } = usePagination("config-fixed-cost-extras", 30);

  return (
    <Pagination
      onPageClick={handlePageClick}
      onSizeChange={handleSizeChange}
      currentPage={current}
      pageSize={size}
      totalItems={total}
      pageCount={count}
    />
  );
};

FixedCostExtrasTableEditor.Pagination = ExtrasTableEditorPagination;
