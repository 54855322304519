import { DropdownMenu, Icon, Typography } from "@/components/atoms";
import { getPhpHostUrl } from "@/helpers/apiHelpers";
import { OffloadDriver } from "@/redux/slices/driver/types";
import { getAssetUrl } from "@/utils";
import { renderRating } from "../MyDrivers/MyDrivers/helpers";
import { BlockedOffloadDriversTableData, OffloadDriversTableData } from "./types";

export const generateOffloadDriversData = (items: OffloadDriver[], onBlockDriver: (driver: OffloadDriver) => void) => {
  const data = items.map(
    (i) =>
      ({
        name: (
          <div className="flex  items-center gap-2.5">
            {renderOffloadAvatar(i.avatar, i.name)}
            <Typography variant="paragraph">{i.name}</Typography>
          </div>
        ),
        state: i.state,
        rating: renderRating(i.rating),
        totalJobs: i.tripCount || i.tripCount === 0 ? `${i.tripCount}` : "-",
        operator: (
          <div className="flex flex-row items-center gap-2.5">
            {renderOffloadAvatar(i.provider.assets.emblem, i.provider.name)}
            <Typography variant="paragraph">{i.provider.name}</Typography>
          </div>
        ),
        operatorContact: i.provider.phoneNumber,
        action: renderOffloadDriverActions(i, onBlockDriver),
      } as OffloadDriversTableData)
  );

  return data;
};

const renderOffloadDriverActions = (driver: OffloadDriver, onBlockDriver: (driver: OffloadDriver) => void) => {
  return (
    <DropdownMenu button={<Icon name="options" isCustom size="lg" />} position="bottom-start" className="!flex max-w-[24px]">
      <DropdownMenu.Item onClick={() => onBlockDriver(driver)}>
        <Icon name="ban-bold" size="sm" className="mr-2.5 text-danger" isCustom />
        <Typography variant="paragraph">Block Driver</Typography>
      </DropdownMenu.Item>
    </DropdownMenu>
  );
};

export const generateBlockedOffloadDriversData = (items: OffloadDriver[], onViewReason: (driver: OffloadDriver) => void) => {
  const phpHostUrl = getPhpHostUrl();
  const data = items.map(
    (i) =>
      ({
        name: (
          <div className="flex">
            <a
              href={`${phpHostUrl}/driver/${i.id}/profile/`}
              className="flex flex-row items-center hover:text-info"
              target="_blank"
              rel="noreferrer"
            >
              <Icon isCustom name="ban-bold" size="lg" className="mr-4 flex-shrink-0 text-danger" />
              {renderOffloadAvatar(i.avatar, i.name)}
              <Typography variant="paragraph" className="ml-2.5">
                {i.name}
              </Typography>
            </a>
          </div>
        ),
        state: i.state,
        rating: renderRating(i.rating),
        totalJobs: i.tripCount || i.tripCount === 0 ? `${i.tripCount}` : "-",
        operator: (
          <div className="flex flex-row items-center gap-2.5">
            {renderOffloadAvatar(i.provider.assets.emblem, i.provider.name)}
            <Typography variant="paragraph">{i.provider.name}</Typography>
          </div>
        ),
        operatorContact: i.provider.phoneNumber,
        reasonForBlocking: (
          <Typography variant="action" className="cursor-pointer text-info" onClick={() => onViewReason(i)}>
            View Reason
          </Typography>
        ),
      } as BlockedOffloadDriversTableData)
  );

  return data;
};

const renderOffloadAvatar = (avatar: string | null, name: string) => (
  <div>
    {avatar ? (
      <div className="h-6 w-6 rounded-full">
        <img className="rounded-full" src={getAssetUrl(avatar, { height: 24, width: 24, fit: "cover" })} alt={name} />
      </div>
    ) : (
      <div className="h-6 w-6 rounded-full bg-neutral-mid-gray" />
    )}
  </div>
);
