import { z } from "zod";

export const addDriverFormSchema = z.object({
  firstName: z.string().min(1, "Field is required and can't be empty.").max(45, "Maximum of 45 characters."),
  lastName: z.string().min(1, "Field is required and can't be empty.").max(45, "Maximum of 45 characters."),
  email: z.string().min(1, "Field is required and can't be empty.").max(100, "Maximum of 100 characters."),
  stateRegistered: z.string({ required_error: "Field is required and can't be empty." }),
  contactNumber: z.string().min(1, "Field is required and can't be empty."),
});

export type AddDriverFormData = z.infer<typeof addDriverFormSchema>;
