import { useMemo } from "react";
import { DataTable, NoSearchResults, Pagination } from "@/components/molecules";
import { usePagination } from "@/hooks";
import { useAppSelector } from "@/redux/hooks";
import { fleetDriversPageSelector } from "@/redux/slices/driver/selectors";
import { FleetDriver } from "@/redux/slices/driver/types";
import { ArchivedDriversEmptyState } from "./components/ArchivedDriversEmptyState";
import { archivedDriversColumns } from "./fixtures";
import { generateArchivedDriversTableData } from "./helpers";
import { useGetArchivedDriversParams } from "./hooks/useGetArchivedDriversParams";

interface ArchivedDriversTableProps {
  items: FleetDriver[];
}

export const ArchivedDriversTable = ({ items }: ArchivedDriversTableProps) => {
  const { sorting, setSorting, search, filters } = useGetArchivedDriversParams();
  const isFiltersEmpty = Object.values(filters).every((el) => el === undefined);

  const data = useMemo(
    () => generateArchivedDriversTableData(items),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [items]
  );

  const renderEmptyState = () => {
    if (search) return <NoSearchResults />;
    if (!isFiltersEmpty) return <ArchivedDriversEmptyState hasFilters />;

    return <ArchivedDriversEmptyState />;
  };

  return (
    <div className="relative">
      <DataTable
        className="w-full overflow-x-auto [&_table]:table-auto [&_tr]:bg-white"
        data={data}
        columns={archivedDriversColumns}
        sorting={sorting}
        setSorting={setSorting}
        emptyState={renderEmptyState()}
      />
    </div>
  );
};

const ArchivedDriversTablePagination = () => {
  const { current, size, total, count } = useAppSelector(fleetDriversPageSelector);
  const { handlePageClick, handleSizeChange } = usePagination("archived-drivers");

  return (
    <Pagination
      onPageClick={handlePageClick}
      onSizeChange={handleSizeChange}
      currentPage={current}
      pageSize={size}
      totalItems={total}
      pageCount={count}
    />
  );
};

ArchivedDriversTable.Pagination = ArchivedDriversTablePagination;
