import { useNavigate } from "react-router-dom";
import { Button, Container, Typography } from "@/components/atoms";
import { GeneralHeader } from "@/components/organisms";

interface NotFoundProps {
  noHeader?: boolean;
  noBackground?: boolean;
}

export const NotFound = ({ noHeader, noBackground }: NotFoundProps) => {
  const navigate = useNavigate();

  return (
    <div className="flex min-h-screen flex-row overflow-hidden bg-white">
      <div className="relative flex flex-1 flex-col">
        {!noBackground && (
          <div className="pointer-events-none fixed bottom-64 min-h-[100%] w-full bg-404-clouds bg-cover bg-no-repeat md:bottom-44 lg:bottom-72" />
        )}
        {!noHeader && <GeneralHeader />}
        <Container className="z-10 flex w-full flex-1 justify-center px-5 pb-32 pt-[14vh]">
          <div className="flex flex-col items-center">
            <Typography variant="title" className="mb-6 !text-[64px] md:!text-[92px]   2xl:!text-[160px]">
              404
            </Typography>
            <Typography variant="title" className="mb-3 text-center !text-[32px] md:!text-[48px] 2xl:!text-[60px]">
              Whoops... this page is not available
            </Typography>
            <Typography variant="paragraph" className="mb-8 text-center">
              We searched everywhere but couldn&apos;t find what you&apos;re looking for. Let&apos;s find a better place for you to go
            </Typography>
            <Button onClick={() => navigate(-1)} className="relative z-20" size="lg">
              Go Back
            </Button>
          </div>
        </Container>
        {!noBackground && <div className="pointer-events-none fixed bottom-0 min-h-[50%] w-full bg-city bg-cover bg-no-repeat" />}
      </div>
    </div>
  );
};
