import { useDispatch, useSelector } from "react-redux";
import type { TypedUseSelectorHook } from "react-redux";
import {
  accreditationApi,
  adminApi,
  assetApi,
  blockApi,
  bookingApi,
  clientApi,
  companyApi,
  driverApi,
  driverProfileApi,
  networkApi,
  paymentApi,
  pricingApi,
  qrApi,
  ratingApi,
  reportingApi,
  reportApi,
  documentApi,
  typeaheadApi,
  userAccountApi,
  profileApi,
  operatorApi,
  accountApi,
  jobRatingApi,
  paymentMethodApi,
} from "../apis";
import type { AppDispatch, RootState } from "../store";

// Use throughout your app instead of plain `useDispatch` and `useSelector`
export const useAppDispatch: () => AppDispatch = useDispatch;
export const useAppSelector: TypedUseSelectorHook<RootState> = useSelector;
export const useAppResetApi = () => {
  const dispatch = useAppDispatch();
  return () => {
    dispatch(profileApi.util.invalidateTags(["Profile"]));
    dispatch(operatorApi.util.invalidateTags(["Operators"]));
    dispatch(accountApi.util.invalidateTags(["Account"]));
    dispatch(adminApi.util.resetApiState());
    dispatch(pricingApi.util.resetApiState());
    dispatch(accreditationApi.util.resetApiState());
    dispatch(blockApi.util.resetApiState());
    dispatch(bookingApi.util.resetApiState());
    dispatch(clientApi.util.resetApiState());
    dispatch(companyApi.util.resetApiState());
    dispatch(assetApi.util.resetApiState());
    dispatch(driverApi.util.resetApiState());
    dispatch(driverProfileApi.util.resetApiState());
    dispatch(networkApi.util.resetApiState());
    dispatch(paymentApi.util.resetApiState());
    dispatch(qrApi.util.resetApiState());
    dispatch(ratingApi.util.resetApiState());
    dispatch(reportingApi.util.resetApiState());
    dispatch(reportApi.util.resetApiState());
    dispatch(documentApi.util.resetApiState());
    dispatch(typeaheadApi.util.resetApiState());
    dispatch(userAccountApi.util.resetApiState());
    dispatch(jobRatingApi.util.resetApiState());
    dispatch(paymentMethodApi.util.resetApiState());
  };
};
