import { isEmpty } from "ramda";
import { ClientSearchItem } from "@/redux/slices/client/types";
import { DriverSearchItem } from "@/redux/slices/driver/types";
import { VehicleSearchItem } from "@/redux/slices/pricing/types";
import {
  SearchClientRawResponse,
  SearchClientResponse,
  SearchCompanyRawResponse,
  SearchCompanyResponse,
  SearchDriverRawResponse,
  SearchDriverResponse,
  SearchVehicleRawResponse,
  SearchVehicleResponse,
} from "./types";

export const transformClient = (res: SearchClientRawResponse) => {
  const {
    _embedded: { client },
  } = res;

  const response: SearchClientResponse = client.map(
    (i) =>
      ({
        id: i.uuid,
        name: i.name,
        email: i.email,
        phone: i.phone,
        company: i.company
          ? {
              id: i.company.uuid,
              name: i.company.name,
            }
          : null,
        avatar: i.avatar,
      } as ClientSearchItem)
  );

  return response;
};

export const transformCompany = (res: SearchCompanyRawResponse) => {
  const {
    _embedded: { company },
  } = res;

  const response: SearchCompanyResponse = company.map((i) => ({
    id: i.uuid,
    name: i.name,
  }));

  return response;
};

export const transformDriver = (res: SearchDriverRawResponse) => {
  const {
    _embedded: { driver },
  } = res;

  const response: SearchDriverResponse = driver
    .map((i) => {
      const metadata = Object.entries(i.metadata);

      const [warnings, errors] = metadata.reduce(
        (acc, [key, value]) => {
          if (key.includes("warning") && value) acc[0].push(key);
          else if (key.includes("error") && value) acc[1].push({ key, value: value.toString() });
          return acc;
        },
        [[], []] as [string[], Array<{ key: string; value: string }>]
      );

      return {
        id: i.uuid,
        name: `${i.first_name} ${i.last_name}`,
        avatar: i.avatar ?? "",
        phone: i.mobile,
        email: i.email,
        operator: {
          id: i.provider.uuid,
          //todo: driver search item provider key
          providerKey: "",
          name: i.provider.name,
        },
        warnings,
        errors,
        recommendedCommissionRate: i.recommended_commission_rate ?? undefined,
      } as DriverSearchItem;
    })
    .sort((i) => (i.errors.length > 0 ? 1 : -1));

  return response;
};

export const transformVehicle = (res: SearchVehicleRawResponse) => {
  const {
    _embedded: { vehicle },
  } = res;

  const response: SearchVehicleResponse = vehicle
    .map((i) => {
      const metadata = Object.entries(i.metadata);
      const [warnings, errors] = metadata.reduce(
        (acc, [key, value]) => {
          if (key.includes("warning") && !!value && !isEmpty(value)) acc[0].push(key);
          else if (key.includes("error") && !!value && !isEmpty(value)) acc[1].push({ key, value: value.toString() });
          return acc;
        },
        [[], []] as [string[], Array<{ key: string; value: string }>]
      );

      return {
        id: i.uuid,
        registrationNumber: i.registration_number,
        image: i.avatar ?? "",
        year: i.year ?? "",
        model: i.model,
        color: i.color,
        warnings,
        errors,
      } as VehicleSearchItem;
    })
    .sort((i) => (i.errors.length > 0 ? 1 : -1));

  return response;
};
