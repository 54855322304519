import { useMemo } from "react";
import { useFormContext } from "react-hook-form";
import { CheckboxButton, ErrorMessage, Typography } from "@/components/atoms";
import { useGetVehicleCategoriesFormOptionsQuery } from "@/redux/apis/config/pricing/vehicleCategory/vehicleCategoryApi";
import { capitalize, clsx } from "@/utils";
import { AddVehicleCategoryFormData } from "../../form";

export const VehicleCategoryOffloadEngineTypesField = ({ className, ...props }: React.HTMLAttributes<HTMLDivElement>) => {
  const { isFetching, data } = useGetVehicleCategoriesFormOptionsQuery();
  const {
    register,
    formState: { errors },
  } = useFormContext<AddVehicleCategoryFormData>();

  const options = useMemo(() => {
    if (!data) return [];
    return data.engineType.map((engineType) => ({
      value: engineType,
      name: capitalize(engineType.toLowerCase()),
    }));
  }, [data]);

  if (isFetching || !data) return null;

  return (
    <div className={clsx("flex flex-col gap-y-2", className)} {...props}>
      <Typography variant="paragraph">Engine Type</Typography>

      <div className="flex flex-wrap items-center gap-3">
        {options.map((option, i) => (
          <CheckboxButton key={i} label={option.name} {...register("offloadEngineTypes")} value={option.value} />
        ))}
      </div>

      <ErrorMessage errors={errors} name="offloadEngineTypes" />
    </div>
  );
};
