import { zodResolver } from "@hookform/resolvers/zod";
import { FormProvider, useForm, useFormContext } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import { Button, Divider, Panel, Spinner, Typography } from "@/components/atoms";
import { getErrorMessages } from "@/helpers/reduxHelpers";
import { usePrompt } from "@/hooks";
import { useEditTransferTypeMutation } from "@/redux/apis/config/pricing/transferTypes";
import { addToast } from "@/utils";
import { PageHeader } from "../../PageHeader/PageHeader";
import { TransferTypeAdvancedSettings } from "../AddTransferType/AdvancedSettings/TransferTypeAdvancedSettings";
import { TransferTypeAssociatedCategories } from "../AddTransferType/AssociatedCategories/TransferTypeAssociatedCategories";
import { TransferTypeCostField } from "../AddTransferType/fields/TransferTypeCostField";
import { TransferTypeDescriptionField } from "../AddTransferType/fields/TransferTypeDescriptionField";
import { TransferTypeNameField } from "../AddTransferType/fields/TransferTypeNameField";
import { TransferTypeTripTypeField } from "../AddTransferType/fields/TransferTypeTripTypeField";
import { AddTransferTypeFormData, addTransferTypeFormSchema } from "../AddTransferType/form";
import { EditTransferTypeProvider, useEditTransferTypeContext } from "./context";

export const EditTransferTypePanel = () => {
  return (
    <EditTransferTypeProvider>
      <PageHeader.Actions />
      <div className="flex flex-col items-start gap-3 lg:flex-row-reverse">
        <Content />
      </div>
    </EditTransferTypeProvider>
  );
};

const Content = () => {
  const { transferType } = useEditTransferTypeContext();
  const form = useForm<AddTransferTypeFormData>({
    defaultValues: {
      name: transferType.name,
      description: transferType.description,
      tripType: transferType.type,
      costAdjustment: transferType.multiplier,
      isAdminOnly: transferType.isAdminOnly,
      isDispatchEnabled: transferType.isDispatchEnabled,
      isTaxExempt: transferType.taxStatus === "exempt",
      isSpecialEvent: transferType.isSpecialEvent,
      associatedCategories: transferType.vehicleCategories,
    },
    resolver: zodResolver(addTransferTypeFormSchema),
    shouldFocusError: true,
  });

  usePrompt({ when: form.formState.isDirty, message: "Changes will not be saved. Do you want to proceed?" });

  return (
    <FormProvider {...form}>
      <Panel className="flex w-full flex-col gap-5">
        <div>
          <Typography variant="h3">Edit Transfer Type</Typography>
          <Typography className="text-neutral-dark-gray">Edit option for your type of transfers</Typography>
        </div>
        <Form />
      </Panel>
    </FormProvider>
  );
};

const Form = () => {
  const navigate = useNavigate();
  const { transferType } = useEditTransferTypeContext();

  const form = useFormContext<AddTransferTypeFormData>();
  const [editTransferType, { isLoading }] = useEditTransferTypeMutation();

  const handleCancel = () => {
    navigate("./../");
  };

  const handleSave = async (data: AddTransferTypeFormData) => {
    editTransferType({
      uuid: transferType.uuid,
      name: data.name,
      description: data.description,
      type: data.tripType,
      multiplier: data.costAdjustment,
      is_admin_only: data.isAdminOnly,
      is_dispatch_enabled: data.isDispatchEnabled,
      is_special_event: data.isSpecialEvent,
      tax_status: data.isTaxExempt ? "exempt" : "liable",
      vehicle_categories: data.associatedCategories,
      is_active: transferType.isActive,
    })
      .unwrap()
      .then(() => {
        addToast("success", "Transfer Type updated successfully");
        navigate("./../");
      })
      .catch((e) => getErrorMessages(e).forEach((message) => addToast("danger", message)));
  };

  return (
    <form onSubmit={form.handleSubmit(handleSave)} id="edit-transfer-type-form" className="flex flex-col gap-5">
      <div className="flex w-full flex-col gap-5 md:flex-row">
        <TransferTypeNameField className="w-full" />
        <TransferTypeDescriptionField className="w-full" />
        <TransferTypeCostField className="w-[170px]" />
      </div>
      <TransferTypeTripTypeField />
      <Divider className="mt-5" />
      <TransferTypeAssociatedCategories />
      <TransferTypeAdvancedSettings />
      <div className="col-span-2 flex justify-end gap-4">
        <Button onClick={handleCancel} variant="secondary" size="lg" disabled={isLoading}>
          Cancel
        </Button>
        <Button variant="primary" size="lg" type="submit" form="edit-transfer-type-form" disabled={isLoading || !form.formState.isDirty}>
          {isLoading ? <Spinner /> : "Save Transfer Type"}
        </Button>
      </div>
    </form>
  );
};
