import { Controller, useFormContext } from "react-hook-form";
import { CheckboxInput, Typography } from "@/components/atoms";
import { AddVehicleCategoryFormData } from "../../form";
import { VehicleMakesModelsFilter } from "./VehicleMakesModelsFilter";
import { useVehicleCategoryOffloadModelsContext } from "./context";

export const VehicleModelsTable = () => {
  const { activeMake, options, isLoading } = useVehicleCategoryOffloadModelsContext();
  const { control } = useFormContext<AddVehicleCategoryFormData>();

  return (
    <div className="flex w-full flex-1 flex-col md:w-[340px] md:max-w-[75%]">
      <header className="flex h-[45px] w-full items-center justify-between gap-3 rounded-tr-lg bg-neutral-gray p-5 pb-2">
        <Typography variant="action">Model</Typography>
        <VehicleMakesModelsFilter />
      </header>
      <div
        className={`flex max-h-[250px] w-full flex-1 flex-col items-start gap-3 overflow-auto bg-white px-5 py-3 ${
          isLoading ? "pointer-events-none opacity-50" : ""
        } ${!activeMake ? "invisible" : ""}`}
      >
        <Typography variant="action" className="capitalize">
          {activeMake}
        </Typography>
        <Controller
          control={control}
          name="offloadVehicleModels"
          render={({ field }) => (
            <>
              {options.filteredModels.map((model, i) => (
                <CheckboxInput
                  key={i}
                  label={model.model}
                  value={model.uuid}
                  onChange={() => {
                    field.onChange(
                      field.value?.includes(model.uuid)
                        ? field.value?.filter((v) => v !== model.uuid)
                        : [...(field.value ?? []), model.uuid]
                    );
                  }}
                  checked={field.value && field.value.length > 0 ? field.value.includes(model.uuid) : false}
                  variant="check"
                />
              ))}
            </>
          )}
        />
      </div>
    </div>
  );
};
