import { z } from "zod";
import { signUpDriverFormSchema, signUpOperatorFormSchema, signUpClientFormSchema, signUpInviteFormSchema } from "./schemas";

export type SignUpDriverFormData = z.infer<typeof signUpDriverFormSchema>;

export type SignUpOperatorFormData = z.infer<typeof signUpOperatorFormSchema>;

export type SignUpClientFormData = z.infer<typeof signUpClientFormSchema>;

export type SignUpInviteFormData = z.infer<typeof signUpInviteFormSchema>;

export const signUpFormDataKey: { [key: string]: string } = {
  first_name: "firstName",
  last_name: "lastName",
  company_name: "company",
  job_title: "jobTitle",
  email: "email",
  mobile: "contactNumber",
  password: "password.password",
  hear_about_us: "howDidYouHearAboutUs",
  states_of_operation: "statesOfOperation",
  business_type: "businessType",
  business_registration_type: "businessRegistration.businessRegistrationType",
  business_registration_id: "businessRegistration.businessRegistrationIdNumber",
  business_official_name: "businessOfficialName",
  business_name: "businessTradingName",
  website_address: "websiteAddress",
  business_tagline: "businessTagline",
  business_address: "businessAddress",
  years_in_operation: "yearsInOperation",
  total_annual_turnover: "totalAnnualTurnover",
  number_anticipated_annual_bookings: "numberOfAnticipatedAnnualBookings",
  average_trip_cost: "averageTripCost",
  number_individual_travellers: "numberOfIndividualTravelers",
  number_vehicles_owned: "numberOfVehiclesYouOwnAndOperate",
  number_internal_drivers: "numberOfInternalDrivers",
  number_partner_drivers: "numberOfPartnerDrivers",
  office_handling_operations: "officeHandlingOperations",
  platform_goals: "platformGoals",
  platform_fit: "platformFit",
  date_of_birth: "dateOfBirth",
  state_registered: "stateRegistered",
  driver_licence: "driverLicense",
  model_uuid: "vehicle.makeAndModel",
  color: "vehicle.color",
  year: "vehicle.year",
};
