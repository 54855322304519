import { useNavigate } from "react-router-dom";
import { Modal } from "@/components/atoms";
import { AddFixedCostExtrasForm } from "@/components/organisms";

export const FixedCostExtrasAdd = () => {
  const navigate = useNavigate();

  return (
    <Modal open title="Add Fixed Cost Extra" onClose={() => navigate("../")}>
      <AddFixedCostExtrasForm />
    </Modal>
  );
};
