import { Link } from "react-router-dom";
import { DropdownMenu, Icon, Typography } from "@/components/atoms";
import { getPhpHostUrl } from "@/helpers/apiHelpers";
import { FleetDriver } from "@/redux/slices/driver/types";
import { renderFleetAvatar, renderFleetStatus } from "../MyDrivers/MyDrivers/helpers";
import { NewStatuses } from "../MyDrivers/MyDrivers/types";
import { ArchivedDriversTableData } from "./types";

export const generateArchivedDriversTableData = (items: FleetDriver[]) => {
  const data = items.map(
    (i) =>
      ({
        name: renderDriverName(i),
        email: i.email,
        mobile: i.phone,
        status: renderFleetStatus(i.status),
        // actionDate: "",
        action: renderArchivedDriverActions(i.uuid),
      } as ArchivedDriversTableData)
  );

  return data;
};

const renderDriverName = (driver: FleetDriver) => {
  const phpHostUrl = getPhpHostUrl();

  const useNewDriverProfile = import.meta.env.DEV || NewStatuses.includes(driver.status);
  if (useNewDriverProfile) {
    return (
      <Link to={driver.uuid}>
        <div className="flex flex-row items-center gap-2.5 hover:text-info">
          {renderFleetAvatar(driver.avatar, driver.name, driver.isFleetLeader)}
          <Typography variant="paragraph">{driver.name}</Typography>
        </div>
      </Link>
    );
  }

  return (
    <a
      href={`${phpHostUrl}/driver/${driver.uuid}/profile/`}
      className="flex flex-row items-center gap-2.5 hover:text-info"
      target="_blank"
      rel="noreferrer"
    >
      <div className="flex flex-row items-center gap-2.5 hover:text-info">
        {renderFleetAvatar(driver.avatar, driver.name, driver.isFleetLeader)}
        <Typography variant="paragraph">{driver.name}</Typography>
      </div>
    </a>
  );
};

const renderArchivedDriverActions = (driverId: string) => {
  const phpHostUrl = getPhpHostUrl();
  return (
    <DropdownMenu button={<Icon name="options" isCustom size="lg" />} position="bottom-start" className="!flex max-w-[24px]">
      <a href={`${phpHostUrl}/driver/${driverId}/profile/`} target="_blank" rel="noreferrer">
        <DropdownMenu.Item>
          <Icon name="UserSquare" size="sm" className="mr-2.5" />
          <Typography variant="paragraph">Manage Driver</Typography>
        </DropdownMenu.Item>
      </a>
    </DropdownMenu>
  );
};
