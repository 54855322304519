import { useClose } from "@headlessui/react";
import { zodResolver } from "@hookform/resolvers/zod";
import { useDebouncedEffect } from "@react-hookz/web";
import { useMemo } from "react";
import { Controller, useForm } from "react-hook-form";
import { z } from "zod";
import { DropdownSelect, Typography } from "@/components/atoms";
import { FilterDrawer } from "../common/FilterDrawer";
import { FilterPopover } from "../common/FilterPopover";
import { useVehicleFilters } from "../useVehicleFilters";

const schema = z.object({
  pax: z.string(),
});

type FormData = z.infer<typeof schema>;

export const PaxFilter = () => {
  const {
    filters: { minCapacityPax },
    setFilter,
  } = useVehicleFilters();

  const value = useMemo(() => minCapacityPax, [minCapacityPax]);

  const onClose = () => setFilter("minCapacityPax", undefined);

  return (
    <FilterPopover
      className="overflow-visible"
      name="minCapacityPax"
      value={value?.toString()}
      label="Minimum Pax Capacity"
      onClose={onClose}
    >
      <PaxFilterForm />
    </FilterPopover>
  );
};

interface PaxFilterDrawerProps {
  open?: boolean;
  onOpenChange?: (open: boolean) => void;
}

export const PaxFilterDrawer = ({ open, onOpenChange }: PaxFilterDrawerProps) => {
  const {
    filters: { minCapacityPax },
    setFilter,
  } = useVehicleFilters();

  const value = useMemo(() => minCapacityPax, [minCapacityPax]);

  const onClose = () => setFilter("minCapacityPax", undefined);

  return (
    <FilterDrawer open={open} onOpenChange={onOpenChange} value={value?.toString()} label="Pax Capacity" onClose={onClose}>
      <PaxFilterForm />
    </FilterDrawer>
  );
};

const PaxFilterForm = () => {
  const close = useClose();
  const { setFilter, filters } = useVehicleFilters();

  const { watch, control } = useForm<FormData>({
    resolver: zodResolver(schema),
    defaultValues: { pax: filters.minCapacityPax ? filters.minCapacityPax.toString() : "" },
  });

  const value = watch("pax");

  useDebouncedEffect(
    () => {
      setFilter("minCapacityPax", value);
    },
    [value],
    300
  );

  return (
    <form
      className="contents"
      onSubmit={(event) => {
        event.preventDefault();
        close();
      }}
    >
      <Typography className="mb-4">Set Minimum Pax Capacity</Typography>
      <Controller
        control={control}
        name="pax"
        render={({ field }) => (
          <DropdownSelect
            isNullable
            defaultValue="0"
            placeholder="Select Minimum Capacity"
            options={Array.from({ length: 99 }, (_, i) => (i + 1).toString())}
            value={field.value?.toString()}
            onChange={(value: string) => {
              field.onChange(+value);
            }}
          />
        )}
      />
    </form>
  );
};
