import { useForm } from "react-hook-form";
import { Button, Icon, Loading, Modal, TextArea, Typography } from "@/components/atoms";
import { getErrorMessages } from "@/helpers/reduxHelpers";
import { driverApi } from "@/redux/apis";
import { useBlockDriverMutation } from "@/redux/apis/block/blockApi";
import { useAppDispatch } from "@/redux/hooks";
import { OffloadDriver } from "@/redux/slices/driver/types";
import { addToast } from "@/utils";
import { BlockDriverFormData } from "../../OffloadDrivers/form";

interface BlockDriverModalProps {
  open: boolean;
  onClose: () => void;
  driver?: OffloadDriver;
}

export const BlockDriverModal = ({ open, onClose, driver }: BlockDriverModalProps) => {
  const dispatch = useAppDispatch();
  const [blockDriver, { isLoading }] = useBlockDriverMutation();
  const { register, handleSubmit } = useForm<BlockDriverFormData>();

  const onSubmit = handleSubmit((data) => {
    if (driver) {
      blockDriver({ id: driver.id, comment: data.reason })
        .unwrap()
        .then(() => {
          addToast("success", `Blocked ${driver.name}`);
          dispatch(driverApi.util.invalidateTags(["OffloadDrivers"]));
          onClose();
        })
        .catch((e) => getErrorMessages(e).forEach((m) => addToast("danger", m)));
    } else onClose();
  });

  const handleOnClose = () => {
    if (!isLoading) onClose();
  };

  const renderHeader = () => (
    <div className="flex flex-row items-center">
      <Typography variant="title" className="flex-1">
        Reason for Blocking Driver
      </Typography>
      <Icon name="close" isCustom className="flex cursor-pointer justify-end" onClick={handleOnClose} />
    </div>
  );

  return (
    <Modal open={open} onClose={handleOnClose} className="relative w-[85vw] md:w-[550px] lg:w-[550px]">
      {isLoading && <Loading />}
      {renderHeader()}
      <form className="flex flex-col" onSubmit={onSubmit}>
        <TextArea placeholder="Enter your reason" maxLength={1024} className="mt-3" {...register("reason")} />
        <Typography variant="paragraph" className="mt-4 text-neutral-dark-gray">
          Blocking a driver will stop them completing work on your behalf. The reason you will enter will not be visible to the driver
        </Typography>
        <Button type="submit" size="lg" className="mt-8">
          Submit Response
        </Button>
      </form>
    </Modal>
  );
};
