import { differenceInWeeks, format } from "date-fns";
import { useMemo } from "react";
import { useNavigate } from "react-router-dom";
import { Icon, IconButton, Tooltip, Typography } from "@/components/atoms";
import { useProfile } from "@/hooks";
import { getRegistrationStatus } from "../../MyVehicles/helpers";
import { VehicleDetailCard } from "../common/VehicleDetailCard";
import { useViewVehicleContext } from "../context";

export const RegistrationExpiryDetailCard = () => {
  const navigate = useNavigate();
  const { dateFormat } = useProfile();
  const { vehicle } = useViewVehicleContext();
  const { registration } = vehicle;

  const status = getRegistrationStatus(vehicle);

  const tooltip = useMemo(() => {
    const expiringInWeeks = registration.expiryDate
      ? differenceInWeeks(new Date(registration.expiryDate), new Date(), {
          roundingMethod: "ceil",
        })
      : undefined;

    switch (status) {
      case "expiring":
        return (
          <Typography className="leading-tight">
            Vehicle registration expiring in {expiringInWeeks} {expiringInWeeks === 1 ? "week" : "weeks"}. <br /> Kindly update the
            registration date
          </Typography>
        );
      case "expired":
        return (
          <Typography className="leading-tight">
            Vehicle registration expired.
            <br /> This vehicle is now blocked from
            <br /> accepting network jobs
          </Typography>
        );

      case "missing-details":
        return <Typography className="leading-tight">Missing vehicle registration details.</Typography>;

      case "verified":
      default:
        return undefined;
    }
  }, [registration.expiryDate, status]);

  const ActionButton = () => {
    switch (status) {
      case "expiring":
        return (
          <Tooltip content="Renew Verification" placement="bottom">
            <IconButton onClick={() => navigate("./edit")} iconName="Refresh2" variant="primary" size="sm" className="relative z-10" />
          </Tooltip>
        );
      case "expired":
        return (
          <Tooltip content="Renew Verification" placement="bottom">
            <IconButton onClick={() => navigate("./edit")} iconName="Refresh2" variant="primary" size="sm" className="relative z-10" />
          </Tooltip>
        );
      default:
        return null;
    }
  };

  const description = registration.expiryDate
    ? `${status === "expired" ? "Expired" : "Expires"} on: ${format(new Date(registration.expiryDate), dateFormat)}`
    : "N/A";

  return (
    <VehicleDetailCard
      icon={<Icon name="Verify" size="xl" className="text-neutral-black" />}
      description={description}
      title={
        <>
          Vehicle Registration
          <StatusBadge status={status} />
        </>
      }
      tooltip={tooltip}
      status={status}
      action={<ActionButton />}
      actionClick={status === "expiring" || status === "expired" ? () => navigate("./edit") : undefined}
    />
  );
};

const StatusBadge = ({ status }: { status: string }) => {
  switch (status) {
    case "verified":
      return <Icon name="Verify" className="text-info" variant="Bold" size="md" />;
    case "expiring":
      return <Icon name="Danger" size="md" className="text-warning" variant="Bold" />;
    case "unknown":
    case "missing-details":
    case "expired":
      return <Icon name="InfoCircle" size="md" className=" text-danger" variant="Bold" />;
    default:
      return null;
  }
};
