export const iconsOptions = [
  {
    name: "Baby",
    icon: "extras-baby-seat",
    value: "baby-seat",
  },
  {
    name: "Stop Over",
    icon: "extras-stop-over",
    value: "stop-over",
  },
  {
    name: "Booking",
    icon: "extras-phone-booking",
    value: "phone-booking",
  },
  {
    name: "Luggage",
    icon: "extras-luggage-or-bag",
    value: "luggage-or-bag",
  },
  {
    name: "Others",
    icon: "extras-others",
    value: "others",
  },
];

export const modals = {
  exit: {
    title: "Discard Changes",
    description: "Changes will not be saved. Do you want to proceed?",
  },
  delete: {
    title: "Are you sure?",
    description: "This will delete this fixed extra cost permanently. You cannot undo this action, do you wish to continue?",
  },
  error: {
    title: "Unable to do this action",
    description: "Unable to do this action because some error occured.",
  },
};
