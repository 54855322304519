import { zodResolver } from "@hookform/resolvers/zod";
import { useEffect, useRef, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import { useNavigate, useParams } from "react-router-dom";
import { Avatar, Button, CheckboxInput, ErrorMessage, Loading, Panel, Stars, TextArea, Typography } from "@/components/atoms";
import { getErrorMessages } from "@/helpers/reduxHelpers";
import { useSubmitRatingMutation } from "@/redux/apis/jobRating/jobRatingApi";
import { GetJobDetailsRawResponse } from "@/redux/apis/jobRating/types";
import { addToast, clsx, getAssetUrl } from "@/utils";
import { jobRatingFormSchema } from "./fixtures";
import { JobRatingFormData } from "./type";

interface JobRatingPanelProps extends React.HTMLAttributes<HTMLDivElement> {
  job: GetJobDetailsRawResponse;
}

export const JobRatingPanel = ({ job, className, ...props }: JobRatingPanelProps) => {
  const navigate = useNavigate();
  const { jobId, actionKey } = useParams();
  const [submitRating, { isLoading }] = useSubmitRatingMutation();
  const {
    control,
    register,
    handleSubmit,
    formState: { isDirty, errors },
  } = useForm<JobRatingFormData>({
    resolver: zodResolver(jobRatingFormSchema),
    defaultValues: {
      vehicleRating: 0,
      driverRating: 0,
      professionalismRating: 0,
      overallServiceRating: 0,
      bookingExperienceRating: 0,
      doNotMatch: false,
    },
  });

  const containerRef = useRef<HTMLDivElement>(null);
  const [containerWidth, setContainerWidth] = useState<number>();

  useEffect(() => {
    if (containerRef.current) setContainerWidth(containerRef.current.offsetWidth);
  }, [containerRef]);

  const onSubmit = handleSubmit((data) => {
    if (jobId && actionKey) {
      submitRating({
        id: jobId,
        actionKey,
        ratings: {
          vehicle: data.vehicleRating,
          driver: data.driverRating,
          professionalism: data.professionalismRating,
          overall_service: data.overallServiceRating,
          booking_experience: data.bookingExperienceRating,
        },
        comments: data.comments,
        do_not_match: data.doNotMatch,
      })
        .unwrap()
        .then(() => navigate("success"))
        .catch((e) => getErrorMessages(e).forEach((m) => addToast("danger", m)));
    }
  });

  const renderAvatar = (avatar: string | null, alt: string) => {
    if (!avatar || avatar.includes("COMING_SOON")) return <div className="h-12 w-12 rounded-full bg-neutral-mid-gray" />;
    else return <Avatar src={getAssetUrl(avatar)} alt={alt} size="md" />;
  };

  const renderHeader = () => {
    if (job) {
      const { driver, vehicle } = job;
      return (
        <div className="flex flex-col rounded-lg bg-neutral-surface-gray sm:flex-row">
          <div className="flex w-full flex-row gap-4 px-5 py-4">
            {renderAvatar(driver.avatar, driver.name)}
            <div className="flex flex-col">
              <Typography variant="action">{driver.name}</Typography>
              {driver.share_code && (
                <Typography variant="small" className="text-neutral-dark-gray">
                  Driver ID: {driver.share_code}
                </Typography>
              )}
            </div>
          </div>
          <div className="mx-1.5 border-t border-neutral-mid-gray sm:my-1.5 sm:border-r" />
          <div className="flex w-full flex-row gap-4 px-5 py-4">
            {renderAvatar(vehicle.model.avatar, `${vehicle.model.make} ${vehicle.model.model}`)}
            <div className="flex flex-col">
              <Typography variant="action">{`${vehicle.model.make} ${vehicle.model.model}`}</Typography>
              {vehicle.registration_number && (
                <Typography variant="small" className="text-neutral-dark-gray">
                  Number Plate: {vehicle.registration_number}
                </Typography>
              )}
            </div>
          </div>
        </div>
      );
    }
  };

  return (
    <div
      className={clsx("flex w-full flex-1 justify-center px-5 pb-[74px] pt-[30px] sm:px-10 sm:pb-[148px] sm:pt-[60px]", className)}
      ref={containerRef}
      {...props}
    >
      <Panel className="relative max-w-[748px] px-[30px] py-6">
        {isLoading && <Loading className="rounded-lg" />}
        {renderHeader()}
        <div className="mt-6 flex flex-col">
          <Typography variant="h2">
            How was your trip{job ? ` with ${job.driver.nickname ? job.driver.nickname : job.driver.name} ` : ` us`}?
          </Typography>
          <Typography variant="paragraph" className="text-neutral-dark-gray">
            Your input ensures we have the best drivers in the network - providing exceptional service every time.
          </Typography>
        </div>
        <form onSubmit={onSubmit}>
          {containerWidth && (
            <div className="mt-6 flex flex-col gap-4">
              <div className="flex flex-col">
                <div className="flex flex-col sm:flex-row sm:items-center">
                  <Typography variant="paragraph" className="flex-1">
                    Vehicle
                  </Typography>
                  <Controller
                    name="vehicleRating"
                    control={control}
                    render={({ field }) => (
                      <Stars
                        size={containerWidth > 600 ? 24 : 38}
                        color1="#D1D2D5"
                        value={field.value}
                        onChange={(newRating) => field.onChange(newRating)}
                      />
                    )}
                  />
                </div>
                <ErrorMessage errors={errors} name="vehicleRating" className="-mt-1.5" />
              </div>
              <div className="flex flex-col">
                <div className="flex flex-col sm:flex-row sm:items-center">
                  <Typography variant="paragraph" className="flex-1">
                    Driver
                  </Typography>
                  <Controller
                    name="driverRating"
                    control={control}
                    render={({ field }) => (
                      <Stars
                        size={containerWidth > 600 ? 24 : 38}
                        color1="#D1D2D5"
                        value={field.value}
                        onChange={(newRating) => field.onChange(newRating)}
                      />
                    )}
                  />
                </div>
                <ErrorMessage errors={errors} name="driverRating" className="-mt-1.5" />
              </div>
              <div className="flex flex-col">
                <div className="flex flex-col sm:flex-row sm:items-center">
                  <Typography variant="paragraph" className="flex-1">
                    Professionalism
                  </Typography>
                  <Controller
                    name="professionalismRating"
                    control={control}
                    render={({ field }) => (
                      <Stars
                        size={containerWidth > 600 ? 24 : 38}
                        color1="#D1D2D5"
                        value={field.value}
                        onChange={(newRating) => field.onChange(newRating)}
                      />
                    )}
                  />
                </div>
                <ErrorMessage errors={errors} name="professionalismRating" className="-mt-1.5" />
              </div>
              <div className="flex flex-col">
                <div className="flex flex-col sm:flex-row sm:items-center">
                  <Typography variant="paragraph" className="flex-1">
                    Overall Service
                  </Typography>
                  <Controller
                    name="overallServiceRating"
                    control={control}
                    render={({ field }) => (
                      <Stars
                        size={containerWidth > 600 ? 24 : 38}
                        color1="#D1D2D5"
                        value={field.value}
                        onChange={(newRating) => field.onChange(newRating)}
                      />
                    )}
                  />
                </div>
                <ErrorMessage errors={errors} name="overallServiceRating" className="-mt-1.5" />
              </div>
              <div className="flex flex-col">
                <div className="flex flex-col sm:flex-row sm:items-center">
                  <Typography variant="paragraph" className="flex-1">
                    Booking Experience
                  </Typography>
                  <Controller
                    name="bookingExperienceRating"
                    control={control}
                    render={({ field }) => (
                      <Stars
                        size={containerWidth > 600 ? 24 : 38}
                        color1="#D1D2D5"
                        value={field.value}
                        onChange={(newRating) => field.onChange(newRating)}
                      />
                    )}
                  />
                </div>
                <ErrorMessage errors={errors} name="bookingExperienceRating" className="-mt-1.5" />
              </div>
            </div>
          )}
          <div className="mt-6 flex flex-col">
            <div className="mb-4 flex flex-col">
              <Typography variant="paragraph" className="text-neutral-dark-gray">
                Message{job && ` to ${job.provider.name}`}
              </Typography>
              <TextArea rows={3} className="mt-1.5" maxLength={2048} {...register("comments")} />
            </div>
            <CheckboxInput label="Don't match me with this driver again" labelClassName="whitespace-normal" {...register("doNotMatch")} />
            <div className="mt-4 flex justify-end">
              <Button type="submit" variant="primary" size="lg" className="w-full sm:w-fit" disabled={!isDirty}>
                Submit Rating
              </Button>
            </div>
          </div>
        </form>
      </Panel>
    </div>
  );
};
