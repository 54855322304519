import { Controller, useFormContext } from "react-hook-form";
import { DropdownSelect, ErrorMessage, Typography } from "@/components/atoms";
import { clsx } from "@/utils";
import { AddVehicleCategoryFormData } from "../form";

export const VehicleCategoryCapacityFields = ({ className, ...props }: React.HTMLAttributes<HTMLDivElement>) => {
  const {
    control,
    formState: { errors },
  } = useFormContext<AddVehicleCategoryFormData>();

  return (
    <div className={clsx("col-span-2 grid w-full grid-cols-2  gap-5 md:col-span-1", className)} {...props}>
      <div className="flex flex-col gap-y-1">
        <Typography variant="paragraph">Max Capacity</Typography>
        <Controller
          control={control}
          name="capacity"
          render={({ field }) => (
            <DropdownSelect
              className="w-full [&_.dropdown-panel]:z-20"
              defaultValue="0"
              placeholder="Select Maximum Capacity"
              options={Array.from({ length: 100 }, (_, i) => (i + 1).toString())}
              value={field.value?.toString()}
              onChange={(value: string) => {
                field.onChange(+value);
              }}
            />
          )}
        />
        <ErrorMessage errors={errors} name="capacity" />
      </div>
      <div className="flex flex-col gap-y-1">
        <Typography variant="paragraph">No. of Bags</Typography>

        <Controller
          control={control}
          name="bags"
          render={({ field }) => (
            <DropdownSelect
              className="w-full [&_.dropdown-panel]:z-20"
              defaultValue="0"
              placeholder="Enter No. of Bags"
              options={Array.from({ length: 100 }, (_, i) => i.toString())}
              value={field.value?.toString()}
              onChange={(value: string) => {
                field.onChange(+value);
              }}
            />
          )}
        />

        <ErrorMessage errors={errors} name="bags" />
      </div>
    </div>
  );
};
