import { useCallback, useMemo, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Button, Modal, Loading } from "@/components/atoms";
import { DataTable, Pagination } from "@/components/molecules";
import { getErrorMessages } from "@/helpers/reduxHelpers";
import { usePagination, useSorting } from "@/hooks";
import {
  transferTypesApi,
  useDeleteTransferTypeMutation,
  useReorderTransferTypeMutation,
  useToggleTransferTypeMutation,
} from "@/redux/apis/config/pricing/transferTypes";
import { useAppDispatch } from "@/redux/hooks";
import { TransferType } from "@/redux/slices/vehicle/types";
import { Page } from "@/redux/types";
import { addNotification, clsx } from "@/utils";
import { modals } from "./fixtures";
import { generateEditorColumnsV2, generateTableEditorV2Data } from "./helpers";
import { useGetTransferTypesParams } from "./hooks/useGetTransferTypesParams";
import { TransferTypeTableDataV2 } from "./types";

interface TransferTypesTableEditorV2Props extends React.HTMLAttributes<HTMLDivElement> {
  items: TransferType[];
}

export const TransferTypesTableEditorV2 = ({ items, className, ...props }: TransferTypesTableEditorV2Props) => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const { params } = useGetTransferTypesParams();
  const { sorting, setSorting } = useSorting();
  const [pendingDeleteId, setPendingDeleteId] = useState<string | null>(null);
  const [toggle, { isLoading: toggleLoading }] = useToggleTransferTypeMutation();
  const [remove, { isLoading: removeLoading, error, reset: resetDelete }] = useDeleteTransferTypeMutation();
  const [reorder, { isLoading: reorderLoading }] = useReorderTransferTypeMutation();
  const [activeModal, setActiveModal] = useState<"error" | "exit" | "delete" | null>(null);
  const isLoading = toggleLoading || removeLoading || reorderLoading;

  const onEditItem = useCallback(
    (item: TransferType) => {
      navigate(`./${item.uuid}`);
    },
    [navigate]
  );

  const toggleItemStatus = useCallback(
    (id: string, active: boolean) => {
      const item = items.find((i) => i.uuid === id);
      if (!item) return;

      toggle({ uuid: id, is_active: active })
        .unwrap()
        .then(() => {
          dispatch(
            transferTypesApi.util.updateQueryData("getVehicleTransferTypes", params, (draft) => {
              draft.transferTypes.find((i) => i.uuid === id)!.isActive = active;
            })
          );
          addNotification("info", `Active status: ${active ? "On" : "Off"}`, `Changed active status for "${item.name}".`);
        })
        .catch(() => setActiveModal("error"));
    },
    [dispatch, items, params, toggle]
  );

  const onReorder = useCallback(
    (sortedItems: TransferTypeTableDataV2[]) => {
      const mapping = sortedItems.reduce((acc, item, index) => {
        acc[item.id] = index;
        return acc;
      }, {} as Record<string, number>);

      dispatch(
        transferTypesApi.util.updateQueryData("getVehicleTransferTypes", params, (draft) => {
          const newItems = items.map((item) => ({ ...item, order: mapping[item.uuid] })).sort((a, b) => a.order - b.order);
          Object.assign(draft.transferTypes, newItems);
        })
      );

      reorder(mapping)
        .unwrap()
        .then(() => {
          addNotification("info", "Updated Order", "Successfully updated transfer type order.");
          setActiveModal(null);
        })
        .catch(() => {
          setActiveModal("error");
        });
    },
    [dispatch, items, params, reorder]
  );

  const onDeleteItem = useCallback(
    (item: TransferType) => {
      resetDelete();
      setPendingDeleteId(item.uuid);
      setActiveModal("delete");
    },
    [resetDelete]
  );

  const handleDeleteItem = useCallback(() => {
    setActiveModal(null);
    const item = items.find((i) => i.uuid === pendingDeleteId);
    if (!item) return;

    remove(item.uuid)
      .unwrap()
      .then(() => {
        dispatch(
          transferTypesApi.util.updateQueryData("getVehicleTransferTypes", params, (draft) => {
            draft.transferTypes = draft.transferTypes.filter((i) => i.uuid !== item.uuid);
          })
        );
        addNotification("info", `Deleted 1 row`, `Deleted "${item.name}"`);
        setActiveModal(null);
      })
      .catch(() => {
        setActiveModal("error");
      });
  }, [dispatch, items, params, pendingDeleteId, remove]);

  const renderModalButtons = () => {
    switch (activeModal) {
      case "delete":
        return (
          <>
            <Button variant="secondary" onClick={() => setActiveModal(null)} className="px-8">
              Cancel
            </Button>
            <Button variant="primary" onClick={handleDeleteItem} className="bg-danger px-8 hover:bg-danger-dark">
              Delete
            </Button>
          </>
        );
      default:
        return (
          <Button variant="primary" onClick={() => setActiveModal(null)} className="px-8">
            Back
          </Button>
        );
    }
  };

  const renderModal = () => {
    if (!activeModal) return null;

    const description = activeModal !== "error" ? modals[activeModal].description : error ? getErrorMessages(error)[0] : "";

    return (
      <Modal
        open={true}
        title={modals[activeModal].title}
        description={description}
        onClose={() => setActiveModal(null)}
        renderButtons={renderModalButtons}
      />
    );
  };

  const data = useMemo(
    () => generateTableEditorV2Data(items, toggleItemStatus, onEditItem, onDeleteItem),
    [items, onDeleteItem, onEditItem, toggleItemStatus]
  );

  const columns = useMemo(() => generateEditorColumnsV2(data), [data]);

  return (
    <div className="relative">
      {renderModal()}
      {isLoading && <Loading className="rounded-lg" />}
      <DataTable
        columns={columns}
        data={data}
        className={clsx("w-full overflow-x-auto [&_table]:table-auto", className)}
        onReorder={onReorder}
        sorting={sorting}
        setSorting={setSorting}
        {...props}
      />
    </div>
  );
};

const TransferTypesTableEditorV2Pagination = ({ page }: { page: Page }) => {
  const { current, size, total, count } = page;
  const { handlePageClick, handleSizeChange } = usePagination("config-transfer-types", 30);

  return (
    <Pagination
      onPageClick={handlePageClick}
      onSizeChange={handleSizeChange}
      currentPage={current}
      pageSize={size}
      totalItems={total}
      pageCount={count}
    />
  );
};

TransferTypesTableEditorV2.Pagination = TransferTypesTableEditorV2Pagination;
