import { subYears } from "date-fns";
import isValidABN from "is-valid-abn";
import { z } from "zod";

export const editEmergencyContactFormSchema = z.object({
  contactName: z.string().max(50, "Maximum of 50 characters").optional(),
  contactNumber: z
    .string({ invalid_type_error: "Please enter a valid phone number" })
    .min(1, "Field is required and can't be empty")
    .max(45, "Maximum of 45 characters"),
});

export const editDriverDetailsFormSchema = z
  .object({
    firstName: z.string().min(1, "Field is required and can't be empty").max(45, "Maximum of 45 characters."),
    lastName: z.string().min(1, "Field is required and can't be empty").max(45, "Maximum of 45 characters."),
    preferredName: z.string().max(45, "Maximum of 45 characters.").optional(),
    mobile: z
      .string({ invalid_type_error: "Please enter a valid phone number" })
      .min(1, "Field is required and can't be empty")
      .max(45, "Maximum of 45 characters"),
    dateOfBirth: z
      .date({ required_error: "Field is required and can't be empty." })
      .max(subYears(new Date(), 21), { message: "Age is too young. Please check the birth date." })
      .nullable(),
    address: z.string().min(1, "Field is required and can't be empty").max(255, "Maximum of 255 characters."),
    stateId: z.string().nullable(),
    regionAu: z.boolean(),
    abn: z.string().optional(),
    isGstRegistered: z.boolean(),
    hasMedicalCondition: z.boolean(),
    medicalConditionNotes: z.string().max(1024, "Maximum of 1024 characters.").optional(),
  })
  .refine((data) => !(data.regionAu && data.isGstRegistered && (!data.abn || !isValidABN(data.abn))), {
    message: "Field must be a valid ABN.",
    path: ["abn"],
  });

export const removeDriverFormSchema = z.object({
  reason: z.string().max(2048, "Maximum of 2048 characters.").optional(),
});

export const changePasswordFormSchema = z
  .object({
    newPassword: z
      .string()
      .min(1, "Field is required and can't be empty.")
      .min(6, "Minimum of 6 characters.")
      .max(65, "Maximum of 65 characters."),
    confirmPassword: z
      .string()
      .min(1, "Field is required and can't be empty.")
      .min(6, "Minimum of 6 characters.")
      .max(65, "Maximum of 65 characters."),
  })
  .refine((data) => data.newPassword === data.confirmPassword, {
    message: "Passwords don't match. Please try again.",
    path: ["confirmPassword"],
  });

export const editAccountDetailsFormSchema = z.object({
  email: z.string().email("Please enter a valid email address").min(8),
});

export const editDriverStatusFormSchema = z.object({
  tier: z.number(),
});

export type EditEmergencyContactFormData = z.infer<typeof editEmergencyContactFormSchema>;

export type EditDriverDetailsFormData = z.infer<typeof editDriverDetailsFormSchema>;

export type RemoveDriverFormData = z.infer<typeof removeDriverFormSchema>;

export type ChangePasswordFormData = z.infer<typeof changePasswordFormSchema>;

export type EditAccountDetailsFormData = z.infer<typeof editAccountDetailsFormSchema>;

export type EditDriverStatusFormData = z.infer<typeof editDriverStatusFormSchema>;
