import { Icon } from "@/components/atoms";

export const DriverStatusBadge = ({ status }: { status: string }) => {
  switch (status.toLowerCase()) {
    case "active":
      return <Icon name="Verify" variant="Bold" size="md" className="text-info" />;

    default:
      return null;
  }
};
