import { useCallback, useMemo } from "react";
import { Controller, useFormContext } from "react-hook-form";
import { DropdownSelect, ErrorMessage, Icon, Tooltip, Typography } from "@/components/atoms";
import { useGetVehicleCategoriesFormOptionsQuery } from "@/redux/apis/config/pricing/vehicleCategory/vehicleCategoryApi";
import { useGetVehiclesQuery } from "@/redux/apis/vehicle/vehicleApi";
import { capitalize, clsx } from "@/utils";
import { getMatchedVehicles } from "../AssociatedVehicles/helpers";
import { AddVehicleCategoryFormData } from "../form";

export const VehicleCategoryClassField = ({ className, ...props }: React.HTMLAttributes<HTMLDivElement>) => {
  const { isFetching: isFetchingOptions, data: formOptions } = useGetVehicleCategoriesFormOptionsQuery();
  const { isFetching: isFetchingVehicles, data: vehicleOptions } = useGetVehiclesQuery({ page_size: "999" });

  const {
    control,
    formState: { errors },
    watch,
    setValue,
  } = useFormContext<AddVehicleCategoryFormData>();

  const options = useMemo(() => {
    if (!formOptions) return [];
    return formOptions.vehicleClass.map((vehicleClass) => ({
      value: vehicleClass,
      name: capitalize(vehicleClass.toLowerCase()),
    }));
  }, [formOptions]);

  const vehicles = useMemo(() => {
    if (!vehicleOptions) return [];
    return vehicleOptions.vehicles;
  }, [vehicleOptions]);

  const vehicleType = watch("type");
  const updateMatchedVehicles = useCallback(
    (vehicleClass: string) => {
      if (vehicles.length === 0) return;

      const matchedVehicles = getMatchedVehicles(vehicles, vehicleType, vehicleClass);
      setValue(
        "associatedVehicles",
        matchedVehicles.map((vehicle) => vehicle.uuid),
        {
          shouldDirty: true,
        }
      );
    },
    [vehicles, vehicleType, setValue]
  );

  const isLoading = isFetchingOptions || isFetchingVehicles;
  if (isLoading || !formOptions) return null;

  return (
    <div className={clsx("col-span-2 flex flex-col gap-y-1 md:col-span-1", className)} {...props}>
      <div className="flex flex-row items-center gap-x-1.5">
        <Typography variant="paragraph">Class of Vehicle</Typography>
        <Tooltip
          maxWidth={370}
          content="Clients selecting this vehicle category will receive the class of cars designated here"
          placement="right"
        >
          <span>
            <Icon name="InfoCircle" variant="Bold" className="rotate-180 text-neutral-dark-gray" size="md" />
          </span>
        </Tooltip>
      </div>
      <Controller
        control={control}
        name="class"
        render={({ field }) => (
          <DropdownSelect
            ref={field.ref}
            className="w-full"
            placeholder="Select Vehicle Class"
            options={options}
            value={field.value}
            onChange={(value: string | null) => {
              field.onChange(value);
              if (value) updateMatchedVehicles(value);
            }}
            hasError={!!errors.class}
            isNullable
          />
        )}
      />
      <ErrorMessage errors={errors} name="class" />
    </div>
  );
};
