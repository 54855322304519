import { uniq } from "ramda";
import { useCallback, useMemo } from "react";
import { useFormContext } from "react-hook-form";
import { CheckboxInput, Typography } from "@/components/atoms";
import { AddVehicleCategoryFormData } from "../../form";
import { VehicleMakesCheckboxInput } from "./VehicleMakesCheckboxInput";
import { useVehicleCategoryOffloadModelsContext } from "./context";

export const VehicleMakesTable = () => {
  const { watch, setValue } = useFormContext<AddVehicleCategoryFormData>();
  const { isLoading, activeMake, setActiveMake, options } = useVehicleCategoryOffloadModelsContext();
  const selectedModelIds = watch("offloadVehicleModels");

  const selectedModelCount = useMemo(() => {
    return Object.entries(options.allMakes).reduce((acc, [make, makeObj]) => {
      acc[make] = makeObj.models.filter((model) => selectedModelIds && selectedModelIds.includes(model.uuid)).length;
      return acc;
    }, {} as Record<string, number>);
  }, [selectedModelIds, options.allMakes]);

  const handleCheckChange = useCallback(
    ({ target }: React.ChangeEvent<HTMLInputElement>) => {
      const targetMakeModelIds = options.allMakes[target.value].models.map((model) => model.uuid);

      if (target.checked) {
        setValue("offloadVehicleModels", uniq([...(selectedModelIds || []), ...targetMakeModelIds]), { shouldDirty: true });
      } else {
        setValue("offloadVehicleModels", selectedModelIds ? selectedModelIds.filter((id) => !targetMakeModelIds.includes(id)) : [], {
          shouldDirty: true,
        });
      }
    },
    [options.allMakes, selectedModelIds, setValue]
  );

  return (
    <div className="flex w-full flex-col md:w-[240px] md:max-w-[50%]">
      <header className="flex h-[45px] items-center gap-3 rounded-tl-lg bg-neutral-gray p-5 pb-2">
        <Typography variant="action">Make</Typography>
      </header>
      <div className={`grid max-h-[250px] gap-2 overflow-auto p-5 ${isLoading ? "pointer-events-none opacity-50" : ""}`}>
        <CheckboxInput
          id="vehicleMakeSelectAll"
          className="px-1"
          label="Select all"
          variant="check"
          onChange={({ target }) => {
            if (target.checked) {
              setValue(
                "offloadVehicleModels",
                options.allModels.map((i) => i.uuid),
                { shouldDirty: true }
              );
            } else {
              setValue("offloadVehicleModels", [], { shouldDirty: true });
            }
          }}
          checked={selectedModelIds ? selectedModelIds.length > 0 && selectedModelIds.length === options.allModels.length : false}
        />

        {Object.keys(options.allMakes)
          .sort()
          .map((make, i) => (
            <VehicleMakesCheckboxInput
              key={i}
              make={make}
              onCheckChange={handleCheckChange}
              onClick={() => setActiveMake(make)}
              isActive={activeMake === make}
              isChecked={selectedModelCount[make] > 0}
            />
          ))}
      </div>
    </div>
  );
};
