import { getPhpHostUrl } from "./apiHelpers";

export const getOldBookingUrl = (accountId: string, bookingId: string, page: string, journey = false) => {
  if (journey) return `${getPhpHostUrl()}/passenger/${accountId}/journey-admin/${page}/${bookingId}`;
  return `${getPhpHostUrl()}/passenger/${accountId}/booking/view/${bookingId}/admin/${page}`;
};

export const getTripLogGuestViewUrl = (bookingId: string, hash: string) => {
  return `${window.location.origin}/dashboard/trip-log/${bookingId}/${hash}`;
};

export const getBookingDetailsUrl = (operatorId: string, bookingId: string, jobDate: string) => {
  return `${window.location.origin}/dashboard/operator/${operatorId}/bookings?search=${bookingId}&dateFrom=${jobDate}&dateTo=${jobDate}`;
};
