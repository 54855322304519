import { format, parseISO } from "date-fns";
import { isEmpty } from "ramda";
import { useEffect, useState } from "react";
import { Controller, useFormContext } from "react-hook-form";
import { Divider, DropdownSelect, DropdownSelectOption, ErrorMessage, Loading, Typography } from "@/components/atoms";
import { getErrorMessages } from "@/helpers/reduxHelpers";
import { useOperator, useProfile } from "@/hooks";
import { useLazyGetCountryStatesQuery } from "@/redux/apis/resource/resourceApi";
import { addToast, clsx } from "@/utils";
import { DriverDetail } from "../../../common/DriverDetail";
import { useManageDriverContext } from "../../context";
import { EditDriverDetailsFormData } from "../../form";
import { DriverDocumentUploadField } from "./DriverDocumentUploadField";

interface LicenseDriverDetailsProps {
  editMode: boolean;
}

export const LicenseDriverDetails = ({ editMode }: LicenseDriverDetailsProps) => {
  const { dateFormat } = useProfile();
  const { country } = useOperator();
  const { driver } = useManageDriverContext();
  const { personal, licence, documents } = driver;
  const driverLicenseFront = documents.find((document) => document.type === "driver-licence");
  const driverLicenseBack = documents.find((document) => document.type === "driver-licence-back");
  const [getCountryStates, { isFetching }] = useLazyGetCountryStatesQuery();
  const [options, setOptions] = useState<Array<DropdownSelectOption>>([]);

  const {
    control,
    setValue,
    formState: { errors: formErrors },
  } = useFormContext<EditDriverDetailsFormData>();

  useEffect(() => {
    getCountryStates(country)
      .unwrap()
      .then((data) => setOptions(data.data.states.map((state) => ({ name: state.name, value: state.id }))))
      .catch((e) => {
        getErrorMessages(e).forEach((m) => {
          if (m === "Not found") addToast("danger", "No states found.");
          else addToast("danger", m);
        });
        setOptions([]);
        setValue("stateId", null);
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [country]);

  return (
    <>
      <Divider className="mt-6" />
      <div
        className={clsx(
          "mt-6 grid gap-6",
          editMode ? "grid-cols-1 md:grid-cols-2 2xl:grid-cols-3" : "grid-cols-1 md:grid-cols-3 2xl:grid-cols-5"
        )}
      >
        <DriverDetail
          className={clsx({ hidden: editMode })}
          label="Driver License No."
          detail={
            <div className="flex flex-col gap-2">
              <Typography variant="action">{licence.number}</Typography>
            </div>
          }
        />
        <DriverDetail
          className={clsx({ hidden: editMode })}
          label="Driver License Expiry Date"
          detail={licence.expiry ? format(parseISO(licence.expiry), dateFormat) : "-"}
        />
        <DriverDetail
          editMode={editMode}
          label="Driver Registered In"
          detail={
            editMode ? (
              <div className="relative flex flex-col gap-1">
                {isFetching && <Loading className="rounded-md" />}
                <Controller
                  name="stateId"
                  control={control}
                  render={({ field }) => (
                    <DropdownSelect
                      options={options}
                      placeholder="Select State"
                      value={field.value?.toString()}
                      onChange={(value) => field.onChange(value)}
                      hasError={!!formErrors.stateId}
                      disabled={isEmpty(options)}
                    />
                  )}
                />
                <ErrorMessage errors={formErrors} name="stateId" />
              </div>
            ) : (
              licence.state.name
            )
          }
        />
        <DriverDetail className={clsx({ hidden: editMode })} label="Years of Driving" detail={null} />
        <DriverDetail
          className={clsx({ hidden: editMode })}
          label="Year's of Driving as a Professional"
          detail={`${personal.yearsProfessionalDriving} ${personal.yearsProfessionalDriving === 1 ? "year" : "years"}`}
        />
        <DriverDetail
          editMode={editMode}
          label="Driver License Photo (Front)"
          detail={<DriverDocumentUploadField editMode={editMode} document={driverLicenseFront} documentType="driver-licence" />}
        />
        <DriverDetail
          editMode={editMode}
          label="Driver License Photo (Back)"
          detail={<DriverDocumentUploadField editMode={editMode} document={driverLicenseBack} documentType="driver-licence-back" />}
        />
      </div>
    </>
  );
};
