import { FleetLeadersGrid, EmptyState, Pagination, NoSearchResults } from "@/components/molecules";
import { usePagination } from "@/hooks";
import { useAppSelector } from "@/redux/hooks";
import { FleetDriver } from "@/redux/slices/driver/types";
import { networkFleetLeaderPageSelector } from "@/redux/slices/network/selectors";

interface PrivateNetworkFleetLeadersGridProps {
  operatorName: string;
  items: FleetDriver[];
  isEmpty: boolean;
}

export const PrivateNetworkFleetLeadersGrid = ({ operatorName, items, isEmpty }: PrivateNetworkFleetLeadersGridProps) => {
  return (
    <>
      {items.length === 0 ? (
        isEmpty ? (
          <EmptyState title="No Fleet Leaders" description={`Page where all list of ${operatorName}'s Fleet leaders.`} />
        ) : (
          <NoSearchResults />
        )
      ) : (
        <FleetLeadersGrid
          items={items.map((driver) => ({
            id: driver.uuid,
            email: driver.email,
            name: driver.name,
            phone: driver.phone,
            location: driver.state.join(", "),
            avatar: driver.avatar,
          }))}
        />
      )}
    </>
  );
};

const PrivateNetworkFleetLeadersGridPagination = () => {
  const { current, count, total, size } = useAppSelector(networkFleetLeaderPageSelector);
  const { handlePageClick, handleSizeChange } = usePagination();

  return (
    <Pagination
      onPageClick={handlePageClick}
      onSizeChange={handleSizeChange}
      currentPage={current}
      pageSize={size}
      totalItems={total}
      pageCount={count}
    />
  );
};

PrivateNetworkFleetLeadersGrid.Pagination = PrivateNetworkFleetLeadersGridPagination;
