import { useNavigate, useParams } from "react-router-dom";
import { Modal } from "@/components/atoms";
import { EditFixedCostExtraForm, useGetFixedCostExtrasParams } from "@/components/organisms";
import { useGetFixedCostExtrasQuery } from "@/redux/apis/config/pricing/fixedCostExtras";

export const FixedCostExtrasEdit = () => {
  const navigate = useNavigate();
  const { params } = useGetFixedCostExtrasParams();
  const { fixedCostExtraId } = useParams<{ fixedCostExtraId: string }>();
  const { data: response } = useGetFixedCostExtrasQuery(params);
  const fixedCostExtra = response?.data.items.find((item) => item.id === fixedCostExtraId);

  if (!fixedCostExtra) return null;

  return (
    <Modal open title="Edit Fixed Cost Extra" onClose={() => navigate("../")}>
      <EditFixedCostExtraForm item={fixedCostExtra} />
    </Modal>
  );
};
