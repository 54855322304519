import { isEmpty } from "ramda";
import { Icon, Loading, Typography } from "@/components/atoms";
import { useLazyRequestDriverDocumentQuery } from "@/redux/apis/document/documentApi";
import { DriverDocument } from "@/redux/slices/driver/types";
import { downloadFile } from "@/utils";
import { DriverDetail } from "../../../common/DriverDetail";
import { useManageDriverContext } from "../../context";

export const Documents = () => {
  const { driver } = useManageDriverContext();
  const documentList = driver.documents.filter((document) => document.type !== "driver-licence" && document.type !== "driver-licence-back");
  const [requestDocument, { isLoading }] = useLazyRequestDriverDocumentQuery();

  const downloadDocument = (document: DriverDocument) => {
    requestDocument({
      id: document.uuid,
      driverId: driver.uuid,
    })
      .unwrap()
      .then((res) => downloadFile(res.filename, document.filename));
  };

  if (isEmpty(documentList)) return null;

  return (
    <div className="relative mt-4 flex flex-col rounded-lg bg-neutral-surface-gray p-5">
      {isLoading && <Loading />}
      <Typography variant="title">Documents</Typography>
      <div className="mt-6 grid grid-cols-1  gap-6 md:grid-cols-3 2xl:grid-cols-5">
        {documentList.map((document) => (
          <DriverDetail
            key={document.uuid}
            label={document.name}
            detail={
              <div className="flex">
                <div className="flex cursor-pointer flex-row items-center gap-2 hover:text-info" onClick={() => downloadDocument(document)}>
                  <Typography variant="action">{document.filename}</Typography>
                  <Icon name="ImportCurve" size="sm" className="text-info" />
                </div>
              </div>
            }
          />
        ))}
      </div>
    </div>
  );
};
