import { MyDriversPanel } from "@/components/organisms";
import { AddDriver } from "./subpages/AddDriver";
import { ManageDriver } from "./subpages/ManageDriver";

export const MyDrivers = () => {
  return (
    <div className="mb-12 flex flex-1 flex-col py-2">
      <MyDriversPanel />
    </div>
  );
};

MyDrivers.AddDriver = AddDriver;
MyDrivers.ManageDriver = ManageDriver;
