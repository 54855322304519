import { useClose } from "@headlessui/react";
import { zodResolver } from "@hookform/resolvers/zod";
import { useDebouncedEffect } from "@react-hookz/web";
import { useMemo } from "react";
import { Controller, useForm } from "react-hook-form";
import { z } from "zod";
import { DropdownSelect, Typography } from "@/components/atoms";
import { FilterDrawer } from "../common/FilterDrawer";
import { FilterPopover } from "../common/FilterPopover";
import { useVehicleFilters } from "../useVehicleFilters";

const schema = z.object({
  bag: z.string(),
});

type FormData = z.infer<typeof schema>;

export const BagFilter = () => {
  const {
    filters: { minCapacityBags },
    setFilter,
  } = useVehicleFilters();

  const value = useMemo(() => minCapacityBags, [minCapacityBags]);

  const onClose = () => setFilter("minCapacityBags", undefined);

  return (
    <FilterPopover
      className="overflow-visible"
      name="minCapacityBags"
      value={value?.toString()}
      label="Minimum Bag Capacity"
      onClose={onClose}
    >
      <BagFilterForm />
    </FilterPopover>
  );
};

interface BagFilterDrawerProps {
  open?: boolean;
  onOpenChange?: (open: boolean) => void;
}

export const BagFilterDrawer = ({ open, onOpenChange }: BagFilterDrawerProps) => {
  const {
    filters: { minCapacityBags },
    setFilter,
  } = useVehicleFilters();

  const value = useMemo(() => minCapacityBags, [minCapacityBags]);

  const onClose = () => setFilter("minCapacityBags", undefined);

  return (
    <FilterDrawer open={open} onOpenChange={onOpenChange} value={value?.toString()} label="Bag Capacity" onClose={onClose}>
      <BagFilterForm />
    </FilterDrawer>
  );
};

const BagFilterForm = () => {
  const close = useClose();
  const { setFilter, filters } = useVehicleFilters();

  const { control, watch } = useForm<FormData>({
    resolver: zodResolver(schema),
    defaultValues: { bag: filters.minCapacityBags ? filters.minCapacityBags.toString() : "" },
  });

  const value = watch("bag");

  useDebouncedEffect(
    () => {
      setFilter("minCapacityBags", value);
    },
    [value],
    300
  );

  return (
    <form
      className="contents"
      onSubmit={(event) => {
        event.preventDefault();
        close();
      }}
    >
      <Typography className="mb-4">Set Minimum Bag Capacity</Typography>
      <Controller
        control={control}
        name="bag"
        render={({ field }) => (
          <DropdownSelect
            isNullable
            defaultValue="0"
            placeholder="Select Minimum Bag Capacity"
            options={Array.from({ length: 99 }, (_, i) => (i + 1).toString())}
            value={field.value?.toString()}
            onChange={(value: string) => {
              field.onChange(+value);
            }}
          />
        )}
      />
    </form>
  );
};
