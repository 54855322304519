import { Outlet } from "react-router-dom";
import { PageHeader, FixedCostExtrasPanel } from "@/components/organisms";
import { Nav } from "../VehicleCategories/VehicleCategories";
import { FixedCostExtrasAdd } from "./FixedCostExtrasAdd";
import { FixedCostExtrasEdit } from "./FixedCostExtrasEdit";

export const FixedCostExtras = () => {
  return (
    <>
      <PageHeader.Actions />
      <Nav />
      <FixedCostExtrasPanel />
      <Outlet />
    </>
  );
};

FixedCostExtras.Edit = FixedCostExtrasEdit;
FixedCostExtras.Add = FixedCostExtrasAdd;
