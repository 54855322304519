import { useCallback } from "react";
import { useFormContext } from "react-hook-form";
import { CheckboxInput, IconButton, Popover, Typography } from "@/components/atoms";
import { AddVehicleCategoryFormData } from "../../form";
import { useVehicleCategoryOffloadModelsContext } from "./context";

export const VehicleMakesModelsFilter = () => {
  const {
    isLoading,
    filters,
    setFilters,
    options: { allModels, classes, types },
  } = useVehicleCategoryOffloadModelsContext();
  const { watch, setValue } = useFormContext<AddVehicleCategoryFormData>();
  const selectedModelIds = watch("offloadVehicleModels");

  const handleTypeChange = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      if (e.target.checked) {
        const newTypes = [...filters.types, e.target.value];
        setFilters((prev) => ({
          ...prev,
          types: newTypes,
        }));

        const newModelIds = allModels.filter((model) => newTypes.includes(model.type)).map((model) => model.uuid);
        setValue("offloadVehicleModels", selectedModelIds ? selectedModelIds.filter((id) => newModelIds.includes(id)) : [], {
          shouldDirty: true,
        });
      } else {
        const newTypes = filters.types.filter((i) => i !== e.target.value);
        setFilters((prev) => ({
          ...prev,
          types: newTypes,
        }));

        const newModelIds = allModels.filter((model) => newTypes.includes(model.type)).map((model) => model.uuid);
        setValue("offloadVehicleModels", selectedModelIds ? selectedModelIds.filter((id) => newModelIds.includes(id)) : [], {
          shouldDirty: true,
        });
      }
    },
    [allModels, filters.types, selectedModelIds, setFilters, setValue]
  );

  const handleCheckAllTypes = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      const { checked } = e.target;

      if (checked) {
        const newTypes = types.map((type) => type.value);
        setFilters((prev) => ({
          ...prev,
          types: newTypes,
        }));

        const newModelIds = allModels.filter((model) => newTypes.includes(model.type)).map((model) => model.uuid);
        setValue("offloadVehicleModels", selectedModelIds ? selectedModelIds.filter((id) => newModelIds.includes(id)) : [], {
          shouldDirty: true,
        });
      } else {
        setFilters((prev) => ({
          ...prev,
          types: [],
        }));

        setValue("offloadVehicleModels", [], { shouldDirty: true });
      }
    },
    [allModels, selectedModelIds, setFilters, setValue, types]
  );

  const handleClassChange = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      if (e.target.checked) {
        const newClasses = [...filters.class, e.target.value];
        setFilters((prev) => ({
          ...prev,
          class: newClasses,
        }));

        const newModelIds = allModels.filter((model) => newClasses.includes(model.class)).map((model) => model.uuid);
        setValue("offloadVehicleModels", selectedModelIds ? selectedModelIds.filter((id) => newModelIds.includes(id)) : [], {
          shouldDirty: true,
        });
      } else {
        const newClasses = filters.class.filter((i) => i !== e.target.value);
        setFilters((prev) => ({
          ...prev,
          class: newClasses,
        }));

        const newModelIds = allModels.filter((model) => newClasses.includes(model.class)).map((model) => model.uuid);
        setValue("offloadVehicleModels", selectedModelIds ? selectedModelIds.filter((id) => newModelIds.includes(id)) : [], {
          shouldDirty: true,
        });
      }
    },
    [allModels, filters.class, selectedModelIds, setFilters, setValue]
  );

  const handleCheckAllClasses = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      const { checked } = e.target;

      if (checked) {
        const newClasses = classes.map((cls) => cls.value);
        setFilters((prev) => ({
          ...prev,
          class: newClasses,
        }));

        const newModelIds = allModels.filter((model) => newClasses.includes(model.class)).map((model) => model.uuid);
        setValue("offloadVehicleModels", selectedModelIds ? selectedModelIds.filter((id) => newModelIds.includes(id)) : [], {
          shouldDirty: true,
        });
      } else {
        setFilters((prev) => ({
          ...prev,
          class: [],
        }));

        setValue("offloadVehicleModels", [], { shouldDirty: true });
      }
    },
    [allModels, classes, selectedModelIds, setFilters, setValue]
  );

  return (
    <Popover position="bottom-end">
      <IconButton
        disabled={isLoading}
        variant="secondary"
        size="sm"
        iconName="Sort"
        className="-translate-y-1 translate-x-3 bg-transparent px-1.5 py-1"
      />
      <div className="grid w-[300px] grid-cols-2 items-start rounded-lg bg-white px-4 py-6 shadow-dropdown">
        <div className="flex flex-col gap-3">
          <Typography variant="action">Class</Typography>
          <CheckboxInput
            label="Select all"
            id="select-all-classes"
            onChange={handleCheckAllClasses}
            variant="check"
            checked={filters.class.length > 0 && filters.class.length === classes.length}
          />

          {classes.map((cls, i) => (
            <CheckboxInput
              key={i}
              label={cls.name}
              value={cls.value}
              onChange={handleClassChange}
              checked={filters.class && filters.class.length > 0 ? filters.class.includes(cls.value) : false}
              variant="check"
            />
          ))}
        </div>
        <div className="flex flex-col gap-3">
          <Typography variant="action">Type</Typography>
          <CheckboxInput
            label="Select all"
            id="select-all-types"
            onChange={handleCheckAllTypes}
            variant="check"
            checked={filters.types.length > 0 && filters.types.length === types.length}
          />

          {types.map((type, i) => (
            <CheckboxInput
              key={i}
              label={type.name}
              value={type.value}
              onChange={handleTypeChange}
              checked={filters.types && filters.types.length > 0 ? filters.types.includes(type.value) : false}
              variant="check"
            />
          ))}
        </div>
      </div>
    </Popover>
  );
};
