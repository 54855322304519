import { useMemo } from "react";
import { usePagination } from "@/hooks";
import { GetCostExtrasParams } from "@/redux/apis/config/pricing/fixedCostExtras";

export const useGetFixedCostExtrasParams = () => {
  const { page, pageSize } = usePagination("config-fixed-cost-extras", 25);

  const params: GetCostExtrasParams = useMemo(
    () => ({
      page,
      pageSize,
    }),
    [page, pageSize]
  );

  return {
    params,
  };
};
