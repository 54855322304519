import { FleetLeaderModal } from "../modals/FleetLeaderModal";
import { MyDriversStep } from "../types";

interface MyDriversModalsProps {
  step: MyDriversStep;
  setStep: (step: MyDriversStep) => void;
}

export const MyDriversModals = ({ step, setStep }: MyDriversModalsProps) => {
  const onClose = () => setStep(undefined);

  return (
    <>
      <FleetLeaderModal open={step === "fleet-leader"} onClose={onClose} />
    </>
  );
};
