import { Icon, Modal, Typography } from "@/components/atoms";

interface SaveChangesModalProps {
  open: boolean;
  onClose: () => void;
}

export const SaveChangesModal = ({ open, onClose }: SaveChangesModalProps) => {
  return (
    <Modal open={open} onClose={onClose} className="!p-5">
      <div className="sm:flex sm:items-start">
        <div className="mt-3 w-full text-center sm:mt-0 sm:text-left">
          <div className="flex flex-row items-center">
            <Typography variant="title" className="flex-1">
              Unsaved Changes
            </Typography>
            <Icon name="close" isCustom className="flex cursor-pointer justify-end" onClick={onClose} />
          </div>
        </div>
      </div>
      <Typography className="mt-5">Please save your changes before proceeding.</Typography>
    </Modal>
  );
};
