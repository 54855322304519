import { zodResolver } from "@hookform/resolvers/zod";
import { FormProvider, useForm, useFormContext } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import { Button, Panel, Spinner, Typography } from "@/components/atoms";
import { getErrorMessages } from "@/helpers/reduxHelpers";
import { usePrompt } from "@/hooks";
import { useUpdateVehicleCategoryMutation } from "@/redux/apis/config/pricing/vehicleCategory/vehicleCategoryApi";
import { addToast } from "@/utils";
import { PageHeader } from "../../PageHeader/PageHeader";
import { VehicleCategoryAdvancedSettings } from "../AddVehicleCategory/AdvancedSettings/VehicleCategoryAdvancedSettings";
import { VehicleCategoryAssociatedVehicles } from "../AddVehicleCategory/AssociatedVehicles/VehicleCategoryAssociatedVehicles";
import { AddVehicleCategoryDispatchSettings } from "../AddVehicleCategory/DispatchSettings/AddVehicleCategoryDispatchSettings";
import { ExampleVehicles } from "../AddVehicleCategory/ExampleVehicles/ExampleVehicles";
import { VehicleCategoryCapacityFields } from "../AddVehicleCategory/fields/VehicleCategoryCapacityFields";
import { VehicleCategoryClassField } from "../AddVehicleCategory/fields/VehicleCategoryClassField";
import { VehicleCategoryCostFields } from "../AddVehicleCategory/fields/VehicleCategoryCostFields";
import { VehicleCategoryNameField } from "../AddVehicleCategory/fields/VehicleCategoryNameField";
import { VehicleCategoryTypeField } from "../AddVehicleCategory/fields/VehicleCategoryTypeField";
import { AddVehicleCategoryFormData, addVehicleCategoryFormSchema } from "../AddVehicleCategory/form";
import { EditVehicleCategoryProvider, useEditVehicleCategoryContext } from "./context";

export const EditVehicleCategoryPanel = () => {
  return (
    <EditVehicleCategoryProvider>
      <PageHeader.Actions />
      <div className="flex flex-col items-start gap-3 md:flex-row-reverse">
        <Content />
      </div>
    </EditVehicleCategoryProvider>
  );
};

const Content = () => {
  const { vehicleCategory } = useEditVehicleCategoryContext();
  const form = useForm<AddVehicleCategoryFormData>({
    defaultValues: {
      name: vehicleCategory.name,
      type: vehicleCategory.dispatchRestrictions.vehicleType,
      class: vehicleCategory.dispatchRestrictions.vehicleClass,
      capacity: vehicleCategory.vehicleCapacity.pax,
      bags: vehicleCategory.vehicleCapacity.bags,
      costAdjustment: vehicleCategory.perDistanceUnitMultiplier,
      waitTimeCost: vehicleCategory.waitTimeCost / 100,
      enableCustomCommission: vehicleCategory.customCommission.enabled,
      commissionRate: vehicleCategory.customCommission.rate,
      enableCustomOffload: vehicleCategory.offloadRestrictions.enabled,
      offloadMaxVehicleAge: vehicleCategory.offloadRestrictions.maxAge,
      offloadMinimumRating: vehicleCategory.offloadRestrictions.minRating,
      offloadVehicleColors: vehicleCategory.offloadRestrictions.colors,
      offloadEngineTypes: vehicleCategory.offloadRestrictions.engineType,
      associatedVehicles: vehicleCategory.vehicles,
      offloadVehicleModels: vehicleCategory.offloadRestrictions.models,
      offloadEnableSpecificModels: vehicleCategory.offloadRestrictions.models.length > 0,
      offloadDispatchTolerance: vehicleCategory.dispatchTolerance,
    },
    resolver: zodResolver(addVehicleCategoryFormSchema),
    shouldFocusError: true,
  });

  usePrompt({ when: form.formState.isDirty, message: "Changes will not be saved. Do you want to proceed?" });

  return (
    <FormProvider {...form}>
      <ExampleVehicles />
      <Panel className="flex w-full flex-col gap-5">
        <div className="flex flex-col">
          <Typography variant="h3">Edit Vehicle Category</Typography>
          <Typography className="text-neutral-dark-gray">Edit vehicle category for your transfer options</Typography>
        </div>
        <Form />
      </Panel>
    </FormProvider>
  );
};

const Form = () => {
  const navigate = useNavigate();
  const { vehicleCategory } = useEditVehicleCategoryContext();
  const form = useFormContext<AddVehicleCategoryFormData>();
  const [updateVehicleCategory, { isLoading }] = useUpdateVehicleCategoryMutation();

  const handleCancel = () => {
    navigate("./../");
  };

  const handleSave = async (data: AddVehicleCategoryFormData) => {
    updateVehicleCategory({
      id: vehicleCategory.uuid,
      name: data.name,
      is_active: vehicleCategory.isActive,
      per_distance_unit_multiplier: data.costAdjustment,
      wait_time_cost: data.waitTimeCost * 100,
      vehicle_capacity: {
        bags: data.bags,
        pax: data.capacity,
      },
      custom_commission: {
        enabled: data.enableCustomCommission,
        rate: data.commissionRate ?? 0,
      },
      dispatch_restrictions: {
        vehicle_class: data.class,
        vehicle_type: data.type,
      },
      offload_restrictions: {
        enabled: data.enableCustomOffload,
        max_age: data.offloadMaxVehicleAge ?? 99,
        min_rating: data.offloadMinimumRating ?? 1,
        colors: data.offloadVehicleColors ?? [],
        engine_type: data.offloadEngineTypes ?? [],
        models: !data.offloadEnableSpecificModels ? [] : data.offloadVehicleModels ?? [],
      },
      vehicles: data.associatedVehicles,
      dispatch_tolerance: data.offloadDispatchTolerance,
    })
      .unwrap()
      .then(() => {
        addToast("success", "Vehicle Category updated successfully");
      })
      .catch((e) => getErrorMessages(e).forEach((message) => addToast("danger", message)));
  };

  return (
    <form onSubmit={form.handleSubmit(handleSave)} id="edit-vehicle-category-form" className="grid grid-cols-2 gap-5">
      <VehicleCategoryNameField />
      <VehicleCategoryClassField />
      <VehicleCategoryTypeField />
      <VehicleCategoryCapacityFields />
      <VehicleCategoryCostFields currency="AUD" />
      <ExampleVehicles.MobileDrawer />
      <VehicleCategoryAssociatedVehicles />
      <AddVehicleCategoryDispatchSettings />
      <VehicleCategoryAdvancedSettings />

      <div className="col-span-2 flex justify-end gap-4">
        <Button onClick={handleCancel} variant="secondary" size="lg" disabled={isLoading}>
          Cancel
        </Button>
        <Button variant="primary" size="lg" type="submit" form="edit-vehicle-category-form" disabled={isLoading || !form.formState.isDirty}>
          {isLoading ? <Spinner /> : "Save Changes"}
        </Button>
      </div>
    </form>
  );
};
