import { useNavigate } from "react-router-dom";
import { Button, Panel, Typography, Skeleton, ErrorPanel } from "@/components/atoms";
import { EmptyState } from "@/components/molecules";
import { getErrorMessages } from "@/helpers/reduxHelpers";
import { useGetFixedCostExtrasQuery } from "@/redux/apis/config/pricing/fixedCostExtras";
import { FixedCostExtrasTableEditor } from "./FixedCostExtrasTableEditor";
import { useGetFixedCostExtrasParams } from "./hooks/useGetFixedCostExtrasParams";

export const FixedCostExtrasPanel = () => {
  const navigate = useNavigate();
  const { params } = useGetFixedCostExtrasParams();
  const { data: response, isFetching, error } = useGetFixedCostExtrasQuery(params, { refetchOnMountOrArgChange: true });

  if (error) return <ErrorPanel messages={getErrorMessages(error)} />;
  if (isFetching) return <Skeleton />;

  return (
    <>
      <Panel className="mt-4">
        <div className="flex flex-col gap-3 sm:flex-row sm:items-center">
          <div className="flex-1">
            <Typography variant="h3" className="leading-8">
              Fixed Cost Extras
            </Typography>
            <Typography className="text-neutral-dark-gray">
              Configure the fixed cost extras your customers can select when making a booking.
            </Typography>
          </div>
          <div className="flex items-center">
            <Button
              onClick={() => navigate("add")}
              variant="tertiary"
              startIcon="Add"
              className="border border-neutral-dark-gray text-primary-dark md:border-none"
            >
              Add Extra
            </Button>
          </div>
        </div>
        {response && response.data.items.length > 0 ? (
          <>
            <FixedCostExtrasTableEditor className="mt-4" items={response.data.items} />
            <FixedCostExtrasTableEditor.Pagination page={response.data.page} />
          </>
        ) : (
          <EmptyState
            title="Fixed Cost Extras"
            description="It looks like you haven't added any fixed cost extras yet. Begin by adding your first fixed cost extra to get started."
          >
            <Button onClick={() => navigate("add")} startIcon="Add" variant="tertiary">
              Add First Fixed Cost Extra
            </Button>
          </EmptyState>
        )}
      </Panel>
    </>
  );
};
