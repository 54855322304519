import { zodResolver } from "@hookform/resolvers/zod";
import { FormProvider, useForm, useFormContext } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import { Button, Panel, Spinner, Typography } from "@/components/atoms";
import { getErrorMessages } from "@/helpers/reduxHelpers";
import { useAddVehicleCategoryMutation } from "@/redux/apis/config/pricing/vehicleCategory/vehicleCategoryApi";
import { addToast } from "@/utils";
import { PageHeader } from "../../PageHeader/PageHeader";
import { VehicleCategoryAdvancedSettings } from "./AdvancedSettings/VehicleCategoryAdvancedSettings";
import { VehicleCategoryAssociatedVehicles } from "./AssociatedVehicles/VehicleCategoryAssociatedVehicles";
import { AddVehicleCategoryDispatchSettings } from "./DispatchSettings/AddVehicleCategoryDispatchSettings";
import { ExampleVehicles } from "./ExampleVehicles/ExampleVehicles";
import { VehicleCategoryCapacityFields } from "./fields/VehicleCategoryCapacityFields";
import { VehicleCategoryClassField } from "./fields/VehicleCategoryClassField";
import { VehicleCategoryCostFields } from "./fields/VehicleCategoryCostFields";
import { VehicleCategoryNameField } from "./fields/VehicleCategoryNameField";
import { VehicleCategoryTypeField } from "./fields/VehicleCategoryTypeField";
import { AddVehicleCategoryFormData, addVehicleCategoryFormSchema } from "./form";

export const AddVehicleCategoryPanel = () => {
  return (
    <>
      <PageHeader.Actions />
      <div className="flex flex-col items-start gap-3 lg:flex-row-reverse">
        <Content />
      </div>
    </>
  );
};

const Content = () => {
  const form = useForm<AddVehicleCategoryFormData>({
    defaultValues: {
      enableCustomCommission: false,
      offloadEnableSpecificModels: false,
      commissionRate: 0,
      associatedVehicles: [],
      enableCustomOffload: false,
      offloadDispatchTolerance: "STRICT",
      offloadEngineTypes: [],
      offloadMaxVehicleAge: 0,
      offloadMinimumRating: undefined,
      offloadVehicleColors: undefined,
      offloadVehicleModels: undefined,
    },
    resolver: zodResolver(addVehicleCategoryFormSchema),
    shouldFocusError: true,
  });

  return (
    <FormProvider {...form}>
      <ExampleVehicles />
      <Panel className="flex w-full flex-col gap-5">
        <div className="flex flex-col">
          <Typography variant="h3">Add Vehicle Category</Typography>
          <Typography className="text-neutral-dark-gray">Add a new vehicle category for your transfer options</Typography>
        </div>
        <Form />
      </Panel>
    </FormProvider>
  );
};

const Form = () => {
  const navigate = useNavigate();
  const form = useFormContext<AddVehicleCategoryFormData>();
  const [addVehicleCategory, { isLoading }] = useAddVehicleCategoryMutation();

  const handleCancel = () => {
    navigate("./../");
  };

  const handleSave = async (data: AddVehicleCategoryFormData) => {
    addVehicleCategory({
      name: data.name,
      is_active: true,
      per_distance_unit_multiplier: data.costAdjustment,
      wait_time_cost: data.waitTimeCost * 100,
      vehicle_capacity: {
        bags: data.bags,
        pax: data.capacity,
      },
      custom_commission: {
        enabled: data.enableCustomCommission,
        rate: data.commissionRate ?? 0,
      },
      dispatch_restrictions: {
        vehicle_class: data.class,
        vehicle_type: data.type,
      },
      offload_restrictions: {
        enabled: data.enableCustomOffload,
        max_age: data.offloadMaxVehicleAge ?? 99,
        min_rating: data.offloadMinimumRating ?? 1,
        colors: data.offloadVehicleColors ?? [],
        engine_type: data.offloadEngineTypes ?? [],
        models: !data.offloadEnableSpecificModels ? [] : data.offloadVehicleModels ?? [],
      },
      vehicles: data.associatedVehicles,
      dispatch_tolerance: data.offloadDispatchTolerance,
    })
      .unwrap()
      .then(() => {
        addToast("success", "Vehicle Category added successfully");
        navigate("./../");
      })
      .catch((e) => getErrorMessages(e).forEach((message) => addToast("danger", message)));
  };

  return (
    <form onSubmit={form.handleSubmit(handleSave)} id="add-vehicle-category-form" className="grid grid-cols-2 gap-5">
      <VehicleCategoryNameField />
      <VehicleCategoryClassField />
      <VehicleCategoryTypeField />
      <VehicleCategoryCapacityFields />
      <VehicleCategoryCostFields currency="AUD" />
      <ExampleVehicles.MobileDrawer />
      <VehicleCategoryAssociatedVehicles />
      <AddVehicleCategoryDispatchSettings />
      <VehicleCategoryAdvancedSettings />
      <div className="col-span-2 flex justify-end gap-4">
        <Button onClick={handleCancel} variant="secondary" size="lg" disabled={isLoading}>
          Cancel
        </Button>
        <Button variant="primary" size="lg" type="submit" form="add-vehicle-category-form" disabled={isLoading || !form.formState.isDirty}>
          {isLoading ? <Spinner /> : "Add Vehicle Category"}
        </Button>
      </div>
    </form>
  );
};
