import { useMemo } from "react";
import { Loading } from "@/components/atoms";
import { DataTable, Pagination } from "@/components/molecules";
import { getErrorMessages } from "@/helpers/reduxHelpers";
import { usePagination, useProfile, useSorting } from "@/hooks";
import {
  useApproveInvitationMutation,
  useRejectInvitationMutation,
  useToggleOffloadFeeExemptionMutation,
} from "@/redux/apis/network/networkApi";
import { useAppDispatch, useAppSelector } from "@/redux/hooks";
import { togglePaysCommission, updateNetworkStatus } from "@/redux/slices/network/networkSlice";
import { NetworkItem } from "@/redux/slices/network/types";
import { addToast, clsx } from "@/utils";
import { generateNetworkColumns, generateNetworkData } from "../helpers";
import { PrivateNetworkStep } from "../types";
import { networkPageSelector } from "@/redux/slices/network/selectors";
import { PrivateNetworkEmptyState } from "../PrivateNetworkEmptyState";

interface PrivateNetworkTableProps extends React.HTMLAttributes<HTMLDivElement> {
  items: NetworkItem[];
  setPendingDisconnectRelationship: (item: NetworkItem) => void;
  setStep: (step: PrivateNetworkStep) => void;
}

export const PrivateNetworkTable = ({ items, setPendingDisconnectRelationship, setStep, className, ...props }: PrivateNetworkTableProps) => {
  const { sorting, setSorting } = useSorting();
  const { dateFormat } = useProfile();
  const dispatch = useAppDispatch();

  const [approve, { isLoading: isLoadingApprove }] = useApproveInvitationMutation();
  const [reject, { isLoading: isLoadingReject }] = useRejectInvitationMutation();
  const [toggle, { isLoading: isLoadingToggle }] = useToggleOffloadFeeExemptionMutation();
  const isLoading = isLoadingApprove || isLoadingReject || isLoadingToggle;

  const acceptInvitation = (id: string) => {
  approve(id)
    .unwrap()
    .then(() => {
      dispatch(updateNetworkStatus({ id, status: "active" }));
      addToast("success", "Successfully accepted invitation");
    })
    .catch((e) => getErrorMessages(e).forEach((m) => addToast("danger", m)));
  };

  const declineInvitation = (id: string) => {
    reject(id)
      .unwrap()
      .then(() => {
        dispatch(updateNetworkStatus({ id, status: "rejected" }));
        addToast("success", "Successfully declined invitation");
      })
      .catch((e) => getErrorMessages(e).forEach((m) => addToast("danger", m)));
  };

  const toggleOffloadFeeExemption = (id: string, active: boolean) => {
    toggle({ id, paysCommission: active })
      .unwrap()
      .then(() => {
        dispatch(togglePaysCommission({ id, active }));
      })
      .catch((e) => getErrorMessages(e).forEach((m) => addToast("danger", m)));
  };

  const data = useMemo(
    () => generateNetworkData(items, dateFormat, acceptInvitation, declineInvitation, toggleOffloadFeeExemption, setPendingDisconnectRelationship),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [items]
  );
  const columns = useMemo(() => generateNetworkColumns(), []);

  return (
    <div className={clsx("relative flex bg-white", className)}>
      {isLoading && <Loading className="rounded-lg" />}
      <DataTable 
        className="[&_table]:table-auto overflow-x-auto w-full"
        columns={columns} 
        data={data} 
        sorting={sorting} 
        setSorting={setSorting} 
        emptyState={<PrivateNetworkEmptyState setStep={setStep} />} 
        {...props} 
      />
    </div>
  );
};

const PrivateNetworkTablePagination = () => {
  const { current, count, total, size } = useAppSelector(networkPageSelector);
  const { handlePageClick, handleSizeChange } = usePagination("private-network");

  return (
    <Pagination
      onPageClick={handlePageClick}
      onSizeChange={handleSizeChange}
      currentPage={current}
      pageSize={size}
      totalItems={total}
      pageCount={count}
    />
  );
};

PrivateNetworkTable.Pagination = PrivateNetworkTablePagination;