import { Button, Loading, Modal, Typography } from "@/components/atoms";
import { getErrorMessages } from "@/helpers/reduxHelpers";
import { driverProfileApi } from "@/redux/apis";
import { useUpdateFleetStatusMutation } from "@/redux/apis/driver/driverApi";
import { useAppDispatch } from "@/redux/hooks";
import { addToast } from "@/utils";
import { useManageDriverContext } from "../context";

interface ApproveDriverModalProps {
  open: boolean;
  onClose: () => void;
}

export const ApproveDriverModal = ({ open, onClose }: ApproveDriverModalProps) => {
  const dispatch = useAppDispatch();
  const { driver } = useManageDriverContext();
  const [updateFleetStatus, { isLoading }] = useUpdateFleetStatusMutation();

  const updateStatus = () => {
    updateFleetStatus({ id: driver.uuid, status: "active" })
      .unwrap()
      .then(() => {
        dispatch(driverProfileApi.util.invalidateTags(["DriverProfile"]));
        addToast("success", "Successfully approved driver");
        onClose();
      })
      .catch((e) => getErrorMessages(e).forEach((m) => addToast("danger", m)));
  };

  return (
    <Modal open={open} onClose={() => (isLoading ? null : onClose())} className="w-[80vw] md:w-[514px] lg:w-[514px]">
      {isLoading && <Loading />}
      <Typography variant="title">Confirm Driver Approval</Typography>
      <Typography variant="paragraph" className="mt-3">
        I have reviewed all required documentation and confirm that this driver meets the legal requirements to operate. I accept full
        responsibility for this approval.
      </Typography>
      <div className="mt-[22px] flex flex-row items-center justify-end gap-x-4">
        <Button size="md" variant="secondary" onClick={onClose}>
          Cancel
        </Button>
        <Button size="md" variant="primary" onClick={updateStatus}>
          Approve Driver
        </Button>
      </div>
    </Modal>
  );
};
