import { Button, LinkButton, Typography } from "@/components/atoms";
import { Breadcrumbs } from "@/components/atoms";
import { usePage } from "@/hooks";
import { clsx } from "@/utils";

export interface PageHeaderProps extends React.HTMLAttributes<HTMLDivElement> {
  title: string;
  skipCrumbs?: number;
  moreCrumbs?: {
    name: string;
    to: string;
    current: boolean;
  }[];
}

export const PageHeader = ({ className, title, skipCrumbs = 0, moreCrumbs = [], children }: PageHeaderProps) => {
  const { pages } = usePage();

  const splicedPages = [...pages, ...moreCrumbs].splice(skipCrumbs);

  return (
    <header className={clsx("sticky top-0 z-20 -mx-4 bg-neutral-surface-gray p-4", className)}>
      <hgroup className="flex items-center">
        <Typography variant="h1" className="inline-flex">
          {title || pages?.[0].name}
        </Typography>
        <Breadcrumbs
          className={clsx("ml-4 h-6 border-solid border-neutral-dark-gray px-2", splicedPages.length > 0 && " border-l")}
          pages={splicedPages}
        />
      </hgroup>
      {children}
    </header>
  );
};

interface ActionsProps extends React.HTMLAttributes<HTMLDivElement> {
  prevPage?: string;
  onBack?: () => void;
}

const Actions = ({ prevPage, onBack, children, className }: ActionsProps) => {
  return (
    <div className={clsx("sticky top-[66px] z-30 -mx-2 flex justify-between bg-neutral-surface-gray px-2 pb-3 md:top-[62px]", className)}>
      {onBack ? (
        <Button onClick={onBack} startIcon="ArrowLeft" variant="tertiary" className="bg-transparent" size="sm">
          Back
        </Button>
      ) : (
        <LinkButton to={prevPage ?? "../"} startIcon="ArrowLeft" variant="tertiary" className="bg-transparent" size="sm">
          Back
        </LinkButton>
      )}
      {children}
    </div>
  );
};

PageHeader.Actions = Actions;
