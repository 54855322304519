import { useIntervalEffect } from "@react-hookz/web";
import { createContext, useContext } from "react";
import { useParams } from "react-router-dom";
import { Loading } from "@/components/atoms";
// import preTripJob from "@/components/organisms/BookingTripMap/mocks/pre-trip2.json";
import { useGetGuestBookingTripLogQuery } from "@/redux/apis/guest/guestApi";
import { GuestBookingTripLog } from "@/redux/slices/guest/types";

interface BookingDriverActivityPageState {
  tripLog: GuestBookingTripLog;
}

const BookingDriverActivityContext = createContext<BookingDriverActivityPageState | null>(null);

export const BookingDriverActivityContextProvider = ({ children }: React.PropsWithChildren) => {
  const { jobId, hash } = useParams();

  if (!jobId || !hash) throw new Error("Missing booking trip log params");

  const {
    data: tripLog,
    isError: hasTripLogError,
    isLoading,
    refetch,
  } = useGetGuestBookingTripLogQuery({
    hash,
    jobId,
  });

  // const tripLog = transformGuestBookingTripLog(preTripJob as unknown as RawGuestBookingTripLog);

  useIntervalEffect(() => {
    if (tripLog && tripLog.job.isInProgress) {
      refetch();
    }
  }, 30000);

  if (hasTripLogError) throw new Error("Booking trip log not found");
  if (!tripLog || isLoading) return <Loading />;

  return (
    <BookingDriverActivityContext.Provider
      value={{
        tripLog,
      }}
    >
      {children}
    </BookingDriverActivityContext.Provider>
  );
};

export const useBookingDriverActivityContext = () => {
  const context = useContext(BookingDriverActivityContext);
  if (!context) throw new Error("useBookingDriverActivityContext must be used within BookingDriverActivityContextProvider");

  return context;
};
