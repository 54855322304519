import { Typography } from "@/components/atoms";
import { clsx } from "@/utils";

interface DriverDetailProps extends React.HTMLAttributes<HTMLDivElement> {
  label: string | JSX.Element;
  detail?: string | JSX.Element | null;
  editMode?: boolean;
}

export const DriverDetail = ({ label, detail, editMode = false, className, ...props }: DriverDetailProps) => (
  <div className={clsx("flex flex-col gap-2 col-span-1", className)} {...props}>
    {typeof label === "string" ? <Typography variant="paragraph" className={clsx({ "text-neutral-dark-gray": !editMode })}>{label}</Typography> : label}
    {!detail ? <Typography variant="action">-</Typography> : typeof detail === "string" ? <Typography variant="action" className="break-words">{detail}</Typography> : detail}
  </div>
);