import { Panel } from "@/components/atoms";
import { PendingDriverAccountDetails } from "./sections/PendingDriverAccountDetails";
import { PendingDriverApplicationDetails } from "./sections/PendingDriverApplicationDetails";
import { PendingDriverDetails } from "./sections/PendingDriverDetails";
import { PendingDriverHeader } from "./sections/PendingDriverHeader";
import { PendingDriverStatus } from "./sections/PendingDriverStatus";

export const PendingDriverPanel = () => {
  return (
    <Panel>
      <PendingDriverHeader />
      <PendingDriverStatus />
      <PendingDriverApplicationDetails />
      <PendingDriverAccountDetails />
      <PendingDriverDetails />
    </Panel>
  );
};
