import { formatInTimeZone } from "date-fns-tz";
import { Link } from "react-router-dom";
import { Icon, Tooltip, Typography } from "@/components/atoms";
import { DataTableColumnDef, DataTableRow } from "@/components/molecules";
import { getOldBookingUrl } from "@/helpers/bookingHelpers";
import { Booking, BookingCloseMeta, BookingDriver, BookingStatus, BookingStop, BookingVehicle } from "@/redux/slices/booking/types";
import { Profile } from "@/redux/slices/profile/types";
import { formatCurrency } from "@/utils";
import { BookingFlightNumber } from "../common/BookingFlightNumber/BookingFlightNumber";
import { BookingStatusIcon } from "../common/BookingStatusIcon/BookingStatusIcon";
import { paymentStatuses, statusMapping } from "../fixtures";
import { PaymentStatusTooltip } from "../tooltips/PaymentStatusTooltip";
import { BookingsTableData, TableColumn } from "../types";
import { getDriverWarningTooltip } from "./driver";

export const getRowClasses = (row: DataTableRow<BookingsTableData>) => {
  return row.getIsExpanded() ? "bg-primary-light" : "bg-white";
};

export const generateBookingColumns = (tableColumns: TableColumn[]) => {
  const columns: DataTableColumnDef<BookingsTableData>[] = tableColumns.map(
    (column) =>
      ({
        accessorKey: column.id,
        id: column.sortId || column.id,
        header: () =>
          column.id !== "paymentStatus" ? (
            <Typography className="line-clamp-1 inline">{column.header}</Typography>
          ) : (
            <div className="inline-flex place-items-center gap-2">
              <Typography className="line-clamp-1">{column.header}</Typography>
              <Tooltip content={<PaymentStatusTooltip />} placement="bottom">
                <Icon name="InfoCircle" variant="Bold" className="rotate-180 text-neutral-dark-gray" size="sm" />
              </Tooltip>
            </div>
          ),
        cell: ({ row }) => row.original[column.id],
        ...(column.size && { minSize: column.size, size: column.size }),
        enableSorting: !column.isSortDisabled,
      } as DataTableColumnDef<BookingsTableData>)
  );

  return columns;
};

export const generateBookingData = (bookings: Booking[], profile: Profile, providerKey: string) => {
  const data = bookings.map(
    (booking) =>
      ({
        bookingId: booking.id,
        id: (
          <>
            <div className="group/cell flex items-center gap-2 overflow-ellipsis">
              <span className="hidden lg:inline">{renderOffloadStatus(booking, providerKey)}</span>
              <Typography
                variant="paragraph"
                className="shrink-0 leading-tight group-hover/cell:text-info group-[.compact-view]:flex sm:leading-none"
              >
                {booking?.account ? (
                  <Link
                    to={getOldBookingUrl(booking.account.id, booking.id, "").replace("/admin/", "")}
                    className="relative z-10"
                    onClick={(e) => e.stopPropagation()}
                    target="_blank"
                  >
                    {booking.jobNumber}
                  </Link>
                ) : (
                  booking.jobNumber
                )}
                {["daily_rate", "hourly_rate"].includes(booking.transferType.type) && (
                  <Typography
                    variant="small"
                    className="hidden capitalize leading-4 text-neutral-dark-gray group-[.compact-view]:ml-1 sm:block"
                  >
                    {booking.transferType.type.replace("_", " ").replace("rate", "")}
                  </Typography>
                )}
              </Typography>
            </div>
            <Typography variant="small" className="block leading-normal text-neutral-dark-gray sm:hidden">
              ID
            </Typography>
          </>
        ),
        date: (
          <>
            <Typography className="leading-tight">
              {formatInTimeZone(new Date(booking.dates.pickup), booking.dates.timezone, profile.dateFormat)}
            </Typography>
            <Typography variant="small" className="block leading-normal text-neutral-dark-gray sm:hidden">
              Date
            </Typography>
          </>
        ),
        time: (
          <>
            <div className="flex group-[.compact-view]:items-center group-[.compact-view]:justify-normal sm:flex-col sm:justify-center group-[.compact-view]:sm:flex-row">
              <Typography className="whitespace-nowrap leading-tight ">
                {formatInTimeZone(new Date(booking.dates.pickup), booking.dates.timezone, profile.timeFormat)}
              </Typography>
              {profile.timezone.name !== booking.dates.timezone && (
                <Typography variant="small" className="whitespace-nowrap leading-snug text-neutral-dark-gray">
                  <span className="hidden group-[.compact-view]:hidden sm:inline"> ({booking.dates.timezone.split("/")[1]})</span>
                  <Tooltip
                    content={formatInTimeZone(new Date(booking.dates.pickup), profile.timezone.name, `${profile.timeFormat} zzzz`)}
                    placement="bottom"
                  >
                    <Icon name="InfoCircle" variant="Bold" size="md" className="ml-1 inline rotate-180" />
                  </Tooltip>
                </Typography>
              )}
            </div>
            <Typography variant="small" className="block leading-normal text-neutral-dark-gray sm:hidden">
              Time
            </Typography>
          </>
        ),
        passenger: renderPassenger(booking),
        client: <Typography className="group-[.compact-view]:truncate">{booking.account?.name || "-"}</Typography>,
        bookedBy: <Typography className="group-[.compact-view]:truncate">{booking.bookedBy || "-"}</Typography>,
        company: <Typography className="group-[.compact-view]:truncate">{booking.account?.company?.name || "-"}</Typography>,
        pickupLocation: renderPickupLocation(booking.stops, booking.metadata.isVerified),
        destination: renderDestination(booking.stops[booking.stops.length - 1].address, booking.metadata.isVerified),
        driver: renderDriver(booking.metadata.isVehicleCorrect, booking.driver, booking.assignedVehicle),
        transferType: <div className="group-[.compact-view]:truncate">{booking.transferType.name}</div>,
        cost:
          (!booking.metadata.isOffloaded || providerKey === booking.operator?.providerKey.toLowerCase()) && booking.cost
            ? formatCurrency(booking.cost.total, 2, booking.cost.currency)
            : "-",
        status: renderBookingStatus(booking.status, booking.closeMeta),
        paymentStatus: booking.paymentStatus ? paymentStatuses[booking.paymentStatus] : "",
        dashboardNotes: booking.notes.dashboard,
        operator: <Typography className="group-[.compact-view]:truncate">{booking.operator?.name}</Typography>,
        appBooking: booking.metadata.isAppBooking ? (
          <Tooltip content="Booked via Mobile" placement="left">
            <Icon name="app-booking" isCustom size={18} className="m-0" />
          </Tooltip>
        ) : null,
        vehicleType: <Typography className="group-[.compact-view]:truncate">{booking.vehicleCategory.name}</Typography>,
        metadata: {
          isVerified: booking.metadata.isVerified,
          transferRate: booking.transferType.type,
        },
        mobileMetadata: (
          <div className="flex items-center gap-2">
            <span className="inline-block h-[16px] empty:hidden lg:hidden">{renderOffloadStatus(booking, providerKey)}</span>
            {renderFlightStatus(booking.stops)}
            {booking.metadata.isAppBooking && (
              <Tooltip content="Booked via Mobile" placement="left">
                <Icon name="app-booking" isCustom size={18} className="m-0" />
              </Tooltip>
            )}
          </div>
        ),
      } as BookingsTableData)
  );

  return data;
};

const renderOffloadStatus = (booking: Booking, providerKey: string) => {
  const isOwnJob = booking.operator?.providerKey.toLowerCase() === providerKey.toLowerCase();
  const { isInPrivateNetwork, isOffloadToPrivate, isOffloadToPublic, isOffloaded } = booking.metadata;

  if (isOwnJob) {
    if (isOffloaded && !isInPrivateNetwork) {
      return (
        <Tooltip content="Offloaded to the network">
          <Icon name="booking-offloaded-network" isCustom size={18} className="-mb-3 text-neutral-dark-gray sm:mb-0" />
        </Tooltip>
      );
    }

    if (isOffloaded && isInPrivateNetwork) {
      return (
        <Tooltip content="Offload through your Private Network">
          <Icon name="booking-offloaded-private-network" isCustom size={18} className="-mb-3 text-neutral-dark-gray sm:mb-0" />
        </Tooltip>
      );
    }

    return null;
  } else {
    if (isOffloadToPublic || (isOffloaded && !isInPrivateNetwork)) {
      return (
        <Tooltip content="Accepted from the network">
          <Icon name="booking-accepted-network" isCustom size={18} className="-mb-3 text-neutral-dark-gray sm:mb-0" />
        </Tooltip>
      );
    }

    if (isOffloadToPrivate || (isOffloaded && isInPrivateNetwork)) {
      return (
        <Tooltip content="Accepted from your Private Network">
          <Icon name="booking-accepted-private-network" isCustom size={18} className="-mb-3 text-neutral-dark-gray sm:mb-0" />
        </Tooltip>
      );
    }

    return null;
  }
};

const renderPassenger = (booking: Booking) => {
  const { passenger, bookedBy, metadata, account } = booking;
  const shouldNeverOffload = account?.flags.dispatchRule === "never";
  const isVip = account?.flags.isVip || false;

  return (
    <>
      <div className="">
        <Typography variant="paragraph" className="flex items-center leading-tight group-[.compact-view]:line-clamp-1">
          <Tooltip
            content={
              <div className="[&>p]:leading-tight">
                <Typography className="empty:hidden">Passenger: {passenger.name}</Typography>
                <Typography className="empty:hidden">Contact Number: {passenger.phone}</Typography>
              </div>
            }
            placement="bottom"
          >
            <span>
              {passenger.name} {passenger.count > 1 && `(${passenger.count})`}
            </span>
          </Tooltip>

          {shouldNeverOffload && (
            <Tooltip content="Never Offload" placement="bottom">
              <Icon name="Flag" variant="Bold" size="sm" className="ml-1 inline text-info" />
            </Tooltip>
          )}

          {isVip && (
            <Tooltip content="VIP Client" placement="bottom">
              <Icon name="Crown" variant="Bold" size="sm" className="ml-1 inline text-warning" />
            </Tooltip>
          )}
        </Typography>

        {bookedBy !== passenger.name && !metadata.isAcceptedFromNetwork && (
          <Typography
            variant="small"
            className="line-clamp-2 hidden leading-snug text-neutral-dark-gray group-[.compact-view]:!hidden sm:block"
          >
            Booked by {bookedBy}
          </Typography>
        )}
      </div>

      <Typography variant="small" className="block leading-normal text-neutral-dark-gray sm:hidden">
        Passenger
      </Typography>
    </>
  );
};

const renderBookingStatus = (status: BookingStatus, closeMeta?: BookingCloseMeta) => {
  const statusObj = statusMapping[status];

  const closedAsNoShow = Boolean(closeMeta?.reason.includes("completed_no_show"));

  return (
    <div>
      <div className="flex items-center gap-1.5 2xl:gap-2 ">
        <Tooltip content={statusObj.name} placement="top">
          <i>
            <BookingStatusIcon
              status={{
                color: statusObj.color,
                icon: statusObj.icon,
              }}
            />
          </i>
        </Tooltip>
        <Typography variant="paragraph" className="leading-tight">
          {statusObj.legend}
        </Typography>
        {closedAsNoShow && (
          <Tooltip content="Closed as No Show" placement="top">
            <Icon size="sm" name="InfoCircle" variant="Bold" className="-translate-x-0.5 rotate-180 text-warning-dark" />
          </Tooltip>
        )}
      </div>
      <Typography variant="small" className="block leading-normal text-neutral-dark-gray sm:hidden">
        Status
      </Typography>
    </div>
  );
};

const renderFlightStatus = (stops: BookingStop[]) => {
  const flight = stops.find((stop) => stop.flight)?.flight;

  return flight ? <BookingFlightNumber number={flight.number} status={flight.status} delay={flight.delay} /> : null;
};

const renderPickupLocation = (stops: BookingStop[], isVerified: boolean) => {
  const pickup = stops[0];
  const midStops = stops.slice(1, stops.length - 1);

  return (
    <>
      <div className="flex flex-col">
        <Tooltip content={pickup.address} placement="bottom">
          <Typography variant="paragraph" className="line-clamp-1 leading-tight lg:line-clamp-2 lg:group-[.compact-view]:line-clamp-1">
            {pickup.address}
          </Typography>
        </Tooltip>
        <div className="mt-1 hidden items-center gap-2 empty:hidden lg:flex ">
          {renderFlightStatus(stops)}
          {midStops.length > 0 && (
            <Typography variant="small" className="leading-snug text-neutral-dark-gray">
              {midStops.length > 0 && `+ ${midStops.length} Stop${midStops.length > 1 ? "s" : ""}`}
            </Typography>
          )}
        </div>
        {!isVerified && (
          <Typography variant="small" className="flex items-center text-danger [&+div]:hidden">
            Requires Validation
            <Icon name="Danger" size={18} variant="Bold" className="ml-1 inline text-warning" />
          </Typography>
        )}
      </div>
      <Typography variant="small" className="block leading-normal text-neutral-dark-gray sm:hidden">
        Pickup
      </Typography>
    </>
  );
};

const renderDestination = (address: string, isVerified: boolean) => {
  return (
    <>
      <div className="flex flex-col ">
        <Tooltip content={address} placement="bottom">
          <Typography variant="paragraph" className="line-clamp-1 leading-tight lg:line-clamp-2 lg:group-[.compact-view]:line-clamp-1">
            {address}
          </Typography>
        </Tooltip>
        {!isVerified && (
          <Typography variant="small" className="flex items-center text-danger [&+div]:hidden">
            Requires Validation
            <Icon name="Danger" size={18} variant="Bold" className="ml-1 inline text-warning" />
          </Typography>
        )}
      </div>
      <Typography variant="small" className="block leading-normal text-neutral-dark-gray sm:hidden">
        Destination
      </Typography>
    </>
  );
};

const renderDriver = (isVehicleCorrect: boolean, driver?: BookingDriver, assignedVehicle?: BookingVehicle) => {
  const showWarning = assignedVehicle && driver?.vehicle ? assignedVehicle.uuid !== driver.vehicle.uuid : !isVehicleCorrect;
  return (
    <>
      {driver ? (
        <Tooltip
          content={
            <div className="[&>p]:leading-tight">
              <Typography>Driver: {driver.name}</Typography>
              <Typography>Number: {driver.phone}</Typography>
            </div>
          }
          placement="bottom"
        >
          <Typography className="line-clamp-1 inline-flex items-center leading-tight group-[.compact-view]:whitespace-nowrap max-sm:truncate lg:line-clamp-2">
            {driver.name}
            {showWarning && (
              <Tooltip content={getDriverWarningTooltip("warning_vehicle_type_incorrect")} placement="top">
                <Icon name="Danger" size={18} variant="Bold" className="ml-1 inline text-warning" />
              </Tooltip>
            )}
          </Typography>
        </Tooltip>
      ) : (
        <Typography className="leading-tight group-[.compact-view]:truncate">-</Typography>
      )}
      <Typography variant="small" className="block leading-normal text-neutral-dark-gray sm:hidden">
        Driver
      </Typography>
    </>
  );
};
