export const PendingStatuses = ["pending", "Pending Qualification", "Application in Progress", "Pending Approval"];

export const ArchivedStatuses = ["removed", "left", "Permanently Terminated", "Retired", "Declined"];

export const ActiveStatuses = ["active", "Approved"];

export const NewStatuses = ["pending", "removed", "left", "active", "inactive", "suspended"];

export type MyDriversStep = "fleet-leader" | undefined;

export type MyDriversTableData = {
  name: JSX.Element;
  email: string;
  mobile: string;
  rating: JSX.Element;
  state: string;
  tier: string;
  fleetLeader: JSX.Element;
  status: JSX.Element;
  action: JSX.Element;
};
