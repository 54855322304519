import { addMinutes } from "date-fns";
import { format, formatInTimeZone } from "date-fns-tz";
import { Card, Icon, Tooltip, Typography } from "@/components/atoms";
import { useBookingETA } from "../../BookingTripMap/hooks/useBookingETA";
import { useBookingDriverActivityContext } from "../context";

export const BookingInformation = () => {
  const { tripLog } = useBookingDriverActivityContext();
  const { ETA } = useBookingETA({ tripLog });

  const { job, bookingStops, driverCurrentLocationData } = tripLog;
  const { id, pickupDate, timezone, flightNumber, driver, reference } = job;
  const { pickupLocationData, dropoffLocationData } = bookingStops;
  if (!pickupLocationData || !dropoffLocationData) throw new Error("Missing booking stops data");

  const { address: pickupAddress } = pickupLocationData;
  const { address: dropoffAddress } = dropoffLocationData;

  const showETA = !!driverCurrentLocationData && ETA;

  return (
    <Card className="w-full border border-neutral-mid-gray p-0">
      <div className="rounded-t-lg border-b border-neutral-mid-gray bg-neutral-gray px-5 py-4">
        <Typography variant="action" className="block leading-none">
          Booking Information
        </Typography>
      </div>
      <div className="space-y-4 p-5 pb-8">
        <Typography className="flex gap-3">
          <Icon name="ArchiveBook" variant="Bold" size="lg" className="text-primary" />J{id}
        </Typography>
        <Typography className="flex gap-3">
          <Icon name="Calendar" variant="Bold" size="lg" className="text-primary" />{" "}
          {formatInTimeZone(new Date(pickupDate), timezone, "dd-MM-yyyy")}
        </Typography>
        <Typography className="flex items-center gap-3">
          <Icon name="Clock" variant="Bold" size="lg" className="text-primary" />{" "}
          {formatInTimeZone(new Date(pickupDate), timezone, "hh:mm a")}
          <Tooltip content={format(new Date(pickupDate), "hh:mm a zzzz")} placement="top">
            <Icon name="InfoCircle" variant="Bold" size="md" className="-translate-x-1 -rotate-180 text-neutral-dark-gray" />
          </Tooltip>
        </Typography>
        {showETA && (
          <Typography className="flex gap-3">
            <Icon name="Timer1" variant="Bold" size="lg" className="text-primary" />
            ETA {formatInTimeZone(addMinutes(new Date(driverCurrentLocationData.dateTime), ETA), timezone, "hh:mm a")}
          </Typography>
        )}
        <div className="flex items-center gap-3">
          <Icon name="Location" variant="Bold" size="lg" className="shrink-0 self-start text-primary" />
          <Typography>{pickupAddress}</Typography>
        </div>
        <div className="flex items-center gap-3">
          <Icon name="Flag" variant="Bold" size="lg" className="shrink-0 self-start text-primary" />
          <Typography>{dropoffAddress}</Typography>
        </div>
        {flightNumber && (
          <Typography className="flex gap-3">
            <Icon name="Airplane" variant="Bold" size="lg" className="shrink-0 self-start text-primary" />
            Flight Number: {flightNumber.toUpperCase()}
          </Typography>
        )}
        {driver && (
          <Typography className="flex gap-3">
            <Icon name="Profile" variant="Bold" size="lg" className="shrink-0 self-start text-primary" />
            {driver.name} - {driver.vehicle?.model.make}{" "}
            {driver.vehicle?.registrationNumber ? `(Plate: ${driver.vehicle.registrationNumber})` : ""}
          </Typography>
        )}
        <Typography className="flex gap-3">
          <Icon name="Car" variant="Bold" size="lg" className="text-primary" />
          {job.vehicleType}
        </Typography>
        {reference && <Typography className="!mt-8 block text-center text-info">Job reference: {reference}</Typography>}
      </div>
    </Card>
  );
};
