import camelcaseKeys from "camelcase-keys";
import {
  Booking,
  BookingAttachment,
  BookingDetails,
  BookingFlightStatus,
  BookingFormOptions,
  BookingHistoryActivity,
  BookingHistoryLog,
  BookingPaymentLog,
  BookingPaymentMethod,
  BookingPaymentLogStatus,
  BookingStatus,
  BookingCloseExtra,
  BookingCloseExtraType,
  BookingDriverAssignmentLog,
  BookingPaymentStatus,
  BookingPaymentLedgerLog,
  BookingPaymentBreakdownLog,
  BookingPaymentMeta,
  BookingStop,
  BookingDispatchRule,
  BookingFlightLuggage,
} from "@/redux/slices/booking/types";
import { TransferType } from "@/redux/slices/pricing/types";
import {
  GetBookingAttachmentsRawResponse,
  GetBookingFormOptionsRawResponse,
  GetBookingHistoryActivityRawResponse,
  GetBookingHistoryLogsRawResponse,
  GetBookingHistoryLogsResponse,
  GetBookingMessagesRawResponse,
  GetBookingMessagesResponse,
  GetBookingPaymentBreakdownRawResponse,
  GetBookingPaymentLedgerRawResponse,
  GetBookingPaymentsRawResponse,
  GetBookingsRawResponse,
  GetBookingsResponse,
  GetDriverAssignmentLogsRawResponse,
  RawBookingAttachment,
  RawBookingDetails,
  RawBookingTripLog,
  RawDriverAssignmentLog,
} from "./types";

export const transformBookings = (res: GetBookingsRawResponse) => {
  const {
    _embedded: { job: bookings },
    page_count,
    page_size,
    page,
    total_items,
  } = res;

  const response: GetBookingsResponse = {
    data: {
      bookings: bookings.map(
        (i) =>
          ({
            id: i.uuid,
            jobNumber: i.job_number,
            dates: {
              pickup: i.dates.pickup_time,
              timezone: i.dates.timezone,
            },
            account: i.account
              ? {
                  id: i.account.uuid,
                  name: i.account.name,
                  phone: i.account.phone,
                  avatar: i.account.avatar || undefined,
                  company: i.account.company
                    ? {
                        id: i.account.company.uuid,
                        name: i.account.company.name,
                      }
                    : undefined,
                  flags: {
                    dispatchRule: i.account.flags.dispatch_rule as BookingDispatchRule,
                    isVip: i.account.flags.is_vip,
                  },
                }
              : undefined,
            bookedBy: i.booked_by,
            passenger: {
              name: i.passenger.name,
              phone: i.passenger.phone,
              count: i.passenger.passenger_count,
            },
            status: i.status,
            trackingHash: i.tracking_hash,
            paymentStatus: (i.payment_status as BookingPaymentLogStatus) ?? undefined,
            metadata: {
              hash: i.metadata.hash,
              isAppBooking: i.metadata.is_app_booking,
              isAcceptedFromNetwork: i.metadata.is_accepted_from_network,
              isVerified: i.metadata.is_verified,
              isOffloadToPublic: i.metadata.is_offload_to_public_network,
              isOffloadToPrivate: i.metadata.is_offload_to_private_network,
              isDriverOnWay: i.metadata.is_driver_on_way,
              isSpecialEvent: i.metadata.is_special_event,
              isInPrivateNetwork: i.metadata.is_in_private_network,
              isReopenAllowed: i.metadata.is_reopen_allowed ?? false,
              isCancelAllowed: i.metadata.is_cancel_allowed ?? false,
              isDriverExtrasAllowed: i.metadata.is_driver_extras_allowed ?? false,
              isCloseJobAllowed: i.metadata.is_close_job_allowed ?? false,
              isAdjustInvoiceAllowed: i.metadata.is_adjust_invoice_allowed ?? false,
              isReassignClientAllowed: i.metadata.is_reassign_client_allowed ?? false,
              isVehicleCorrect: i.metadata.is_vehicle_correct,
              isDriverEditAllowed: i.metadata.is_driver_edit_allowed ?? false,
              isVehicleEditAllowed: i.metadata.is_vehicle_edit_allowed ?? false,
              isOffloaded:
                i.metadata.is_accepted_from_network || (i.driver ? i.provider.provider_key !== i.driver.provider.provider_key : false),
            },
            assignedVehicle: i.assigned_vehicle ? camelcaseKeys(i.assigned_vehicle, { deep: true }) : undefined,
            vehicleCategory: {
              id: i.vehicle_category.uuid,
              name: i.vehicle_category.name,
            },
            notes: {
              dashboard: i.notes.dashboard,
              admin: i.notes.admin,
              driver: i.notes.driver,
              passenger: i.notes.passenger_preference,
              external: i.notes.external,
              operatorToDriver: i.notes.operator_to_driver,
            },
            cost: {
              total: i.cost.total,
              currency: i.cost.currency,
              percentageToDriver: i.cost.percentage_to_driver ?? undefined,
              percentageToVehicle: i.cost.percentage_to_vehicle ?? undefined,
              isDriverPaidByPlatform: i.cost.is_driver_paid_by_platform,
              paymentMethod: i.cost.payment_method as BookingPaymentMethod,
              offloadCalculation: i.cost.offload_calculation
                ? {
                    type: i.cost.offload_calculation.type.toLowerCase(),
                    value: i.cost.offload_calculation.value,
                  }
                : undefined,
            },
            currency: i.cost.currency,
            driver: i.driver
              ? {
                  id: i.driver.uuid,
                  type: i.driver.type,
                  name: i.driver.name,
                  nickname: i.driver.nickname,
                  phone: i.driver.phone,
                  avatar: i.driver.avatar,
                  email: i.driver.email,
                  operator: {
                    name: i.driver.provider.name,
                    phone: i.driver.provider.phone_number,
                    providerKey: i.driver.provider.provider_key,
                    assets: i.driver.provider.assets,
                  },
                  vehicle: i.driver.vehicle ? camelcaseKeys(i.driver.vehicle, { deep: true }) : undefined,
                  rating: i.driver.rating
                    ? {
                        average: i.driver.rating.rating,
                        jobCount: i.driver.rating.jobs_completed,
                      }
                    : undefined,
                }
              : undefined,
            stops: i.stops.map(
              (j) =>
                ({
                  address: j.address,
                  long: j.long ?? undefined,
                  lat: j.lat ?? undefined,
                  flight: j.flight
                    ? {
                        number: j.flight.number,
                        status: j.flight.status as BookingFlightStatus,
                        delay: j.flight.delay,
                        luggage: j.flight.luggage ? (j.flight.luggage.toLowerCase() as BookingFlightLuggage) : undefined,
                        codeShare: j.flight.code_share,
                        operatingAs: j.flight.operating_as,
                        airline: j.flight.airline ? camelcaseKeys(j.flight.airline) : undefined,
                        arrival: camelcaseKeys(j.flight.arrival, { deep: true }),
                        departure: camelcaseKeys(j.flight.departure, { deep: true }),
                      }
                    : undefined,
                } satisfies BookingStop)
            ),
            transferType: {
              id: i.transfer_type.uuid,
              name: i.transfer_type.name,
              type: i.transfer_type.type,
              duration: i.transfer_type.duration,
            },

            operator: i.provider
              ? {
                  providerKey: i.provider.provider_key,
                  name: i.provider.name,
                  phone: i.provider.phone_number,
                  assets: i.provider.assets,
                }
              : undefined,
            closeMeta: i.close
              ? {
                  reason: i.close.reason,
                  driverNotes: i.close.driver_notes,
                  clientNotes: i.close.client_notes,
                  providerNotes: i.close.provider_notes,
                  waitTime: i.close.payload.wait_time
                    ? {
                        name: i.close.payload.wait_time.label,
                        cost: Number(i.close.payload.wait_time.value) / 100,
                        type: i.close.payload.wait_time.expense_type as BookingCloseExtraType,
                      }
                    : undefined,
                  additionalRunTime: i.close.payload.additional_run_time
                    ? {
                        name: i.close.payload.additional_run_time.label,
                        cost: Number(i.close.payload.additional_run_time.value) / 100,
                        type: i.close.payload.additional_run_time.expense_type as BookingCloseExtraType,
                      }
                    : undefined,
                  unscheduledStops: i.close.payload.unscheduled_stops
                    ? {
                        name: i.close.payload.unscheduled_stops.label,
                        cost: Number(i.close.payload.unscheduled_stops.value) / 100,
                        type: i.close.payload.unscheduled_stops.expense_type as BookingCloseExtraType,
                      }
                    : undefined,
                  internationalAirportPickup: i.close.payload.international_airport_pickup
                    ? {
                        name: Number(i.close.payload.international_airport_pickup.value / 100).toString(),
                        cost: Number(i.close.payload.international_airport_pickup.value),
                        type: i.close.payload.international_airport_pickup.expense_type as BookingCloseExtraType,
                      }
                    : undefined,
                  parkingCharges: i.close.payload.parking_charges
                    ? {
                        name: (Number(i.close.payload.parking_charges.label) / 100).toString(),
                        cost: i.close.payload.parking_charges.value / 100,
                        type: i.close.payload.parking_charges.expense_type as BookingCloseExtraType,
                      }
                    : undefined,
                  tollCharges: i.close.payload.toll_charges
                    ? {
                        name: (Number(i.close.payload.toll_charges.label) / 100).toString(),
                        cost: i.close.payload.toll_charges.value / 100,
                        type: i.close.payload.toll_charges.expense_type as BookingCloseExtraType,
                      }
                    : undefined,
                  otherExtras: i.close.payload.other_extra?.map(
                    (i) =>
                      ({
                        name: i.label,
                        cost: i.value / 100,
                        type: i.expense_type,
                      } as BookingCloseExtra)
                  ),
                }
              : undefined,
          } as Booking)
      ),
      page: {
        current: page,
        count: page_count,
        size: page_size,
        total: total_items,
      },
    },
  };

  return response;
};

export const transformBookingFormOptions = (res: GetBookingFormOptionsRawResponse) => {
  const { transfer_type, assigned_vehicle, vehicle_type, state, status } = res;

  const response: BookingFormOptions = {
    states: Object.entries(state).flatMap(([_country, statesArr]) =>
      statesArr.flatMap((stateObj) => Object.entries(stateObj).map((i) => ({ name: i[1], value: i[0] })))
    ),
    transferTypes: Object.entries(transfer_type).map((i) => ({ name: i[1], value: i[0] })),
    assignedVehicles: Object.entries(assigned_vehicle).map((i) => ({ name: i[1], value: i[0] })),
    vehicleTypes: Object.entries(vehicle_type).map((i) => ({ name: i[1], value: i[0] })),
    statuses: Object.entries(status).map((i) => ({ name: i[1], value: i[0] })),
  };

  return response;
};

export const transformBookingDetails = (res: RawBookingDetails) => {
  const {
    uuid,
    job_number,
    dates,
    account,
    passenger,
    booked_by,
    status,
    payment_status,
    assigned_vehicle,
    cancellation_policy,
    metadata,
  } = res;

  const response: BookingDetails = {
    id: uuid,
    jobNumber: job_number,
    dates: {
      pickup: dates.pickup_time,
      timezone: dates.timezone,
    },
    account: account
      ? {
          id: account.uuid,
          name: account.name,
          phone: account.phone,
          avatar: account.avatar || "",
          identifier: account.identifier,
          company: account.company
            ? {
                id: account.company.uuid,
                name: account.company.name,
              }
            : undefined,
          flags: {
            dispatchRule: account.flags.dispatch_rule as BookingDispatchRule,
            isVip: account.flags.is_vip,
          },
        }
      : undefined,
    passenger: {
      name: passenger.name,
      phone: passenger.phone,
      count: passenger.passenger_count,
      email: passenger.email,
      preferences: {
        quietRide: passenger.preferences.quiet_ride ?? undefined,
        radio: passenger.preferences.radio ?? undefined,
        radioStation: passenger.preferences.radio_station ?? undefined,
        seating: passenger.preferences.seating ?? undefined,
        vehicleTemperature: passenger.preferences.vehicle_temperature ?? undefined,
      },
    },
    bookedBy: booked_by,
    status: status as BookingStatus,
    trackingHash: res.tracking_hash,
    paymentStatus: (payment_status as BookingPaymentStatus) ?? undefined,
    referenceExternal: res.reference_external,
    referenceNumber: res.reference_number,
    assignedVehicle: assigned_vehicle ? camelcaseKeys(assigned_vehicle, { deep: true }) : undefined,
    vehicleCategory: {
      id: res.vehicle_category.uuid,
      name: res.vehicle_category.name,
    },
    cancellationPolicy: {
      policy: {
        countryCode: cancellation_policy.policy.country_code,
        baseInterval: {
          rate: cancellation_policy.policy.base_interval.rate,
          rangeTo: cancellation_policy.policy.base_interval.range_to,
        },
        intervals: cancellation_policy.policy.intervals.map((i) => ({
          rate: i.rate,
          rangeTo: i.range_to,
        })),
      },
      asap: {
        countryCode: cancellation_policy.asap.country_code,
        cancellationFee: cancellation_policy.asap.cancellation_fee,
        feeFreeWindow: cancellation_policy.asap.fee_free_window,
      },
    },
    metadata: {
      hash: metadata.hash,
      isAppBooking: metadata.is_app_booking,
      isAcceptedFromNetwork: metadata.is_accepted_from_network,
      isVerified: metadata.is_verified,
      isOffloadToPublic: metadata.is_offload_to_public_network,
      isOffloadToPrivate: metadata.is_offload_to_private_network,
      isDriverOnWay: metadata.is_driver_on_way,
      isSpecialEvent: metadata.is_special_event,
      isInPrivateNetwork: metadata.is_in_private_network,
      isReopenAllowed: metadata.is_reopen_allowed ?? false,
      isCancelAllowed: metadata.is_cancel_allowed ?? false,
      isDriverExtrasAllowed: metadata.is_driver_extras_allowed ?? false,
      isCloseJobAllowed: metadata.is_close_job_allowed ?? false,
      isAdjustInvoiceAllowed: metadata.is_adjust_invoice_allowed ?? false,
      isReassignClientAllowed: metadata.is_reassign_client_allowed ?? false,
      isVehicleEditAllowed: metadata.is_vehicle_edit_allowed ?? false,
      isDriverEditAllowed: metadata.is_driver_edit_allowed ?? false,
      isVehicleCorrect: metadata.is_vehicle_correct,
      isOffloaded:
        metadata.is_accepted_from_network || (res.driver ? res.provider.provider_key !== res.driver.provider.provider_key : false),
    },
    notes: {
      dashboard: res.notes.dashboard,
      admin: res.notes.admin,
      driver: res.notes.driver,
      passenger: res.notes.passenger_preference,
      external: res.notes.external,
      operatorToDriver: res.notes.operator_to_driver,
    },
    cost: {
      total: res.cost.total,
      currency: res.cost.currency,
      breakdown: {
        driverPayment: res.cost.breakdown.driver_payment ?? 0,
        offloadFee: res.cost.breakdown.offload_fee ?? 0,
        providerPayment: res.cost.breakdown.provider_payment ?? 0,
        platformFee: res.cost.breakdown.platform_fee ?? 0,
        vehiclePayment: res.cost.breakdown.vehicle_payment ?? 0,
      },
      percentageToDriver: res.cost.percentage_to_driver ?? undefined,
      percentageToVehicle: res.cost.percentage_to_vehicle ?? undefined,
      isDriverPaidByPlatform: res.cost.is_driver_paid_by_platform,
      paymentMethod: res.cost.payment_method as BookingPaymentMethod,
      offloadCalculation: res.cost.offload_calculation
        ? {
            type: res.cost.offload_calculation.type.toLowerCase(),
            value: res.cost.offload_calculation.value,
          }
        : undefined,
    },
    routeImage: res.route_image,
    driver: res.driver
      ? {
          id: res.driver.uuid,
          type: res.driver.type,
          name: res.driver.name,
          nickname: res.driver.nickname,
          phone: res.driver.phone,
          avatar: res.driver.avatar,
          email: res.driver.email,
          operator: {
            name: res.driver.provider.name,
            phone: res.driver.provider.phone_number,
            providerKey: res.driver.provider.provider_key,
            assets: res.driver.provider.assets,
          },
          vehicle: res.driver.vehicle ? camelcaseKeys(res.driver.vehicle, { deep: true }) : undefined,
          rating: res.driver.rating
            ? {
                average: res.driver.rating.rating,
                jobCount: res.driver.rating.jobs_completed,
              }
            : undefined,
        }
      : undefined,
    stops: res.stops.map(
      (j) =>
        ({
          address: j.address,
          long: j.long ?? undefined,
          lat: j.lat ?? undefined,
          flight: j.flight
            ? {
                number: j.flight.number,
                status: j.flight.status as BookingFlightStatus,
                delay: j.flight.delay,
                luggage: j.flight.luggage ? (j.flight.luggage.toLowerCase() as BookingFlightLuggage) : undefined,
                codeShare: j.flight.code_share,
                operatingAs: j.flight.operating_as,
                airline: j.flight.airline ? camelcaseKeys(j.flight.airline) : undefined,
                arrival: camelcaseKeys(j.flight.arrival, { deep: true }),
                departure: camelcaseKeys(j.flight.departure, { deep: true }),
              }
            : undefined,
        } satisfies BookingStop)
    ),
    transferType: {
      id: res.transfer_type.uuid,
      name: res.transfer_type.name,
      type: res.transfer_type.type as TransferType,
      duration: res.transfer_type.duration,
    },
    extras: res.extras.map((i) => ({
      name: i.name,
      cost: i.cost,
      driverVisible: i["driver-visible"],
      image: i.image ?? "others",
      type: i.type,
    })),
    fees: res.fees?.map((i) => ({
      name: i.name,
      cost: i.cost,
      driverVisible: i["driver-visible"],
      expenseType: i.expense_type,
      type: i.type,
      image: i.image ?? "others",
    })),
    operator: {
      providerKey: res.provider.provider_key,
      name: res.provider.name,
      phone: res.provider.phone_number,
      assets: res.provider.assets,
    },
    closeMeta: res.close
      ? {
          reason: res.close.reason,
          driverNotes: res.close.driver_notes,
          clientNotes: res.close.client_notes,
          providerNotes: res.close.provider_notes,
          waitTime: res.close.payload.wait_time
            ? {
                name: res.close.payload.wait_time.label,
                cost: Number(res.close.payload.wait_time.value) / 100,
                type: res.close.payload.wait_time.expense_type as BookingCloseExtraType,
              }
            : undefined,
          additionalRunTime: res.close.payload.additional_run_time
            ? {
                name: res.close.payload.additional_run_time.label,
                cost: Number(res.close.payload.additional_run_time.value) / 100,
                type: res.close.payload.additional_run_time.expense_type as BookingCloseExtraType,
              }
            : undefined,
          unscheduledStops: res.close.payload.unscheduled_stops
            ? {
                name: res.close.payload.unscheduled_stops.label,
                cost: Number(res.close.payload.unscheduled_stops.value) / 100,
                type: res.close.payload.unscheduled_stops.expense_type as BookingCloseExtraType,
              }
            : undefined,
          internationalAirportPickup: res.close.payload.international_airport_pickup
            ? {
                name: Number(res.close.payload.international_airport_pickup.value / 100).toString(),
                cost: Number(res.close.payload.international_airport_pickup.value),
                type: res.close.payload.international_airport_pickup.expense_type as BookingCloseExtraType,
              }
            : undefined,
          parkingCharges: res.close.payload.parking_charges
            ? {
                name: (Number(res.close.payload.parking_charges.label) / 100).toString(),
                cost: res.close.payload.parking_charges.value / 100,
                type: res.close.payload.parking_charges.expense_type as BookingCloseExtraType,
              }
            : undefined,
          tollCharges: res.close.payload.toll_charges
            ? {
                name: (Number(res.close.payload.toll_charges.label) / 100).toString(),
                cost: res.close.payload.toll_charges.value / 100,
                type: res.close.payload.toll_charges.expense_type as BookingCloseExtraType,
              }
            : undefined,
          otherExtras: res.close.payload.other_extra?.map(
            (i) =>
              ({
                name: i.label,
                cost: i.value / 100,
                type: i.expense_type,
              } as BookingCloseExtra)
          ),
        }
      : undefined,
  };

  return response;
};

export const transformBookingMessages = (res: GetBookingMessagesRawResponse) => {
  const { driver, passenger, message_log } = res;
  const response: GetBookingMessagesResponse = {
    driver: {
      name: driver.name,
      email: driver.email,
      avatar: driver.avatar ?? "",
      maskedPhone: driver.phone_masked,
      realPhone: driver.phone_real,
    },
    client: {
      name: passenger.name,
      email: passenger.email,
      avatar: passenger.avatar ?? "",
      maskedPhone: passenger.phone_masked,
      realPhone: passenger.phone_real,
    },
    logs: message_log.map((log) => ({
      id: log.timestamp,
      createdAt: log.timestamp,
      message: log.content ?? "",
      type: log.type,
      sender: log.actor,
    })),
  };
  return response;
};

export const transformBookingAttachment = (res: RawBookingAttachment) => {
  const attachment: BookingAttachment = {
    id: res.uuid,
    description: res.description,
    filename: res.filename,
    createdAt: res.created_at,
    permission: res.permission,
    uploader: { name: res.created_by.name, email: res.created_by.email },
    link: res.link,
  };
  return attachment;
};

export const transformBookingAttachments = (res: GetBookingAttachmentsRawResponse) => {
  const attachments: BookingAttachment[] = res._embedded.document.map((attachment) => ({
    id: attachment.uuid,
    description: attachment.description,
    filename: attachment.filename,
    createdAt: attachment.created_at,
    permission: attachment.permission,
    link: attachment.link,
    uploader: { name: attachment.created_by.name, email: attachment.created_by.email },
  }));

  return attachments;
};

export const transformBookingHistoryLogs = (res: GetBookingHistoryLogsRawResponse) => {
  const logs: BookingHistoryLog[] = res._embedded.logs.map((log) => ({
    id: log.event_time.timestamp, //todo: maybe replace with proper one
    createdAt: log.event_time.timestamp,
    timezone: log.event_time.timezone,
    modifier: log.performed_by ? log.performed_by.name : undefined,
    event: {
      type: log.event.type,
      name: log.event.name,
    },
    field: log.field
      ? {
          name: log.field.name,
          from: log.field.from ?? undefined,
          to: log.field.to ?? undefined,
        }
      : undefined,
  }));

  return {
    data: {
      items: logs,
      page: {
        current: res.page,
        count: res.page_count,
        size: res.page_size,
        total: res.total_items,
      },
    },
  } satisfies GetBookingHistoryLogsResponse;
};

export const transformBookingHistoryActivity = (res: GetBookingHistoryActivityRawResponse) => {
  const activity: BookingHistoryActivity = {
    driverOnWay: res.on_way
      ? {
          timestamp: res.on_way.timestamp,
          timezone: res.on_way.timezone,
        }
      : undefined,
    pickedUp: res.picked_up
      ? {
          timestamp: res.picked_up.timestamp,
          timezone: res.picked_up.timezone,
        }
      : undefined,
    closeToDestination: res.close_to_destination
      ? {
          timestamp: res.close_to_destination.timestamp,
          timezone: res.close_to_destination.timezone,
        }
      : undefined,
    cancelled: res.cancellation
      ? {
          timestamp: res.cancellation.timestamp,
          timezone: res.cancellation.timezone,
        }
      : undefined,
    closed: res.closed
      ? {
          timestamp: res.closed.timestamp,
          timezone: res.closed.timezone,
        }
      : undefined,
    droppedOff: res.dropped_off
      ? {
          timestamp: res.dropped_off.timestamp,
          timezone: res.dropped_off.timezone,
        }
      : undefined,
  };

  return activity;
};

export const transformDriverAssignmentLogs = (res: GetDriverAssignmentLogsRawResponse) => {
  const getLogType = ({ field }: RawDriverAssignmentLog) => {
    if (field.name === "job.change-driver") return "reason";
    if (field.from && !field.to) return "removed";
    if (field.from && field.to) return "swapped";
    if (field.to && !field.from) return "added";
    return "reason";
  };

  const logs: BookingDriverAssignmentLog[] = res._embedded.logs.map((log) => ({
    id: log.event_time.timestamp,
    createdAt: log.event_time.timestamp,
    modifier: log.performed_by?.name || "",
    field: {
      name: log.field.name,
      from: log.field.from ?? "",
      to: log.field.to ?? "",
    },
    type: getLogType(log),
  }));

  return logs;
};

export const transformBookingPaymentsMeta = (res: GetBookingPaymentBreakdownRawResponse) => {
  const response: BookingPaymentMeta = {
    fundsReceived: res.metadata.funds_received,
    isFinalPaymentAvailable: res.metadata.is_final_payment_available,
    isPrepaymentAvailable: res.metadata.is_prepayment_available,
  };

  return response;
};

export const transformBookingPayments = (res: GetBookingPaymentsRawResponse) => {
  const response = res._embedded.payment.map(
    (payment) =>
      ({
        id: payment.uuid,
        createdAt: payment.created_at,
        amount: payment.amount,
        balance: payment.amount_remaining,
        description: payment.description,
        status: payment.status.toLowerCase(),
        isRefundable: payment.metadata.is_refundable,
        history: payment.attempts.map((attempt) => ({
          id: attempt.created_at,
          createdAt: attempt.created_at,
          description: attempt.description,
          category: attempt.category,
          reason: attempt.reason ?? "",
          paidTo: attempt.account?.name ?? "",
          amount: attempt.amount / 100,
        })),
      } as BookingPaymentLog)
  );

  return response;
};

export const transformBookingPaymentBreakdown = (res: GetBookingPaymentBreakdownRawResponse) => {
  const response = res.payouts.map(
    (payout) =>
      ({
        id: payout.created_at,
        createdAt: payout.created_at,
        amount: payout.amount,
        category: payout.type.toLowerCase(),
        transferType: payout.description,
        status: payout.status.toLowerCase(),
      } as BookingPaymentBreakdownLog)
  );

  return response;
};

export const transformBookingLedger = (res: GetBookingPaymentLedgerRawResponse) => {
  const response = res.ledger.map(
    (ledger) =>
      ({
        id: ledger.id.toString(),
        createdAt: ledger.created_at,
        amount: ledger.amount,
        category: ledger.category.toLowerCase(),
        type: ledger.type.toLowerCase(),
        description: ledger.description,
        fees: ledger.fee,
        isReturnable: ledger.metadata.is_return_available,
        isDisburseable: ledger.metadata.is_disburse_available,
      } as BookingPaymentLedgerLog)
  );

  return response;
};

export const transformBookingTripLog = (res: RawBookingTripLog) => {
  return camelcaseKeys(res, { deep: true });
};
