import { Button, Icon, Modal, Typography } from "@/components/atoms";
import { useOperator } from "@/hooks";

interface InvitationSentModalProps {
  email: string;
  open: boolean;
  onClose: () => void;
}

export const InvitationSentModal = ({ email, open, onClose }: InvitationSentModalProps) => {
  const { name: operatorName } = useOperator();

  return (
    <Modal open={open} onClose={onClose} className="!p-5">
      <div className="flex max-w-[550px] flex-col items-center justify-center pb-4 pt-12">
        <Icon className="flex-shrink-0 self-center text-success" name="TickCircle" variant="Bold" size={100} />
        <Typography variant="h1">Account Invitation Sent</Typography>
        <Typography className="mt-5 text-center">
          An email invitation with instructions has been sent to {email}. The driver can follow the steps in the email to sign up and join{" "}
          {operatorName}.
        </Typography>
        <Button className="mt-5 w-full" onClick={onClose} variant="primary" size="lg">
          Close
        </Button>
      </div>
    </Modal>
  );
};
