import { useState } from "react";
import { ErrorPanel, Skeleton } from "@/components/atoms";
import { getErrorMessages } from "@/helpers/reduxHelpers";
import { useGetOffloadDriversQuery } from "@/redux/apis/driver/driverApi";
import { useAppSelector } from "@/redux/hooks";
import { offloadDriversSelector } from "@/redux/slices/driver/selectors";
import { OffloadDriver } from "@/redux/slices/driver/types";
import { OffloadDriversTable } from "./OffloadDriversTable";
import { OffloadDriversModals } from "./components/OffloadDriversModals";
import { useGetOffloadDriversParams } from "./hooks/useGetOffloadDriversParams";
import { OffloadDriversStep } from "./types";

export const OffloadDriversPanel = () => {
  const { params } = useGetOffloadDriversParams();
  const { isFetching, error } = useGetOffloadDriversQuery(params, { refetchOnMountOrArgChange: true });
  const drivers = useAppSelector(offloadDriversSelector);
  const [selectedDriver, setSelectedDriver] = useState<OffloadDriver | undefined>(undefined);
  const [step, setStep] = useState<OffloadDriversStep>(undefined);

  if (error) return <ErrorPanel messages={getErrorMessages(error)} />;
  if (isFetching) return <Skeleton />;

  return (
    <>
      <OffloadDriversTable items={drivers} setDriver={setSelectedDriver} setStep={setStep} />
      <OffloadDriversTable.Pagination />
      <OffloadDriversModals step={step} setStep={setStep} driver={selectedDriver} setDriver={setSelectedDriver} />
    </>
  );
};
