import { zodResolver } from "@hookform/resolvers/zod";
import { useCallback, useMemo } from "react";
import { Controller, useForm } from "react-hook-form";
import { z } from "zod";
import { CheckboxInput, Icon, Typography } from "@/components/atoms";
import { FilterDrawer } from "../common/FilterDrawer";
import { FilterPopover } from "../common/FilterPopover";
import { useDriversFilters } from "../useDriversFilters";

const schema = z.object({
  rating: z.array(z.string()),
});

type FormData = z.infer<typeof schema>;

export const RatingFilter = () => {
  const {
    filters: { rating },
    setFilter,
  } = useDriversFilters();

  const value = useMemo(() => {
    if (!rating) return undefined;

    return rating.filter(Boolean).join(", ");
  }, [rating]);

  const onClose = () => setFilter("rating", undefined);

  return (
    <FilterPopover name="rating" value={value} label="Rating" onClose={onClose}>
      <RatingFilterForm />
    </FilterPopover>
  );
};

interface RatingFilterDrawerProps {
  open?: boolean;
  onOpenChange?: (open: boolean) => void;
}

export const RatingFilterDrawer = ({ open, onOpenChange }: RatingFilterDrawerProps) => {
  const {
    filters: { rating },
    setFilter,
  } = useDriversFilters();

  const value = useMemo(() => {
    if (!rating) return undefined;

    return rating.filter(Boolean).join(", ");
  }, [rating]);

  const onClose = () => setFilter("rating", undefined);

  return (
    <FilterDrawer open={open} onOpenChange={onOpenChange} value={value} label="Rating" onClose={onClose}>
      <RatingFilterForm />
    </FilterDrawer>
  );
};

const RatingFilterForm = () => {
  const ratings = ["5", "4", "3", "2", "1"].map(rating => ({ name: rating, value: rating }));
  const { setFilter, filters } = useDriversFilters();

  const { control, setValue } = useForm<FormData>({
    resolver: zodResolver(schema),
    defaultValues: { rating: (filters.rating as string[]) ?? [] },
  });

  const handleCheckAll = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      const { checked } = e.target;

      if (checked) {
        setFilter(
          "rating",
          ratings.map((rating) => rating.value)
        );
        setValue(
          "rating",
          ratings.map((rating) => rating.value)
        );
      } else {
        setFilter("rating", null);
        setValue("rating", []);
      }
    },
    [setFilter, setValue, ratings]
  );

  const isAllRatingsSelected = useMemo(() => {
    if (!filters.rating) return false;
    return filters.rating.length === ratings.length;
  }, [filters.rating, ratings]);

  return (
    <div className="relative contents">
      <Typography className="mb-4">Rating</Typography>
      <CheckboxInput label="All Ratings" onChange={handleCheckAll} variant="check" checked={isAllRatingsSelected} />
      <div className="mt-4 grid gap-4">
        <Controller
          control={control}
          name="rating"
          render={({ field }) => (
            <>
              {ratings.map((rating) => (
                <CheckboxInput
                  key={rating.value}
                  label={rating.name}
                  value={rating.value}
                  endIcon={<Icon name="Star1" className="fill-warning text-warning" />}
                  onChange={(e) => {
                    if (e.target.checked) {
                      const newRating = [...field.value, e.target.value];
                      field.onChange(newRating);
                      setFilter("rating", newRating.length > 0 ? newRating : null);
                    } else {
                      const newRating = field.value.filter((i) => i !== e.target.value);
                      field.onChange(newRating);
                      setFilter("rating", newRating.length > 0 ? newRating : null);
                    }
                  }}
                  variant="check"
                  checked={field.value.includes(rating.value)}
                />
              ))}
            </>
          )}
        />
      </div>
    </div>
  );
};
