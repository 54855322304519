export const modals = {
  exit: {
    title: "Discard Changes",
    description: "Changes will not be saved. Do you want to proceed?",
  },
  delete: {
    title: "Are you sure?",
    description: "This will delete this transfer type permanently. You cannot undo this action, do you wish to continue?",
  },
  error: {
    title: "Unable to do this action",
    description: "Unable to do this action because some error occured.",
  },
};
