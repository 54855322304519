import { Link, useLocation } from "react-router-dom";
import { Panel, Typography } from "@/components/atoms";
import { clsx } from "@/utils";
import { Documents } from "./fields/Documents";
import { AccountDetails } from "./sections/AccountDetails";
import { DriverDetails } from "./sections/DriverDetails";
import { DriverHeader } from "./sections/DriverHeader";
import { DriverStatus } from "./sections/DriverStatus";
import { EmergencyContact } from "./sections/EmergencyContact";

export const ApprovedDriverPanel = () => {
  return (
    <div className="flex gap-3">
      <Navigation />
      <Panel>
        <DriverHeader />
        <DriverStatus />
        <AccountDetails />
        <DriverDetails />
        <Documents />
        <EmergencyContact />
      </Panel>
    </div>
  );
};

const Navigation = () => {
  const { pathname } = useLocation();

  return (
    <div className="flex w-[280px] flex-col gap-1.5 p-2.5">
      <Link to="../profile">
        <Typography
          className={clsx(
            "w-full rounded-lg p-2.5 font-medium",
            pathname.includes("profile") ? "bg-primary-light text-primary" : "text-neutral-black"
          )}
        >
          Driver Profile
        </Typography>
      </Link>
      <Link to="../vehicles">
        <Typography
          className={clsx(
            "w-full rounded-lg p-2.5 font-medium",
            pathname.includes("vehicles") ? "bg-primary-light text-primary" : "text-neutral-black"
          )}
        >
          Owned & Assigned Vehicles
        </Typography>
      </Link>
      <Link to="../job-history">
        <Typography
          className={clsx(
            "w-full rounded-lg p-2.5 font-medium",
            pathname.includes("job-history") ? "bg-primary-light text-primary" : "text-neutral-black"
          )}
        >
          Job History
        </Typography>
      </Link>
      <Link to="../payout-history">
        <Typography
          className={clsx(
            "w-full rounded-lg p-2.5 font-medium",
            pathname.includes("payout-history") ? "bg-primary-light text-primary" : "text-neutral-black"
          )}
        >
          Payout History
        </Typography>
      </Link>
      <Link to="../admin">
        <Typography
          className={clsx(
            "w-full rounded-lg p-2.5 font-medium",
            pathname.includes("admin") ? "bg-primary-light text-primary" : "text-neutral-black"
          )}
        >
          Admin
        </Typography>
      </Link>
      <Link to="../history-logs">
        <Typography
          className={clsx(
            "w-full rounded-lg p-2.5 font-medium",
            pathname.includes("history-logs") ? "bg-primary-light text-primary" : "text-neutral-black"
          )}
        >
          History Logs
        </Typography>
      </Link>
    </div>
  );
};
