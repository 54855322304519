import { AnyAction, combineReducers } from "@reduxjs/toolkit";
import {
  accountApi,
  accreditationApi,
  adminApi,
  assetApi,
  brandingApi,
  authApi,
  clientApi,
  companyApi,
  linksAndDocumentsApi,
  termsAndConditionsApi,
  networkApi,
  operatorApi,
  pricingApi,
  qrApi,
  ratingApi,
  reportingApi,
  reportApi,
  documentApi,
  resourceApi,
  userAccountApi,
  configVehicleApi,
  recommendApi,
  profileApi,
  notificationApi,
  typeaheadApi,
  systemApi,
  bookingApi,
  driverApi,
  driverProfileApi,
  blockApi,
  externalSupplierApi,
  bookingOfferApi,
  paymentApi,
  payInvoiceApi,
  vehicleGuestApi,
  addOnApi,
  contentApi,
  jobRatingApi,
  paymentMethodApi,
  vehicleCategoryApi,
} from "./apis";
import { guestApi } from "./apis/guest/guestApi";
import { vehicleApi } from "./apis/vehicle/vehicleApi";
import {
  accountReducer,
  accreditationReducer,
  adminReducer,
  brandingAndAcquisitionReducer,
  addOnReducer,
  documentsReducer,
  authReducer,
  clientReducer,
  companyReducer,
  networkReducer,
  operatorReducer,
  pricingReducer,
  qrReducer,
  ratingReducer,
  resourceReducer,
  userAccountReducer,
  profileReducer,
  notificationReducer,
  bookingReducer,
  driverReducer,
  externalSupplierReducer,
  bookingOfferReducer,
  paymentReducer,
  configReducer,
  payInvoiceReducer,
  vehicleGuestReducer,
  tripReportingReducer,
} from "./slices";

const apiReducers = {
  [authApi.reducerPath]: authApi.reducer,
  [accountApi.reducerPath]: accountApi.reducer,
  [operatorApi.reducerPath]: operatorApi.reducer,
  [pricingApi.reducerPath]: pricingApi.reducer,
  [adminApi.reducerPath]: adminApi.reducer,
  [configVehicleApi.reducerPath]: configVehicleApi.reducer,
  [assetApi.reducerPath]: assetApi.reducer,
  [brandingApi.reducerPath]: brandingApi.reducer,
  [linksAndDocumentsApi.reducerPath]: linksAndDocumentsApi.reducer,
  [termsAndConditionsApi.reducerPath]: termsAndConditionsApi.reducer,
  [qrApi.reducerPath]: qrApi.reducer,
  [accreditationApi.reducerPath]: accreditationApi.reducer,
  [resourceApi.reducerPath]: resourceApi.reducer,
  [userAccountApi.reducerPath]: userAccountApi.reducer,
  [ratingApi.reducerPath]: ratingApi.reducer,
  [companyApi.reducerPath]: companyApi.reducer,
  [reportingApi.reducerPath]: reportingApi.reducer,
  [reportApi.reducerPath]: reportApi.reducer,
  [documentApi.reducerPath]: documentApi.reducer,
  [networkApi.reducerPath]: networkApi.reducer,
  [clientApi.reducerPath]: clientApi.reducer,
  [recommendApi.reducerPath]: recommendApi.reducer,
  [profileApi.reducerPath]: profileApi.reducer,
  [notificationApi.reducerPath]: notificationApi.reducer,
  [typeaheadApi.reducerPath]: typeaheadApi.reducer,
  [systemApi.reducerPath]: systemApi.reducer,
  [bookingApi.reducerPath]: bookingApi.reducer,
  [driverApi.reducerPath]: driverApi.reducer,
  [driverProfileApi.reducerPath]: driverProfileApi.reducer,
  [blockApi.reducerPath]: blockApi.reducer,
  [externalSupplierApi.reducerPath]: externalSupplierApi.reducer,
  [bookingOfferApi.reducerPath]: bookingOfferApi.reducer,
  [paymentApi.reducerPath]: paymentApi.reducer,
  [payInvoiceApi.reducerPath]: payInvoiceApi.reducer,
  [vehicleGuestApi.reducerPath]: vehicleGuestApi.reducer,
  [addOnApi.reducerPath]: addOnApi.reducer,
  [guestApi.reducerPath]: guestApi.reducer,
  [vehicleApi.reducerPath]: vehicleApi.reducer,
  [contentApi.reducerPath]: contentApi.reducer,
  [jobRatingApi.reducerPath]: jobRatingApi.reducer,
  [paymentMethodApi.reducerPath]: paymentMethodApi.reducer,
  [vehicleCategoryApi.reducerPath]: vehicleCategoryApi.reducer,
};

const appReducer = combineReducers({
  ...apiReducers,
  auth: authReducer,
  account: accountReducer,
  operator: operatorReducer,
  pricing: pricingReducer,
  admin: adminReducer,
  brandingAndAcquisition: brandingAndAcquisitionReducer,
  addOn: addOnReducer,
  documents: documentsReducer,
  qr: qrReducer,
  accreditation: accreditationReducer,
  resource: resourceReducer,
  userAccount: userAccountReducer,
  rating: ratingReducer,
  company: companyReducer,
  network: networkReducer,
  client: clientReducer,
  profile: profileReducer,
  notification: notificationReducer,
  booking: bookingReducer,
  driver: driverReducer,
  externalSupplier: externalSupplierReducer,
  bookingOffer: bookingOfferReducer,
  payInvoice: payInvoiceReducer,
  payment: paymentReducer,
  config: configReducer,
  vehicleGuest: vehicleGuestReducer,
  tripReporting: tripReportingReducer,
});

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const rootReducer: (state: any, action: AnyAction) => ReturnType<typeof appReducer> = (state, action) => {
  switch (action.type) {
    case "auth/logOut": {
      const { config } = state;
      state = { config };
      return appReducer(state, action);
    }

    case "operator/switchOperator": {
      // Reset all state except non operator specific states
      const { config, auth, authApi, profile, profileApi, account, accountApi, operator, operatorApi, typeaheadApi } = state;
      state = { config, auth, authApi, profile, profileApi, account, accountApi, operator, operatorApi, typeaheadApi };
      return appReducer(state, action);
    }

    default:
      return appReducer(state, action);
  }
};

export default rootReducer;
