import { Divider, Icon, Tooltip, Typography } from "@/components/atoms";
import { useAppSelector } from "@/redux/hooks";
import { bookingsPageStateSelector } from "@/redux/slices/booking/selectors";
import { clsx } from "@/utils";
import { useIsOperatorOwnJob } from "../../../hooks/useIsOperatorOwnJob";
import { useSelectedBooking } from "../../../hooks/useSelectedBooking";
import { AssignedVehicle } from "./AssignedVehicle";
import { Driver } from "./Driver";
import { TransferDetails } from "./TransferDetails";

export const DriverVehicle = () => {
  const {
    metadata: { isOffloaded },
  } = useSelectedBooking();
  const isOwnJob = useIsOperatorOwnJob();
  const { inlineEditing } = useAppSelector(bookingsPageStateSelector);

  const className = clsx("relative max-xl:!mt-2.5 ", {
    "!opacity-100 !pointer-events-auto": inlineEditing === "change-driver" || inlineEditing === "change-vehicle",
  });

  return (
    <div className={className}>
      <Icon name="Car" variant="Bold" size="lg" className="absolute -inset-1.5 z-10 rounded-full bg-primary p-1 text-white" />
      <Driver />
      {(!isOffloaded || isOwnJob) && (
        <>
          <Divider className="my-4" />
          <Vehicle />
        </>
      )}
      <Divider className="my-4" />
      <TransferDetails />
    </div>
  );
};

const Vehicle = () => {
  const {
    driver,
    assignedVehicle,
    metadata: { isVehicleCorrect },
  } = useSelectedBooking();

  const renderCurrentVehicle = () => {
    if (!driver?.vehicle)
      return (
        <div className="flex flex-col gap-1.5 2xl:flex-row 2xl:gap-3">
          <Typography variant="small" className="min-w-[105px] shrink-0 text-neutral-black/70">
            Current Vehicle:
          </Typography>
          <Typography variant="small" className="flex-1">
            -
          </Typography>
        </div>
      );

    const { vehicle } = driver;
    const showWarning = assignedVehicle && driver?.vehicle ? assignedVehicle.uuid !== driver.vehicle.uuid : !isVehicleCorrect;

    return (
      <div className="flex flex-col gap-1.5 2xl:flex-row 2xl:gap-3 ">
        <Typography variant="small" className="min-w-[105px] shrink-0 text-neutral-black/70">
          Current Vehicle:
        </Typography>
        <Typography variant="small" className="flex flex-1 items-center gap-2">
          {vehicle
            ? `${vehicle.model.make} - ${vehicle.model.model}` + `${vehicle.registrationNumber ? ` (${vehicle.registrationNumber})` : ""}`
            : "-"}
          <Tooltip content={`Color: ${vehicle.color}`} placement="right">
            <i className="-my-1 inline-block h-4 w-4 shrink-0 rounded-full border" style={{ backgroundColor: vehicle.color }} />
          </Tooltip>
          {showWarning && (
            <Tooltip content="Driver is not in the correct vehicle" placement="bottom">
              <Icon name="Danger" variant="Bold" size="sm" className="inline text-warning " />
            </Tooltip>
          )}
        </Typography>
      </div>
    );
  };

  return (
    <div className="space-y-3">
      {renderCurrentVehicle()}
      <AssignedVehicle />
    </div>
  );
};
