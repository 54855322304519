import { useNavigate } from "react-router-dom";
import { PageHeader } from "../../../PageHeader/PageHeader";
import { PendingStatuses } from "../MyDrivers/types";
import { ApprovedDriverPanel } from "./ApprovedDriver/ApprovedDriverPanel";
import { PendingDriverPanel } from "./PendingDriver/PendingDriverPanel";
import { ManageDriverProvider, useManageDriverContext } from "./context";
import { ApproveDriverModal } from "./modals/ApproveDriverModal";
import { ChangePasswordModal } from "./modals/ChangePasswordModal";
import { DriverLeftModal } from "./modals/DriverLeftModal";
import { RemoveDriverModal } from "./modals/RemoveDriverModal";
import { SaveChangesModal } from "./modals/SaveChangesFirstModal";

export const ManageDriverPanel = () => {
  const navigate = useNavigate();

  return (
    <ManageDriverProvider>
      <PageHeader.Actions onBack={() => navigate("../../")} />
      <Content />
    </ManageDriverProvider>
  );
};

const Content = () => {
  const { driver } = useManageDriverContext();
  const isPendingApproval = PendingStatuses.includes(driver.status);

  if (isPendingApproval) {
    return (
      <>
        <PendingDriverPanel />
        <ManageDriverModals />
      </>
    );
  }

  return (
    <>
      <ApprovedDriverPanel />
      <ManageDriverModals />
    </>
  );
};

const ManageDriverModals = () => {
  const { activeModal, setActiveModal } = useManageDriverContext();

  return (
    <>
      <DriverLeftModal open={activeModal === "driver-left"} onClose={() => setActiveModal(undefined)} />
      <RemoveDriverModal open={activeModal === "remove-driver"} onClose={() => setActiveModal(undefined)} />
      <ChangePasswordModal open={activeModal === "change-password"} onClose={() => setActiveModal(undefined)} />
      <ApproveDriverModal open={activeModal === "approve-driver"} onClose={() => setActiveModal(undefined)} />
      <SaveChangesModal open={activeModal === "save-changes"} onClose={() => setActiveModal(undefined)} />
    </>
  );
};
