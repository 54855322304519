import { DataTableColumnDef } from "@/components/molecules";
import { BlockedOffloadDriversTableData, OffloadDriversTableData } from "./types";

export const offloadDriversColumns: DataTableColumnDef<OffloadDriversTableData>[] = [
  { accessorKey: "name", header: "Name", cell: (props) => props.row.original.name, id: "name", size: 300 },
  {
    accessorKey: "state",
    header: "State",
    cell: (props) => props.row.original.state,
    id: "state",
  },
  {
    accessorKey: "rating",
    header: "Rating",
    cell: (props) => props.row.original.rating,
    id: "rating",
  },
  {
    accessorKey: "totalJobs",
    header: "Total Jobs",
    cell: (props) => props.row.original.totalJobs,
    enableSorting: false,
  },
  {
    accessorKey: "operator",
    header: "Operator",
    cell: (props) => props.row.original.operator,
    enableSorting: false,
    size: 250,
  },
  {
    accessorKey: "operatorContact",
    header: "Operator Contact No.",
    cell: (props) => props.row.original.operatorContact,
    enableSorting: false,
    size: 250,
  },
  {
    accessorKey: "action",
    header: "Action",
    cell: (props) => props.row.original.action,
    size: 75,
    enableSorting: false,
  },
];

export const blockedOffloadDriversColumns: DataTableColumnDef<BlockedOffloadDriversTableData>[] = [
  { accessorKey: "name", header: "Name", cell: (props) => props.row.original.name, id: "name", size: 325 },
  {
    accessorKey: "state",
    header: "State",
    cell: (props) => props.row.original.state,
    id: "state",
  },
  {
    accessorKey: "rating",
    header: "Rating",
    cell: (props) => props.row.original.rating,
    id: "rating",
  },
  {
    accessorKey: "totalJobs",
    header: "Total Jobs",
    cell: (props) => props.row.original.totalJobs,
    enableSorting: false,
  },
  {
    accessorKey: "operator",
    header: "Operator",
    cell: (props) => props.row.original.operator,
    enableSorting: false,
    size: 250,
  },
  {
    accessorKey: "operatorContact",
    header: "Operator Contact No.",
    cell: (props) => props.row.original.operatorContact,
    enableSorting: false,
    size: 250,
  },
  {
    accessorKey: "reasonForBlocking",
    header: "Reason for Blocking",
    cell: (props) => props.row.original.reasonForBlocking,
    enableSorting: false,
    size: 250,
  },
];
