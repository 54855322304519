import { useLocalStorageValue } from "@react-hookz/web";
import { createContext, useCallback, useContext, useEffect, useMemo, useState } from "react";
import { ArrayParam, DecodedValueMap, useQueryParams } from "use-query-params";
import { useOperator, usePagination } from "@/hooks";
import { GetDriversFilterParams, DriversFilters } from "./types";

export type DriversFilterKey = keyof Omit<DriversFilters, "search">;
export type DriversFilterValue = string | boolean | (string | null)[] | null | undefined;
export type DriversDecodedFilters = DecodedValueMap<typeof driversFilterParams>;

const DEFAULT_FILTERS: Record<DriversFilterKey, undefined> = {
  rating: undefined,
  state: undefined,
  tier: undefined,
  status: undefined,
};

const driversFilterParams = {
  rating: ArrayParam,
  state: ArrayParam,
  tier: ArrayParam,
  status: ArrayParam,
};

interface IDriversFiltersContext {
  params: GetDriversFilterParams;
  sortedFilters: [DriversFilterKey, DriversFilterValue][];
  filters: DriversDecodedFilters;
  setFilter: (key: DriversFilterKey, value?: DriversFilterValue) => void;
  setFilters: (newFilters: Record<DriversFilterKey, DriversFilterValue>) => void;
  clearFilters: () => void;
  resetFilters: () => void;
}

const DriversFiltersContext = createContext<IDriversFiltersContext | null>(null);

export const DriversFiltersProvider = ({ children }: React.PropsWithChildren) => {
  const { id: operatorId } = useOperator();
  const { clearPagination } = usePagination("my-drivers");
  const [filters, setFilters] = useQueryParams(driversFilterParams);
  const { set: setPersistedFilters, value: persistedFilters } = useLocalStorageValue<DriversFilters>(`${operatorId}:drivers-filter`, {
    defaultValue: DEFAULT_FILTERS,
  });
  const [filterSorting, setFilterSorting] = useState<DriversFilterKey[]>([]);

  useEffect(() => {
    if (!persistedFilters || !!filters) return;
    setFilters(persistedFilters);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const sortedFilters = useMemo(() => {
    const mergedFilters = Object.keys(filters).reduce((acc, key) => {
      if (!filterSorting.includes(key as DriversFilterKey) && filters[key as DriversFilterKey] !== undefined) {
        acc.push(key as DriversFilterKey);
      }
      return acc;
    }, filterSorting);

    return (mergedFilters || []).map((key) => [key, filters[key]] as const);
  }, [filterSorting, filters]);

  const params: GetDriversFilterParams = useMemo(
    () => ({
      rating: filters.rating ? (filters.rating.filter(Boolean) as string[]) : undefined,
      state: filters.state ? (filters.state.filter(Boolean) as string[]) : undefined,
      tier: filters.tier ? (filters.tier.filter(Boolean) as string[]) : undefined,
      account_status: filters.status ? (filters.status.filter(Boolean) as string[]) : undefined,
    }),
    [filters]
  );

  const clearFilters = useCallback(() => {
    clearPagination();
    const clearedFilters = Object.entries(filters)
      .filter(([_, value]) => value !== undefined)
      .reduce((acc, [key]) => {
        acc[key as DriversFilterKey] = null;
        return acc;
      }, {} as DriversFilters);

    setFilters(clearedFilters);
    setPersistedFilters(clearedFilters);
  }, [clearPagination, filters, setFilters, setPersistedFilters]);

  const resetFilters = useCallback(() => {
    clearPagination();
    setFilterSorting([]);
    setFilters(DEFAULT_FILTERS);
    setPersistedFilters(DEFAULT_FILTERS);
  }, [clearPagination, setFilterSorting, setFilters, setPersistedFilters]);

  const setFilter = useCallback(
    (key: DriversFilterKey, value?: DriversFilterValue) => {
      if (value) {
        clearPagination();
      }

      setFilters((prev) => ({ ...prev, [key]: value }));
      setPersistedFilters((prev) => ({ ...prev, [key]: value }));
      setFilterSorting((prev = []) => {
        if (value === undefined) {
          return prev.filter((i) => i !== key);
        }
        return prev.includes(key) ? prev : [...prev, key];
      });
    },
    [setFilters, setPersistedFilters, setFilterSorting, clearPagination]
  );

  const setAllFilters = useCallback(
    (newFilters: Record<DriversFilterKey, DriversFilterValue>) => {
      clearPagination();
      setFilters(newFilters as DriversFilters);
      setPersistedFilters(newFilters as DriversFilters);
      setFilterSorting(Object.keys(newFilters) as DriversFilterKey[]);
    },
    [setFilters, setPersistedFilters, setFilterSorting, clearPagination]
  );

  const value = {
    params,
    sortedFilters: sortedFilters as [DriversFilterKey, DriversFilterValue][],
    filters,
    setFilter,
    setFilters: setAllFilters,
    clearFilters,
    resetFilters,
  };

  return <DriversFiltersContext.Provider value={value}>{children}</DriversFiltersContext.Provider>;
};

export const useDriversFilters = () => {
  const context = useContext(DriversFiltersContext);
  if (!context) {
    throw new Error("useDriversFilters must be used within a DriversFiltersProvider");
  }

  return context;
};
