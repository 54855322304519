import { Icon } from "@/components/atoms";
import { Tabs } from "@/components/molecules";
import { PageHeader, VehicleCategoriesPanel } from "@/components/organisms";
import { useGetVehicleCategoriesParams } from "@/components/organisms/VehicleCategories/VehicleCategories/hooks/useGetVehicleCategoriesParams";
import { useOperator } from "@/hooks";
import { useGetVehicleCategoriesQuery } from "@/redux/apis/config/pricing/vehicleCategory/vehicleCategoryApi";
import { VehicleCategoriesAdd } from "./VehicleCategoriesAdd";
import { VehicleCategoriesEdit } from "./VehicleCategoriesEdit";

export const VehicleCategories = () => {
  return (
    <>
      <PageHeader.Actions />
      <Nav />
      <VehicleCategoriesPanel />
    </>
  );
};

export const Nav = () => {
  const { id: operatorId } = useOperator();
  const { params } = useGetVehicleCategoriesParams();
  const { data } = useGetVehicleCategoriesQuery(params);

  const pageTabs = [
    {
      name: "Vehicle Categories",
      to: `/operator/${operatorId}/config/pricing/vehicle-categories`,
    },
    {
      name: "Transfer Types",
      to: `/operator/${operatorId}/config/pricing/transfer-types`,
      isLocked: data ? data.page.total < 1 : false,
    },
    {
      name: "Extras",
      to: `/operator/${operatorId}/config/pricing/fixed-cost-extras`,
    },
    {
      name: "Vehicle Manager",
      to: `/operator/${operatorId}/vehicles`,
      icon: <Icon name="ExportSquare" size="md" className="ml-2" />,
    },
  ];

  return <Tabs className="no-scrollbar min-w-0 border-b border-neutral-mid-gray" tabs={pageTabs} />;
};

VehicleCategories.Edit = VehicleCategoriesEdit;
VehicleCategories.Add = VehicleCategoriesAdd;
