import { useMemo } from "react";
import { DataTable, Pagination, NoSearchResults } from "@/components/molecules";
import { usePagination } from "@/hooks";
import { useAppSelector } from "@/redux/hooks";
import { offloadDriversPageSelector } from "@/redux/slices/driver/selectors";
import { OffloadDriver } from "@/redux/slices/driver/types";
import { OffloadDriversEmptyState } from "./components/OffloadDriversEmptyState";
import { blockedOffloadDriversColumns } from "./fixtures";
import { generateBlockedOffloadDriversData } from "./helpers";
import { useGetOffloadDriversParams } from "./hooks/useGetOffloadDriversParams";
import { BlockedOffloadDriversStep } from "./types";

interface BlockedOffloadDriversTableProps {
  items: OffloadDriver[];
  setDriver: (driver?: OffloadDriver) => void;
  setStep: (step: BlockedOffloadDriversStep) => void;
}

export const BlockedOffloadDriversTable = ({ items, setDriver, setStep }: BlockedOffloadDriversTableProps) => {
  const { sorting, setSorting, search, filters } = useGetOffloadDriversParams();
  const isFiltersEmpty = Object.values(filters).every((el) => el === undefined);

  const onViewReason = (driver: OffloadDriver) => {
    setDriver(driver);
    setStep("view-reason");
  };

  const renderEmptyState = () => {
    if (search) return <NoSearchResults />;
    if (!isFiltersEmpty) return <OffloadDriversEmptyState hasFilters />;

    return <OffloadDriversEmptyState />;
  };

  const data = useMemo(
    () => generateBlockedOffloadDriversData(items, onViewReason),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [items]
  );

  return (
    <div className="relative">
      <DataTable
        className="w-full overflow-x-auto [&_table]:table-auto [&_tr]:bg-white"
        data={data}
        columns={blockedOffloadDriversColumns}
        sorting={sorting}
        setSorting={setSorting}
        emptyState={renderEmptyState()}
      />
    </div>
  );
};

const BlockedOffloadDriversTablePagination = () => {
  const { current, size, total, count } = useAppSelector(offloadDriversPageSelector);
  const { handlePageClick, handleSizeChange } = usePagination("blocked-offload-drivers");

  return (
    <Pagination
      onPageClick={handlePageClick}
      onSizeChange={handleSizeChange}
      currentPage={current}
      pageSize={size}
      totalItems={total}
      pageCount={count}
    />
  );
};

BlockedOffloadDriversTable.Pagination = BlockedOffloadDriversTablePagination;
