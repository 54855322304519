import { useMemo } from "react";
import { useNavigate } from "react-router-dom";
import { useAppAbility } from "@/casl";
import { DataTable, Pagination, NoSearchResults } from "@/components/molecules";
import { usePagination, useSorting } from "@/hooks";
import { useAppDispatch, useAppSelector } from "@/redux/hooks";
import { setCompanyAction, setSelectedCompany } from "@/redux/slices/company/companySlice";
import { companiesPageSelector } from "@/redux/slices/company/selectors";
import { Company } from "@/redux/slices/company/types";
import { clsx } from "@/utils";
import { generateCompanyColumns, generateCompanyData } from "../helpers";
import { CompaniesEmptyState } from "../emptyStates/CompaniesEmptyState";

interface CompaniesTableProps extends React.HTMLAttributes<HTMLDivElement> {
  items: Company[];
  isEmpty: boolean;
}

export const CompaniesTable = ({ items, isEmpty, className, ...props }: CompaniesTableProps) => {
  const ability = useAppAbility();
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const { sorting, setSorting } = useSorting();

  const onViewClick = (company: Company) => navigate(`${company.id}`);
  const onRemoveClick = (company: Company) => {
    dispatch(setSelectedCompany(company));
    dispatch(setCompanyAction("removeCompany"));
  };

  const data = useMemo(
    () => generateCompanyData(items, ability.can("manage", "providerCompany"), onViewClick, onRemoveClick),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [items]
  );
  const columns = useMemo(() => generateCompanyColumns(), []);

  return (
    <div className={clsx("relative flex bg-white", className)}>
      <DataTable 
        className="[&_table]:table-auto overflow-x-auto w-full"
        columns={columns} 
        data={data} 
        sorting={sorting} 
        setSorting={setSorting} 
        emptyState={isEmpty ? <CompaniesEmptyState /> : <NoSearchResults />} 
        {...props} 
      />
    </div>
  );
};

const CompaniesTablePagination = () => {
  const { current, count, total, size } = useAppSelector(companiesPageSelector);
  const { handlePageClick, handleSizeChange } = usePagination("companies");

  return (
    <Pagination
      onPageClick={handlePageClick}
      onSizeChange={handleSizeChange}
      currentPage={current}
      pageSize={size}
      totalItems={total}
      pageCount={count}
    />
  );
};

CompaniesTable.Pagination = CompaniesTablePagination;
