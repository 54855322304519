import { pricingApi } from "../pricingApi";
import { transformCostExtras } from "./helpers";
import {
  AddCostExtraParams,
  AddCostExtraResponse,
  DeleteCostExtraResponse,
  EditCostExtraParams,
  EditCostExtraResponse,
  GetCostExtrasParams,
  GetCostExtrasResponse,
  ToggleCostExtraParams,
  ToggleCostExtraResponse,
  ReorderCostExtraResponse,
} from "./types";

export const fixedCostExtrasApi = pricingApi.enhanceEndpoints({ addTagTypes: ["FixedCostExtra"] }).injectEndpoints({
  endpoints: (builder) => ({
    getFixedCostExtras: builder.query<GetCostExtrasResponse, GetCostExtrasParams>({
      query: ({ page, pageSize }) => {
        let queryString = "/fixed-cost-extras?";
        if (page) queryString = `${queryString}&page=${page}`;
        if (pageSize) queryString = `${queryString}&page_size=${pageSize}`;
        return queryString;
      },
      transformResponse: transformCostExtras,
      providesTags: ["FixedCostExtra"],
    }),
    addCostExtra: builder.mutation<AddCostExtraResponse, AddCostExtraParams>({
      query: (body) => ({
        url: `/fixed-cost-extras`,
        method: "POST",
        body,
      }),
      invalidatesTags: ["FixedCostExtra"],
    }),
    editCostExtra: builder.mutation<EditCostExtraResponse, EditCostExtraParams>({
      query: ({ uuid, ...put }) => ({
        url: `/fixed-cost-extras/${uuid}`,
        method: "PUT",
        body: put,
      }),
      invalidatesTags: ["FixedCostExtra"],
    }),
    deleteCostExtra: builder.mutation<DeleteCostExtraResponse, string>({
      query: (uuid) => ({
        url: `/fixed-cost-extras/${uuid}`,
        method: "DELETE",
      }),
      invalidatesTags: ["FixedCostExtra"],
    }),
    toggleCostExtra: builder.mutation<ToggleCostExtraResponse, ToggleCostExtraParams>({
      query: ({ uuid, ...patch }) => ({
        url: `/fixed-cost-extras/${uuid}`,
        method: "PATCH",
        body: patch,
      }),
    }),
    reorderCostExtra: builder.mutation<ReorderCostExtraResponse, Record<string, number>>({
      query: (body) => ({
        url: "/fixed-cost-extras/reorder",
        method: "POST",
        body,
      }),
    }),
  }),
});

export const {
  useGetFixedCostExtrasQuery,
  useLazyGetFixedCostExtrasQuery,
  useToggleCostExtraMutation,
  useDeleteCostExtraMutation,
  useAddCostExtraMutation,
  useEditCostExtraMutation,
  useReorderCostExtraMutation,
  endpoints: { getFixedCostExtras },
} = fixedCostExtrasApi;
