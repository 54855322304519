import { Avatar, DropdownMenu, Icon, IconButton, Typography } from "@/components/atoms";
import { getAssetUrl } from "@/utils";
import { DriverStatusBadge } from "../../../common/DriverStatusBadge";
import { useManageDriverContext } from "../../context";

export const DriverHeader = () => {
  const { driver, setActiveModal } = useManageDriverContext();
  const { personal, rating, status } = driver;
  const driverName = `${personal.firstName} ${personal.lastName}`;

  const renderAvatar = () => {
    const src = getAssetUrl(driver.avatar || "");
    if (driver.isFleetLeader)
      return (
        <div className="h-[72px] w-[72px] flex-shrink-0 rounded-full ring-4 ring-success ring-offset-4">
          <img className="flex-shrink-0 rounded-full" src={src} alt={driverName} />
        </div>
      );

    return <Avatar size="xl" src={src} alt={driverName} />;
  };

  const renderRating = () => {
    const { jobsCompleted, rating: driverRating } = rating;
    if (jobsCompleted) {
      return (
        <div className="flex flex-row items-center gap-1.5">
          {(driverRating || driverRating === 0) && (
            <div className="flex flex-row items-center gap-x-2">
              <Icon name="Star1" className="fill-warning text-warning" />
              <Typography variant="title">{driverRating}</Typography>
            </div>
          )}
          <Typography variant="action" className="text-neutral-dark-gray">
            {`(${jobsCompleted} ${jobsCompleted === 1 ? "Job" : "Jobs"})`}
          </Typography>
        </div>
      );
    }
  };

  return (
    <div className="flex flex-row items-center gap-6">
      {renderAvatar()}
      <div className="flex w-full flex-row">
        <div className="flex flex-1 flex-col gap-1">
          <Typography variant="h3" className="flex items-center gap-2">
            {driverName}
            <DriverStatusBadge status="active" />
          </Typography>
          {renderRating()}
        </div>
        <div className="flex flex-row items-center gap-3">
          <Typography variant="paragraph" className="text-neutral-dark-gray">{`Driver ID: ${driver.shareCode}`}</Typography>
          {status !== "removed" && (
            <DropdownMenu button={<IconButton isCustomIcon iconName="options" size="sm" variant="tertiary" />} position="bottom-end">
              <DropdownMenu.Item onClick={() => setActiveModal("remove-driver")}>
                <Icon name="Trash" className="mr-2.5 text-danger" size="sm" />
                <Typography variant="paragraph">Remove Driver</Typography>
              </DropdownMenu.Item>
              {status !== "left" && (
                <DropdownMenu.Item onClick={() => setActiveModal("driver-left")}>
                  <Icon name="LogoutCurve" className="mr-2.5 text-danger" size="sm" />
                  <Typography variant="paragraph">Driver Left</Typography>
                </DropdownMenu.Item>
              )}
            </DropdownMenu>
          )}
        </div>
      </div>
    </div>
  );
};
