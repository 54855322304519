import { useAppAbility } from "@/casl";
import { AppAction, AppSubject } from "@/casl/types";
import { Panel, Typography } from "@/components/atoms";
import { PageList } from "@/components/molecules";
import { CancellationFees } from "./CancellationFees/CancellationFees";
import { FixedCostExtras } from "./FixedCostExtras/FixedCostExtras";
import { HourlyDailyHire } from "./HourlyDailyHire/HourlyDailyHire";
import { TimeAndDay } from "./TimeAndDay/TimeAndDay";
import { TransferTypes } from "./TransferTypes/TransferTypes";
import { TripCost } from "./TripCost/TripCost";
import { UnscheduledStops } from "./UnscheduledStops/UnscheduledStops";
import { VehicleCategories } from "./VehicleCategories/VehicleCategories";

type Subpage = {
  name: string;
  desc: string;
  to: string;
  icon: string;
  permission: [AppAction, AppSubject];
};

const subpages: Subpage[] = [
  {
    name: "Trip Cost",
    desc: "Standard trip pricing configuration.",
    to: "trip-cost",
    icon: "DollarCircle",
    permission: ["manage", "providerConfigPricing"],
  },
  {
    name: "Transfer & Vehicle Options",
    desc: "Transfer & Vehicle Options configurations.",
    to: "vehicle-categories",
    icon: "Car",
    permission: ["manage", "providerConfigVehicle"],
  },
  {
    name: "Time & Day (Multiplier)",
    desc: "Configure cost multipliers depending on the time & day.",
    to: "time-and-day",
    icon: "Clock",
    permission: ["manage", "providerConfigPricing"],
  },
  {
    name: "Hourly - Daily Hire",
    desc: "Hourly - Daily trip pricing configuration.",
    to: "hourly-daily-hire",
    icon: "Timer1",
    permission: ["manage", "providerConfigPricing"],
  },
  {
    name: "Unscheduled Stops",
    desc: "Configure unscheduled stops.",
    to: "unscheduled-stops",
    icon: "Danger",
    permission: ["manage", "providerConfigPricing"],
  },
  {
    name: "Cancellation Fees",
    desc: "Configure cancellation policies.",
    to: "cancellation-fees",
    icon: "MoneyRemove",
    permission: ["manage", "providerConfigPricing"],
  },
];

export const Pricing = () => {
  const ability = useAppAbility();

  return (
    <Panel>
      <Typography variant="h3">Pricing Setings</Typography>
      <PageList className="-mx-5 mt-2">
        {subpages.map(({ name, desc, to, icon, permission }) => (
          <PageList.Item
            key={name}
            title={name}
            description={desc}
            to={to}
            icon={icon}
            locked={!ability.can(permission[0], permission[1])}
          />
        ))}
      </PageList>
    </Panel>
  );
};

Pricing.TripCost = TripCost;
Pricing.FixedCostExtras = FixedCostExtras;
Pricing.VehicleCategories = VehicleCategories;
Pricing.TimeAndDay = TimeAndDay;
Pricing.TransferTypes = TransferTypes;
Pricing.CancellationFees = CancellationFees;
Pricing.UnscheduledStops = UnscheduledStops;
Pricing.HourlyDailyHire = HourlyDailyHire;
