import { useMemo, useState } from "react";
import { BasicTable, Button, IconButton, Loading, Modal, Typography } from "@/components/atoms";
import { getVehicleTypeLabel } from "@/helpers/vehicleHelpers";
import { useGetAllVehicleMakeAndModelsQuery } from "@/redux/apis/vehicleGuest/vehicleGuestApi";
import { clsx } from "@/utils";

interface LookForYourVehicleProps {
  open: boolean;
  onClose: () => void;
}

export const LookForYourVehicle = ({ open, onClose }: LookForYourVehicleProps) => {
  const [activeMake, setActiveMake] = useState<string | undefined>(undefined);
  const { data, isFetching } = useGetAllVehicleMakeAndModelsQuery({});

  const makes = useMemo(() => {
    if (!data) return [];
    return Object.entries(data).map(([key, value]) => ({ key, name: value.name }));
  }, [data]);

  const models = useMemo(() => {
    if (!data || !activeMake) return [];
    return data[activeMake] ? [...data[activeMake].models].sort((a, b) => a.model.localeCompare(b.model)) : [];
  }, [data, activeMake]);

  return (
    <Modal open={open} onClose={onClose} className="w-[550px] !px-5">
      <header className="flex">
        <Typography variant="title" className="flex-1">
          Look for your vehicle
        </Typography>
        <IconButton iconName="close" iconSize="lg" isCustomIcon variant="custom" onClick={onClose} />
      </header>
      <section className="mt-2">
        <div className="flex w-full  gap-3 ">
          {isFetching ? (
            <Loading />
          ) : (
            <>
              <div className="grid w-[175px] max-w-[33%] overflow-hidden rounded-lg border border-neutral-mid-gray">
                <header className="flex h-[45px] items-center gap-3  bg-neutral-gray px-2 pb-0 pt-3">
                  <Typography variant="action">Vehicle Make</Typography>
                </header>
                <div className={`grid max-h-[250px] gap-2 overflow-auto p-2 ${isFetching ? "pointer-events-none opacity-50" : ""}`}>
                  {makes.map((make, i) => (
                    <VehicleMakeOption
                      key={i}
                      make={make.name}
                      onChange={() => setActiveMake(make.key)}
                      isActive={activeMake === make.key}
                    />
                  ))}
                </div>
              </div>
              <div className="flex max-w-[66%] flex-1 flex-col  self-stretch  overflow-auto rounded-lg border border-neutral-mid-gray">
                <BasicTable.Row className="flex h-[45px] items-center bg-neutral-gray pb-0 pt-3 [&>th]:bg-neutral-gray [&>th]:px-0 [&>th]:py-0">
                  <BasicTable.HeaderCell className="w-[120px]">
                    <Typography variant="action" className="pl-4">
                      Model
                    </Typography>
                  </BasicTable.HeaderCell>
                  <BasicTable.HeaderCell className="w-[100px]">
                    <Typography variant="action">Class</Typography>
                  </BasicTable.HeaderCell>
                  <BasicTable.HeaderCell className="w-[100px]">
                    <Typography variant="action" className="pl-2">
                      Type
                    </Typography>
                  </BasicTable.HeaderCell>
                </BasicTable.Row>
                <div className="max-h-[250px] overflow-auto">
                  <BasicTable className="rounded-none ring-0 [&>table]:table-fixed [&>table]:divide-none [&_thead]:hidden ">
                    <></>
                    <>
                      {models.map((model) => (
                        <BasicTable.Row key={model.uuid}>
                          <BasicTable.Cell className="w-[100px] capitalize">{model.model.toLowerCase()}</BasicTable.Cell>
                          <BasicTable.Cell className="w-[100px] capitalize">{model.class.toLowerCase()}</BasicTable.Cell>
                          <BasicTable.Cell className="w-[100px] capitalize">{getVehicleTypeLabel(model.type)}</BasicTable.Cell>
                        </BasicTable.Row>
                      ))}
                    </>
                  </BasicTable>
                </div>
              </div>
            </>
          )}
        </div>
        <Button variant="secondary" onClick={() => ({})} size="lg" className="mt-5 w-full">
          Vehicle not in the list
        </Button>
      </section>
    </Modal>
  );
};

interface VehicleMakeOptionProps extends React.HTMLAttributes<HTMLInputElement> {
  make: string;
  onChange: () => void;
  isActive: boolean;
}

const VehicleMakeOption = ({ make, onChange, isActive }: VehicleMakeOptionProps) => {
  return (
    <Typography
      className={clsx(
        "rouonded flex-1 cursor-pointer px-0.5 py-0.5 capitalize",
        !isActive ? " text-neutral-black" : "bg-primary-light text-primary"
      )}
      onClick={onChange}
    >
      {make.toLowerCase()}
    </Typography>
  );
};

VehicleMakeOption.displayName = "VehicleMakeOption";
