import { DataTableColumnDef } from "@/components/molecules";
import { ArchivedDriversTableData } from "./types";

export const archivedDriversColumns: DataTableColumnDef<ArchivedDriversTableData>[] = [
  { accessorKey: "name", header: "Name", cell: (props) => props.row.original.name, id: "name", size: 300 },
  {
    accessorKey: "email",
    header: "Email",
    cell: (props) => props.row.original.email,
    id: "email",
    size: 370,
  },
  {
    accessorKey: "mobile",
    header: "Mobile",
    cell: (props) => props.row.original.mobile,
    enableSorting: false,
    size: 250,
  },
  {
    accessorKey: "status",
    header: "Status",
    cell: (props) => props.row.original.status,
    enableSorting: false,
    size: 250,
  },
  // {
  //   accessorKey: "actionDate",
  //   header: "Action Date",
  //   cell: (props) => props.row.original.actionDate,
  //   enableSorting: false,
  //   size: 200
  // },
  {
    accessorKey: "action",
    header: "Action",
    cell: (props) => props.row.original.action,
    size: 75,
    enableSorting: false,
  },
];
