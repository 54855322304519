import { useCallback, useMemo } from "react";
import { useFormContext } from "react-hook-form";
import { Spinner, Typography } from "@/components/atoms";
import { useGetVehiclesQuery } from "@/redux/apis/vehicle/vehicleApi";
import { AddVehicleCategoryFormData } from "../form";
import { AssociatedVehiclesTable } from "./AssociatedVehiclesTable";

export const VehicleCategoryAssociatedVehicles = () => {
  const { setValue, watch } = useFormContext<AddVehicleCategoryFormData>();
  const { isFetching, data } = useGetVehiclesQuery({ page_size: "999" });

  const vehicles = useMemo(() => {
    if (!data) return [];
    return data.vehicles;
  }, [data]);

  const associatedVehicles = watch("associatedVehicles");

  const onCheckChanged = useCallback(
    (vehicleId: string) => {
      if (associatedVehicles.includes(vehicleId)) {
        setValue(
          "associatedVehicles",
          associatedVehicles.filter((id) => id !== vehicleId),
          {
            shouldDirty: true,
          }
        );
      } else
        setValue("associatedVehicles", [...associatedVehicles, vehicleId], {
          shouldDirty: true,
        });
    },
    [associatedVehicles, setValue]
  );

  const onCheckAllChanged = useCallback(
    (checked: boolean) => {
      setValue("associatedVehicles", checked ? vehicles.map((vehicle) => vehicle.uuid) : [], {
        shouldDirty: true,
      });
    },
    [vehicles, setValue]
  );

  return (
    <div className="col-span-2 mt-5 flex flex-col">
      <header className="mb-3 ">
        <div className="flex w-full flex-col md:flex-row md:items-center">
          <Typography variant="action">Vehicle Applications for Your Selected Category</Typography>
          <Typography className="flex-1 text-primary md:text-right">
            {associatedVehicles.length > 0 ? `${associatedVehicles.length}  Vehicles Selected` : ""}
          </Typography>
        </div>
        <Typography variant="paragraph" className="text-neutral-dark-gray">
          For internal operations only: Vehicles shown are based on your configurations. Please choose those to apply to this category
        </Typography>
      </header>
      {isFetching ? (
        <div className="flex h-[400px] w-full flex-col items-center justify-center gap-4 rounded-lg border border-neutral-mid-gray bg-neutral-surface-gray">
          <Spinner className="h-8 w-8 border-neutral-dark-gray" />
          <Typography className="text-neutral-dark-gray">Updating Vehicle List</Typography>
        </div>
      ) : (
        <AssociatedVehiclesTable
          items={vehicles}
          checkedIds={associatedVehicles}
          onCheckChanged={onCheckChanged}
          onCheckAllChanged={onCheckAllChanged}
        />
      )}
    </div>
  );
};
