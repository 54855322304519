import { zodResolver } from "@hookform/resolvers/zod";
import { format, subYears } from "date-fns";
import { FormProvider, useForm } from "react-hook-form";
import { Button, IconButton, Loading, Typography } from "@/components/atoms";
import { getErrorMessages } from "@/helpers/reduxHelpers";
import { useUpdateDriverProfileMutation } from "@/redux/apis/driver/driverProfileApi";
import { addToast } from "@/utils";
import { useManageDriverContext } from "../../context";
import { EditDriverDetailsFormData, editDriverDetailsFormSchema } from "../../form";
import { LicenseDriverDetails } from "../fields/LicenseDriverDetails";
import { PersonalDriverDetails } from "../fields/PersonalDriverDetails";
import { RegionAuDriverDetails } from "../fields/RegionAuDriverDetails";

export const DriverDetails = () => {
  const { driver, activeSection, setActiveSection, setActiveModal } = useManageDriverContext();
  const { personal, regionAu, licence } = driver;
  const [updateDriverProfile, { isLoading }] = useUpdateDriverProfileMutation();

  const methods = useForm<EditDriverDetailsFormData>({
    resolver: zodResolver(editDriverDetailsFormSchema),
    defaultValues: {
      firstName: personal.firstName,
      lastName: personal.lastName,
      preferredName: personal.preferredName,
      mobile: personal.mobile,
      dateOfBirth: personal.dateOfBirth ? new Date(personal.dateOfBirth) : subYears(new Date(), 21),
      address: personal.address,
      stateId: licence.state.uuid,
      regionAu: regionAu ? true : false,
      abn: regionAu ? regionAu.abn : undefined,
      isGstRegistered: regionAu ? regionAu.isGstRegistered : false,
      hasMedicalCondition: regionAu ? regionAu.hasMedicalCondition : false,
      medicalConditionNotes: regionAu ? regionAu.medicalConditionNotes : undefined,
    },
  });

  const {
    handleSubmit,
    formState: { isDirty },
  } = methods;

  const handleEdit = () => {
    if (activeSection !== undefined) setActiveModal("save-changes");
    else setActiveSection("driver-details");
  };

  const onSubmit = handleSubmit((data) => {
    if (isEditMode && isDirty) {
      updateDriverProfile({
        id: driver.uuid,
        licence: { state_uuid: data.stateId ? data.stateId : undefined },
        personal: {
          first_name: data.firstName,
          last_name: data.lastName,
          preferred_name: data.preferredName,
          mobile: data.mobile,
          date_of_birth: data.dateOfBirth ? format(data.dateOfBirth, "yyyy-MM-dd") : "",
          address: data.address,
        },
        region_au: regionAu
          ? {
              abn: data.abn || "",
              is_gst_registered: data.isGstRegistered,
              has_medical_condition: data.hasMedicalCondition,
              medical_condition_notes: data.medicalConditionNotes || "",
            }
          : undefined,
      })
        .unwrap()
        .then(() => {
          addToast("success", "Successfully updated driver details");
          setActiveSection(undefined);
        })
        .catch((e) => getErrorMessages(e).forEach((message) => addToast("danger", message)));
    }
  });

  const isEditMode = activeSection === "driver-details";

  return (
    <FormProvider {...methods}>
      <form onSubmit={onSubmit} className="relative mt-4 flex flex-col rounded-lg border border-neutral-gray bg-neutral-surface-gray p-5">
        {isLoading && <Loading />}
        <div className="flex flex-row items-center">
          <Typography variant="title" className="flex-1">
            Driver Details
          </Typography>
          {isEditMode ? (
            <div className="flex flex-row items-center gap-3">
              <Button size="sm" variant="secondary" onClick={() => setActiveSection(undefined)}>
                Cancel
              </Button>
              <Button size="sm" variant="primary" type="submit" disabled={!isDirty}>
                Save
              </Button>
            </div>
          ) : (
            <IconButton variant="tertiary" iconName="Edit2" size="sm" onClick={handleEdit} />
          )}
        </div>
        <PersonalDriverDetails editMode={isEditMode} />
        <LicenseDriverDetails editMode={isEditMode} />
        <RegionAuDriverDetails editMode={isEditMode} />
      </form>
    </FormProvider>
  );
};
