import { Button, Icon, Loading, Modal, Typography } from "@/components/atoms";
import { getErrorMessages } from "@/helpers/reduxHelpers";
import { driverApi } from "@/redux/apis";
import { useUnblockDriverMutation } from "@/redux/apis/block/blockApi";
import { useAppDispatch } from "@/redux/hooks";
import { OffloadDriver } from "@/redux/slices/driver/types";
import { addToast } from "@/utils";

interface ViewReasonModalProps {
  open: boolean;
  onClose: () => void;
  driver?: OffloadDriver;
}

export const ViewReasonModal = ({ open, onClose, driver }: ViewReasonModalProps) => {
  const dispatch = useAppDispatch();
  const [unblockDriver, { isLoading }] = useUnblockDriverMutation();

  const onUnblockDriver = () => {
    if (driver) {
      unblockDriver({ id: driver.id })
        .unwrap()
        .then(() => {
          addToast("success", `Unblocked ${driver.name}`);
          dispatch(driverApi.util.invalidateTags(["OffloadDrivers"]));
          onClose();
        })
        .catch((e) => getErrorMessages(e).forEach((m) => addToast("danger", m)));
    } else onClose();
  };

  const handleOnClose = () => {
    if (!isLoading) onClose();
  };

  const renderHeader = () => (
    <div className="flex flex-row items-center">
      <Typography variant="title" className="flex-1">
        Reason for Blocking Driver
      </Typography>
      <Icon name="close" isCustom className="flex cursor-pointer justify-end" onClick={handleOnClose} />
    </div>
  );

  return (
    <Modal open={open} onClose={handleOnClose} className="relative w-[85vw] md:w-[550px] lg:w-[550px]">
      {isLoading && <Loading />}
      {renderHeader()}
      <div className="mt-3 flex flex-col">
        <Typography variant="paragraph">{driver?.block.reason}</Typography>
        <div className="mt-4 flex flex-row">
          <Typography variant="paragraph" className="flex-1 text-neutral-dark-gray">
            Operator Contact Number
          </Typography>
          <Typography variant="paragraph">{driver?.provider.phoneNumber}</Typography>
        </div>
        <Button
          size="lg"
          className="mt-8 [&>svg]:text-danger"
          variant="secondary"
          startIcon="MinusCircle"
          iconVariant="Bold"
          onClick={onUnblockDriver}
        >
          Unban Driver
        </Button>
      </div>
    </Modal>
  );
};
