import { useCallback, useMemo } from "react";
import { Controller, useFormContext } from "react-hook-form";
import { DropdownSelect, ErrorMessage, Typography } from "@/components/atoms";
import { getVehicleTypeLabel } from "@/helpers/vehicleHelpers";
import { useGetVehicleCategoriesFormOptionsQuery } from "@/redux/apis/config/pricing/vehicleCategory/vehicleCategoryApi";
import { useGetVehiclesQuery } from "@/redux/apis/vehicle/vehicleApi";
import { clsx } from "@/utils";
import { getMatchedVehicles } from "../AssociatedVehicles/helpers";
import { AddVehicleCategoryFormData } from "../form";

export const VehicleCategoryTypeField = ({ className, ...props }: React.HTMLAttributes<HTMLDivElement>) => {
  const { isFetching: isFetchingOptions, data: formOptions } = useGetVehicleCategoriesFormOptionsQuery();
  const { isFetching: isFetchingVehicles, data: vehicleOptions } = useGetVehiclesQuery({ page_size: "999" });

  const {
    control,
    formState: { errors },
    watch,
    setValue,
  } = useFormContext<AddVehicleCategoryFormData>();

  const options = useMemo(() => {
    if (!formOptions) return [];
    return formOptions.vehicleType.map((vehicleType) => ({
      value: vehicleType,
      name: getVehicleTypeLabel(vehicleType),
    }));
  }, [formOptions]);

  const vehicles = useMemo(() => {
    if (!vehicleOptions) return [];
    return vehicleOptions.vehicles;
  }, [vehicleOptions]);

  const vehicleClass = watch("class");
  const updateMatchedVehicles = useCallback(
    (vehicleType: string) => {
      if (vehicles.length === 0) return;

      const matchedVehicles = getMatchedVehicles(vehicles, vehicleType, vehicleClass);
      setValue(
        "associatedVehicles",
        matchedVehicles.map((vehicle) => vehicle.uuid),
        {
          shouldDirty: true,
        }
      );
    },
    [vehicles, vehicleClass, setValue]
  );

  const isLoading = isFetchingOptions || isFetchingVehicles;
  if (isLoading || !formOptions) return null;

  return (
    <div className={clsx("col-span-2 flex flex-col gap-y-1 md:col-span-1", className)} {...props}>
      <div className="flex flex-row items-center gap-x-1.5">
        <Typography variant="paragraph">Vehicle Type</Typography>
      </div>
      <Controller
        control={control}
        name="type"
        render={({ field }) => (
          <DropdownSelect
            ref={field.ref}
            className="w-full"
            placeholder="Select Vehicle Type"
            options={options}
            value={field.value}
            onChange={(value: string | null) => {
              field.onChange(value);
              if (value) updateMatchedVehicles(value);
            }}
            hasError={!!errors.type}
            isNullable
          />
        )}
      />
      <ErrorMessage errors={errors} name="type" />
    </div>
  );
};
