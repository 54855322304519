import { createContext, PropsWithChildren, useContext, useState } from "react";
import { useParams } from "react-router-dom";
import { Loading } from "@/components/atoms";
import { NotFound } from "@/components/pages";
import { useGetVehicleQuery } from "@/redux/apis/vehicle/vehicleApi";
import { Vehicle } from "@/redux/slices/vehicle/types";

export interface IViewVehicleContext {
  vehicle: Vehicle;
  activeModal?: "access-manager" | "vehicle-category" | "history-logs" | "registration-renewal";
  setActiveModal: (modal?: "access-manager" | "vehicle-category" | "history-logs" | "registration-renewal") => void;
}
export const ViewVehicleContext = createContext<IViewVehicleContext | null>(null);

export const ViewVehicleProvider = ({ children }: PropsWithChildren) => {
  const { vehicleId } = useParams<{ vehicleId: string }>();
  const { data: vehicle, isLoading, isError } = useGetVehicleQuery(vehicleId ?? "", { skip: !vehicleId });
  const [activeModal, setActiveModal] = useState<IViewVehicleContext["activeModal"]>(undefined);

  if (isError) return <NotFound noBackground noHeader />;
  if (isLoading || !vehicle) return <Loading />;

  return <ViewVehicleContext.Provider value={{ vehicle, activeModal, setActiveModal }}>{children}</ViewVehicleContext.Provider>;
};

export const useViewVehicleContext = () => {
  const context = useContext(ViewVehicleContext);
  if (!context) {
    throw new Error("useViewVehicleContext must be used within an ViewVehicleProvider");
  }
  return context;
};
